import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ReactNode } from 'react';
import { Message } from '../Message/Message';

export interface ErrorBoundaryState {
    error?: Error;
}

export interface IErrorBoundaryProps extends RouteComponentProps {
    onError?: (arg: string) => ReactNode;
}

export const ErrorBoundary = withRouter(
    // still using a class component because there currently isn't a hook for componentDidCatch                   //
    // please see the React Docs: https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes //
    class ErrorBoundary extends React.Component<
        IErrorBoundaryProps,
        ErrorBoundaryState
    > {
        state: ErrorBoundaryState = {};

        constructor(props: IErrorBoundaryProps) {
            super(props);
            const { history } = props;
            // using history.listen listens for a url change resetting the state so that the error doesn't
            // persist when moving to another page: https://github.com/remix-run/history/blob/dev/docs/getting-started.md#listening and https://stackoverflow.com/a/49341596
            history.listen(() => {
                if (this.state.error) {
                    this.setState({});
                }
            });
        }

        componentDidMount() {
            // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
            window.addEventListener(
                'unhandledrejection',
                this.promiseRejectionHandler,
            );
        }

        // errorInfo can also be passed in as a parameter for this lifecycle method: errorInfo: React.errorInfo
        componentDidCatch(error: Error) {
            if (error) {
                this.setState({
                    error,
                });
            }
        }

        componentWillUnmount() {
            window.removeEventListener(
                'unhandledrejection',
                this.promiseRejectionHandler,
            );
        }

        private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
            // Temporary hack to ignore the unhandled CLOSE_Q_SEARCH timed out error
            if (event.reason.includes('CLOSE_Q_SEARCH timed out')) {
                return;
            }

            this.setState({
                error: event.reason,
            });
        };

        render() {
            const { error } = this.state;
            if (error) {
                return (
                    this.props.onError?.(error.message) ?? (
                        <Message variant="error" message={error.message} />
                    )
                );
            }
            return this.props.children;
        }
    },
);
