import React from 'react';
import { JssThemeProvider } from '@bb-ui/react-library/dist/components/JssThemeProvider';
import { AppThemeContext } from '../../contexts/AppThemeContext';

export const AppThemeProvider: React.FunctionComponent = ({ children }) => {
    const [isRtl, setIsRtl] = React.useState(false);

    return (
        <AppThemeContext.Provider value={{ isRtl, setIsRtl }}>
            <JssThemeProvider
                isRtl={isRtl}
                theme="light"
                jssInsertionPoint="insertion-point-jss"
            >
                {children}
            </JssThemeProvider>
        </AppThemeContext.Provider>
    );
};
