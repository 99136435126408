import React from 'react';
import classnames from 'classnames';

import { Card } from '@bb-ui/react-library/dist/components/Card';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import ImageHandler from '../ImageHandler/ImageHandler';
import { ICustomCardPrimaryProps, ICustomCardProps } from './CustomCards.types';
import { useStyles } from './CustomCardPrimary.styles';

export const CustomCardPrimary: React.FunctionComponent<
    ICustomCardProps & ICustomCardPrimaryProps
> = ({
    id,
    title,
    imgSrc,
    loadingImageColor,
    className,
    imagePosition = 'right',
    cardActions,
    children,
}) => {
    const classes = useStyles();

    return (
        <Card
            className={classnames(classes.card, className)}
            data-testid={`home-page-${id}-card`}
        >
            <div
                className={
                    imagePosition === 'left'
                        ? classes.imageContainerLeft
                        : classes.imageContainerRight
                }
            >
                <ImageHandler
                    imgSrc={imgSrc}
                    loadingImageColor={loadingImageColor}
                    className={classes.image}
                />
            </div>
            <div className={classes.contentSection}>
                {typeof title === 'string' ? (
                    <Typography
                        variant="h2"
                        className={classes.title}
                        data-testid={`home-page-${id}-card-title`}
                    >
                        {title}
                    </Typography>
                ) : (
                    <div data-testid={`home-page-${id}-card-title`}>
                        {title}
                    </div>
                )}
                <div data-testid={`home-page-${id}-card-text`}>{children}</div>
                {cardActions}
            </div>
        </Card>
    );
};
