import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { AppLoadingContext } from '../../contexts/AppLoadingContext';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { GeneralErrorPage } from '../ErrorPages/GeneralErrorPage/GeneralErrorPage';
import { localStorageUASTenantKey } from '../../utilities/constants';

export const AppLoadingAndErrorOverlay: React.FunctionComponent = ({
    children,
}) => {
    const { t } = useTranslation(undefined, { useSuspense: false });
    const [loading, setLoading] = React.useState(true);
    const [loadingUnsuccesful, setLoadingUnsuccesful] = React.useState(false);
    const [, setUasTenant] = useLocalStorage<string>(
        localStorageUASTenantKey,
        '',
        {
            raw: true,
        },
    );

    useEffect(() => {
        const loadingTimer = setTimeout(() => {
            if (loading) {
                setLoading(false);
                setLoadingUnsuccesful(true);
            }
        }, 120000);
        return () => clearTimeout(loadingTimer);
    }, [loading, loadingUnsuccesful]);

    const loadingNode = (
        <LoadingIndicator
            aria-label={t('general.applicationLoadingAriaLabel')}
            variant="fill-window"
            id="appLoadingAndErrorOverlay"
        />
    );

    const onError = () => {
        // remove potentially blocking uasTenantUriComponent local storage value
        // clearing the value storage affects state, so it needs to be postponed after the render phase.
        setTimeout(() => {
            setUasTenant('');
        }, 0);
        return <GeneralErrorPage />;
    };

    return (
        <ErrorBoundary onError={onError}>
            <AppLoadingContext.Provider
                value={{
                    loading,
                    setLoading,
                }}
            >
                <Suspense fallback={loadingNode}>
                    {loading && loadingNode}
                    {children}
                    {
                        loadingUnsuccesful && (
                            <GeneralErrorPage />
                        ) /* It is here for
                    the second time because ErrorBoundary doesn't catch
                    compile-time errors */
                    }
                </Suspense>
            </AppLoadingContext.Provider>
        </ErrorBoundary>
    );
};
