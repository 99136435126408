import {
    Theme,
    createStyles,
    makeStyles,
} from '@bb-ui/react-library/dist/components/styles';
import { SnowflakeLauncherModalState } from './SnowflakeLauncherModal.types';

interface ModalState {
    state: SnowflakeLauncherModalState;
}

const styles = (theme: Theme) =>
    createStyles({
        dialogPaper: {
            maxWidth: '450px !important',
            minHeight: '260px',
            '& >div': {
                minHeight: '258px',
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
            },
        },
        title: {
            '& h1': {
                lineHeight: '27px',
                fontWeight: theme.typography.fontWeightSemiBold,
                fontSize: theme.typography.fontSizeLarge,
            },
            '& button': {
                minHeight: 'auto',
                alignSelf: 'baseline',
                paddingTop: theme.spacing(0.25),
            },
            height: 'fit-content',
            minHeight: theme.spacing(5),
            verticalAlign: 'top',
        },
        dialogContent: (props: ModalState) => ({
            paddingTop: theme.spacing(1),
            paddingBottom:
                props.state === SnowflakeLauncherModalState.REDIRECT_FAILED
                    ? theme.spacing(0)
                    : theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
        }),
        textAboveIndicator: {
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.fontSizeDefault,
            lineHeight: '21px',
        },
        indicatorWrapper: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100% - 5px)',
        },
        textBelowIndicator: (props: ModalState) => ({
            fontWeight: theme.typography.fontWeightSemiBold,
            fontSize: theme.typography.fontSizeDefault,
            lineHeight: '24px',
            paddingTop: theme.typography.fontSizeDefault,
            textAlign: 'center',
            maxWidth:
                props.state === SnowflakeLauncherModalState.IN_PROGRESS
                    ? '280px'
                    : '370px',
        }),
        button: {
            display: 'inline-block',
        },
    });
const options = {
    name: 'SnowflakeLauncherModal-',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStyles = (state: SnowflakeLauncherModalState) =>
    makeStyles(styles, options)({ state });
