import {
    Typography,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Checkbox,
    TableContainer,
    Combobox,
    ComboboxOption,
    CircularProgress,
    Box,
} from '@bb-ui/react-library';
import {
    ChevronDown,
    ChevronRight,
    LineHorizontal,
} from '@bb-ui/icons/dist/small';
import { useAuth0Context } from '@bb-ui/auth/dist/auth0/Auth0Context';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyles } from './IHNodes.styles';
import { useAppConfigContext } from '../../../../contexts/AppConfigProvider';
import { getTenantId } from '../../../../utilities/utilities';
import { SkeletonTable } from '../../../Skeleton/SkeletonTable';
import { useApi } from '../../../../hooks/useApi';
import { PagingControls } from '../../../PagingControls/PagingControls';
import {
    getPageNumber,
    getPageNumberOptions,
    updateLocation,
} from '../../../../utilities/pagination';
import { ILocationState } from '../../../../types/appUITypes';
import { LoadingIndicator } from '../../../LoadingIndicator/LoadingIndicator';
import { EditUserError } from '../EditUserError/EditUserError';
import {
    INodeRepsonse,
    IUserNodesAPI,
    IhNodesProps,
    TreeNode,
} from './IHNodes.types';

export const IHNodes: React.FunctionComponent<IhNodesProps> = ({
    userId,
    onSave,
    onLoading,
    onError,
}) => {
    const classes = useStyles();

    const { uas, api, pagination } = useAppConfigContext();
    const { user, accessToken } = useAuth0Context();
    const tenantId = getTenantId(user, uas);
    const { t } = useTranslation();

    const location = useLocation<ILocationState>();
    const history = useHistory();

    const rootNodeId = 'root';

    const ITEMS_PER_PAGE = pagination?.itemsPerPage ?? 10;
    const [pageSize, setPageSize] = useState<number>(ITEMS_PER_PAGE);

    const pageOptions = [
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
        { value: '100', label: '100' },
    ];

    const [assignedNodes, setAssignedNodes] = useState<string[]>([]);

    // This is a temporary state that is updated when the user adds/removes values in the Combobox
    const [unsavedNodeAssignments, setUnsavedNodeAssignments] =
        useState<string[]>(assignedNodes);

    // States used to track loading and errors
    const [loading, setLoading] = useState<boolean>(false); // represents all initial loading
    const [updating, setUpdating] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    // State managing . The strings are the node ids.
    const [expandedNodes, setExpandedNodes] = useState<string[]>([]); // manarepresentsges which nodes are expanded
    const [topAncestorIds, setTopAncestorIds] = useState<string[]>([]); // represents which nodes are top most ancestors
    const [loadingNodeIds, setLoadingNodeIds] = useState<string[]>([]); // represents which nodes are loading their children

    const [nodeOptions, setNodeOptions] = useState<TreeNode[]>([]); // represents the nodes that the user can choose from
    const [noChildrenNodes, setNoChildrenNodes] = useState<string[]>([]); // represents the nodes with no children

    const [intialNodeOptionsLoading, setIntialNodeOptionsLoading] =
        useState<boolean>(false);

    const tenantUrl = encodeURI(
        `${api!.userManagementHostname}/v1/tenants/${tenantId}/nodes/`,
    );

    const userUrl = encodeURI(
        `${
            api!.userManagementHostname
        }/v1/tenants/${tenantId}/users/${userId}/nodes/`,
    );

    // Fetch the initial nodes for the user. Happens only once on load.
    const {
        data: initialNodes,
        loading: initialNodesLoading,
        error: initialNodesError,
    } = useApi<IUserNodesAPI>({
        url: userUrl,
        method: 'GET',
        useAuth: true,
    });

    // Update the nodes for the user. The strings represent nodes the user should have access to.
    const {
        data: putNodesData,
        loading: putNodesLoading,
        error: putNodesError,
        execute: putNodesExecute,
    } = useApi<string[]>({
        url: userUrl,
        method: 'PUT',
        useAuth: true,
        executeType: 'manual',
    });

    // Hooks to manage the loading and error states
    useEffect(() => {
        // pass up the loading state to parent
        if (onLoading) {
            onLoading(loading || updating);
        }
    }, [loading, onLoading, updating]);

    useEffect(() => {
        if (onError && error) {
            onError(error);
        }
    }, [error, onError]);

    useEffect(() => {
        if (initialNodesError) {
            setError(
                'Could not load the institutional hierarchy permissions for this user',
            );
        }
    }, [initialNodesError]);

    useEffect(() => {
        setLoading(initialNodesLoading || intialNodeOptionsLoading);
    }, [initialNodesLoading, intialNodeOptionsLoading]);

    useEffect(() => {
        if (putNodesError) {
            setError(
                'Could not update the institutional hierarchy permissions for this user',
            );
        }
    }, [t, putNodesError]);

    useEffect(() => {
        setUpdating(putNodesLoading || deleting);
    }, [putNodesLoading, deleting]);

    // Handle the initial nodes for the user.
    useEffect(() => {
        if (initialNodes) {
            const initialNodeIds = initialNodes.nodes.map(node => node.id);
            setAssignedNodes(initialNodeIds);
            setUnsavedNodeAssignments(initialNodeIds);
        }
    }, [initialNodes]);

    /**
     * Fetches all child nodes of a given parent node.
     *
     * This function is not using the useApi hook in order to avoid rerendering issues.
     *
     * @param parentNodeId - The ID of the parent node to fetch the child nodes of.
     * @param nextToken - An optional token for fetching the next page of child nodes.
     * @returns A promise that resolves to an array of the child nodes of the parent node.
     *          If an error occurs while fetching the child nodes, the promise resolves to an empty array and an error message is set.
     */
    const fetchAllChildNodes = useCallback(
        async (
            parentNodeId: string,
            nextToken?: string,
        ): Promise<INodeRepsonse[]> => {
            const fetchUrl = `${tenantUrl}${parentNodeId}`;
            try {
                const response = await axios.get<INodeRepsonse>(fetchUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    params: {
                        nextToken,
                    },
                });
                const { data } = response;
                // TODO: add recursive call to get all child nodes, once nextToken is implemented
                return [data];
            } catch (error: any) {
                setError(`Could not load the children of node ${parentNodeId}`);
                return [];
            }
        },
        [accessToken, tenantUrl],
    );

    /**
     * Fetches all child nodes of a given parent node and manages the setting of children nodes.
     *
     * If the parent node has no children, its ID is added to `noChildrenNodes`.
     *
     * @param parentNodeId - The ID of the parent node to fetch the child nodes of.
     * @returns A promise that resolves to an array of the child nodes of the parent node.
     *          If the parent node has no children, the promise resolves to an empty array.
     */
    const getChildNodes = useCallback(
        async (parentNodeId: string): Promise<TreeNode[]> => {
            const responses = await fetchAllChildNodes(parentNodeId);
            const nodes = responses.map(response => response.nodes).flat();
            if (nodes.length === 0) {
                setNoChildrenNodes(prevNodes => [...prevNodes, parentNodeId]);
            }
            return nodes;
        },
        [fetchAllChildNodes],
    );

    // Initial load of node options for a tenant
    useEffect(() => {
        const fetchNodes = async () => {
            setIntialNodeOptionsLoading(true);
            const nodes = await getChildNodes(rootNodeId);
            const updatedNodes = nodes.map(node => ({
                ...node,
                level: 0,
                sortableName: node.name,
            }));
            setNodeOptions(updatedNodes);
            setTopAncestorIds(nodes.map(node => node.id));
            setIntialNodeOptionsLoading(false);
        };

        fetchNodes();
    }, [getChildNodes, setNodeOptions]);

    /**
     * Updates the assigned nodes.
     *
     * Nodes that are in `unsavedNodeAssignments` but not in `assignedNodes` are added.
     * Nodes that are in `assignedNodes` but not in `unsavedNodeAssignments` are removed.
     *
     * @returns A promise that resolves to the data returned by `putNodesExecute` if the update is successful,
     *          or an empty array if the update fails.
     *          The promise will always resolve, never reject.
     */
    const updateNodes = useCallback(async (): Promise<string[] | undefined> => {
        const nodesToAdd = unsavedNodeAssignments.filter(
            node => !assignedNodes.includes(node),
        );
        const nodesToRemove = assignedNodes.filter(
            node => !unsavedNodeAssignments.includes(node),
        );
        try {
            if (nodesToAdd.length !== 0) {
                putNodesExecute({
                    url: userUrl,
                    data: { nodes: nodesToAdd },
                });
                setAssignedNodes([...assignedNodes, ...nodesToAdd]);
            }
            if (nodesToRemove.length !== 0) {
                // manually set updating to true
                setDeleting(true);
                const endpoints = nodesToRemove.map(
                    node => `${userUrl}${node}`,
                );
                await axios.all(
                    endpoints.map(endpoint =>
                        axios.delete<INodeRepsonse>(endpoint, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }),
                    ),
                );
                nodesToRemove.forEach(node => {
                    setAssignedNodes(
                        assignedNodes.filter(
                            assignedNode => assignedNode !== node,
                        ),
                    );
                });
            }
            return putNodesData;
        } catch (error: any) {
            setError(
                'Could not update the institutional hierarchy permissions for this user',
            );
            return [];
        } finally {
            // manually set updating to false
            setDeleting(false);
            setAssignedNodes(unsavedNodeAssignments);
        }
    }, [
        unsavedNodeAssignments,
        assignedNodes,
        putNodesData,
        putNodesExecute,
        userUrl,
        accessToken,
    ]);

    // Ensure that the updateNodes call will be made when the 'save' button is clicked
    const updateNodesRef = useRef(updateNodes);
    const initialRenderRef = useRef(true);

    useEffect(() => {
        updateNodesRef.current = updateNodes;
    }, [updateNodes]);

    useEffect(() => {
        if (initialRenderRef.current) {
            initialRenderRef.current = false;
        } else {
            updateNodesRef.current();
        }
    }, [onSave]);

    /**
     * Finds a node by its ID in a list of nodes.
     *
     * @param nodeId - The ID of the node to find.
     * @param nodes - The list of nodes to search in.
     * @returns The node with the given ID, or undefined if no such node is found.
     */
    const findNodeById = (
        nodeId: string,
        nodes: TreeNode[],
    ): TreeNode | undefined => {
        const node = nodes.find(node => node.id === nodeId);

        if (node) return node;

        return undefined;
    };

    /**
     * Gets the IDs of the child nodes of a given node.
     *
     * @param nodeId - The ID of the node to get the child node IDs of.
     * @param nodes - The list of nodes to search in.
     * @returns An array of the IDs of the child nodes of the node with the given ID.
     *          If the node with the given ID is not found or it has no children, an empty array is returned.
     */
    const getChildNodeIds = (nodeId: string, nodes: TreeNode[]): string[] => {
        const node = findNodeById(nodeId, nodes);

        if (!node || !node.childrenIds) {
            return [];
        }

        return node.childrenIds;
    };

    /**
     * Updates the selected nodes.
     *
     * If the given node ID is already in the selected nodes, it is removed.
     * Otherwise, it is added to the selected nodes.
     * In either case, all child nodes of the given node are removed from the selected nodes.
     *
     * @param nodeId - The ID of the node to add or remove.
     * @param nodes - The list of all nodes.
     */
    const updateSelected = (nodeId: string, nodes: TreeNode[]) => {
        // If the node ID is already in unsavedNodeAssignments, it is removed.
        // If the node ID is not in unsavedNodeAssignments, it is added.
        // This will
        const updatedSelected = unsavedNodeAssignments.includes(nodeId)
            ? unsavedNodeAssignments.filter(g => g !== nodeId)
            : [...unsavedNodeAssignments, nodeId];

        // remove from the unsavedNodeAssignments all the children of the node
        const childrenNodeIds = getChildNodeIds(nodeId, nodes);
        const updatedSelectedFiltered = updatedSelected.filter(
            g => !childrenNodeIds.includes(g),
        );

        setUnsavedNodeAssignments([...new Set(updatedSelectedFiltered)]);
    };

    /**
     * Checks if all top ancestor nodes are selected.
     *
     * @returns True if all top ancestor nodes are selected, false otherwise.
     *          If there are no top ancestor nodes, false is returned.
     */
    const allTopAncesorsAreSelected = (): boolean => {
        if (topAncestorIds.length === 0) return false;
        return topAncestorIds.every(ancestorId =>
            unsavedNodeAssignments.includes(ancestorId),
        );
    };

    /**
     * Selects/deselects all nodes. Selects all nodes if not all top ancestor
     * nodes are selected, deselects all nodes otherwise.
     *
     */
    const selectAllNodesChecked = () => () => {
        if (allTopAncesorsAreSelected()) {
            setUnsavedNodeAssignments([]);
        } else {
            setUnsavedNodeAssignments(topAncestorIds);
        }
    };

    /**
     * Handles the change of the page size.
     *
     * Updates the page size and resets the current page to 1.
     *
     * @param newPageSize - The new page size.
     */
    const handlePageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
        updateLocation({ page: 1 }, history, location);
    };

    /**
     * Gets the style for a row based on its level.
     *
     * The left padding of the row is increased by 2rem for each level.
     *
     * @param level - The level of the row.
     * @returns A style object with the left padding set based on the level.
     *          If the level is 0 or less, an empty object is returned.
     */
    const getRowStyle = (level: number) => {
        if (level <= 0) return {};
        const padding = `${1 + level * 2}rem`;
        return {
            paddingLeft: padding,
        };
    };

    /**
     * Handles the click event on a node to expand it.
     *
     * When a node is clicked, its child nodes are fetched and added to the node options.
     * The clicked node is also added to the expanded nodes and the loading nodes.
     * Once the child nodes are fetched, the clicked node is removed from the loading nodes.
     *
     * @param parentNode - The node that was clicked.
     * @param childNodeLevel - The level of the child nodes of the clicked node.
     */
    const handleNodeExpandClick = (
        parentNode: TreeNode,
        childNodeLevel: number,
    ) => {
        const childNodeIds: string[] = [];
        const tempParentNode = parentNode;
        setLoadingNodeIds(prevLoadingNodeIds => [
            ...prevLoadingNodeIds,
            parentNode.id,
        ]);
        setExpandedNodes(prevExpandedNodes => [
            ...prevExpandedNodes,
            parentNode.id,
        ]);
        getChildNodes(parentNode.id).then(nodes => {
            const updatedNodes: TreeNode[] = nodes.map(node => ({
                ...node,
                ancestorIds: [...(parentNode.ancestorIds ?? []), parentNode.id],
                level: childNodeLevel,
                sortableName: `${parentNode.sortableName}|${node.name}`,
            }));
            updatedNodes.forEach(node => {
                childNodeIds.push(node.id);
            });
            setNodeOptions(prevNodeOptions => [
                ...prevNodeOptions,
                ...updatedNodes,
            ]);
            setLoadingNodeIds(prevLoadingNodeIds =>
                prevLoadingNodeIds.filter(id => id !== parentNode.id),
            );
        });

        tempParentNode.childrenIds = childNodeIds;
        const updatedNodes = nodeOptions.filter(
            node => node.id !== parentNode.id,
        );
        updatedNodes.push(tempParentNode);
        setNodeOptions(updatedNodes);
    };

    /**
     * Handles the click event on a node to collapse it.
     *
     * When a node is clicked, it is removed from the expanded nodes and its descendants are removed from the node options.
     *
     * @param parentNode - The node that was clicked.
     */
    const handleNodeCollapseClick = (parentNode: TreeNode) => {
        const nodesToCollapse = nodeOptions
            .filter(node => node.ancestorIds?.includes(parentNode.id))
            .map(node => node.id);

        setExpandedNodes(prevExpandedNodes =>
            prevExpandedNodes.filter(
                nodeId =>
                    nodeId !== parentNode.id &&
                    !nodesToCollapse.includes(nodeId),
            ),
        );

        setNodeOptions(prevNodeOptions =>
            prevNodeOptions.filter(
                node => !node.ancestorIds?.includes(parentNode.id),
            ),
        );
    };

    /**
     * Gets the arrow icon for a parent node based on its state.
     *
     * If the parent node is expanded, a ChevronDown icon is returned. Indicates that the children are visible.
     * If the parent node is not expanded, a ChevronRight icon is returned. Indicates that the children are hidden.
     * If the parent node has no children, a LineHorizontal icon is returned. Indicates that the node has no children.
     * If the parent node's level is less than 0, null is returned.
     *
     * @param parentNode - The parent node to get the arrow icon for.
     * @returns A ChevronDown, ChevronRight, or LineHorizontal icon, or null, based on the state of the parent node.
     */
    const getArrow = (parentNode: TreeNode) => {
        const expanded = expandedNodes.includes(parentNode.id);
        const childNodeLevel = parentNode.level + 1;
        if (noChildrenNodes.includes(parentNode.id)) {
            return (
                <LineHorizontal
                    data-testid={`no-children-icon-${parentNode.id}`}
                    className={classes.noChildrenFoundIcon}
                />
            );
        }
        if (parentNode.level < 0) return null;
        if (expanded === false) {
            return (
                <ChevronRight
                    data-testid={`expand-children-icon-${parentNode.id}`}
                    className={classes.expandChildrenIcon}
                    onClick={() =>
                        handleNodeExpandClick(parentNode, childNodeLevel)
                    }
                />
            );
        }

        return (
            <ChevronDown
                data-testid={`collapse-children-icon-${parentNode.id}`}
                className={classes.collapseChildrenIcon}
                onClick={() => handleNodeCollapseClick(parentNode)}
            />
        );
    };

    /**
     * Updates the current page.
     *
     * @param newPage - The new page number.
     */
    const updateCurrentPage = (newPage: number) => {
        updateLocation({ page: newPage }, history, location);
    };

    /**
     * Checks if any ancestor nodes of a given node is selected.
     *
     * @param node - The node to check the ancestor nodes of.
     * @returns True if any ancestor node of the node is selected, false otherwise.
     *          If the node has no ancestor nodes, false is returned.
     */
    const isAncestorSelected = (node: TreeNode): boolean => {
        if (node.ancestorIds && node.ancestorIds.length > 0) {
            const ancestorsSelected = node.ancestorIds.some(ancestorId =>
                unsavedNodeAssignments.includes(ancestorId),
            );
            return ancestorsSelected;
        }

        return false;
    };

    /**
     * Sorts a list of nodes by their sortable names.
     *
     * @param nodes - The list of nodes to sort.
     * @returns A new array of the nodes sorted by their sortable names.
     *          If the input is undefined, an empty array is returned.
     */
    const sortNodes = (nodes: TreeNode[]): TreeNode[] => {
        if (nodes === undefined) {
            return [];
        }
        return nodes.sort((a, b) =>
            a.sortableName.localeCompare(b.sortableName),
        );
    };

    /**
     * An object that provides the text values for the ComboBox component.
     *
     * The text values are localized using the `t` function.
     */
    const comboBoxTextValues = {
        announceOptionSelected: (option: any) =>
            t('settings.editUser.comboBox.optionSelected', {
                option: option.label,
            }),
        announceOptionDeselected: (option: any) =>
            t('settings.editUser.comboBox.optionDeselected', {
                option: option.label,
            }),
        announceValueCleared: t('settings.editUser.comboBox.valueCleared'),
        announceSearchResults: (count: any) => {
            switch (count) {
                case 0:
                    return t('settings.editUser.comboBox.noResultsFound');
                default:
                    return t('settings.editUser.comboBox.resultsFound', {
                        count,
                    });
            }
        },
        noResults: () => t('settings.editUser.comboBox.noResultsFound'),
        clearButtonLabel: t('settings.editUser.comboBox.clearButton'),
        searchLabel: t('settings.editUser.comboBox.searchLabel'),
    };

    /**
     * Renders a loading indicator for a node.
     *
     * If the node is currently loading, a CircularProgress component is returned.
     * Otherwise, null is returned.
     *
     * @param id - The ID of the node.
     * @returns A CircularProgress component if the node is currently loading, or null otherwise.
     */
    const renderLoader = (id: string) => {
        if (loadingNodeIds.includes(id)) {
            return (
                <CircularProgress
                    id="icp1"
                    data-testid={`loading-children-icon-${id}`}
                    label=""
                    size="12"
                    className={classes.loadingChildrenIcon}
                />
            );
        }
        return null;
    };

    /**
     * Renders a table row for each node.
     *
     * The nodes are sorted and paginated, and a table row is rendered for each node.
     * Each table row includes a checkbox and the name of the node.
     *
     * @returns An array of table row elements.
     */
    const renderTableRow = () =>
        sortNodes(nodeOptions)
            .slice(
                (getPageNumber(location) - 1) * pageSize,
                getPageNumber(location) * pageSize,
            )
            .map(node => (
                <TableRow key={node.id}>
                    <TableCell>
                        <Checkbox
                            data-testid={`node-checkbox-${node.id}`}
                            className={`${
                                isAncestorSelected(node)
                                    ? classes.disabledCheckBox
                                    : ''
                            }`}
                            disabled={isAncestorSelected(node)}
                            checked={
                                unsavedNodeAssignments.includes(node.id) ||
                                isAncestorSelected(node)
                            }
                            onChange={() => {
                                updateSelected(node.id, nodeOptions);
                            }}
                        />
                    </TableCell>
                    <TableCell
                        style={{
                            ...getRowStyle(node.level),
                        }}
                    >
                        <div className={classes.nodeRow}>
                            {getArrow(node)}
                            {node.name}
                            {renderLoader(node.id)}
                        </div>
                    </TableCell>
                </TableRow>
            ));

    /**
     * Renders an error message.
     *
     * @returns An `EditUserError` component with the current error message.
     */
    const renderError = () => <EditUserError error={error ?? ''} />;

    /**
     * Renders a loading indicator.
     *
     * @returns A `Box` component that includes a `LoadingIndicator` component.
     */
    const renderUpdater = () => (
        <Box data-testid="user-nodes-updating" className={classes.updatingInfo}>
            <LoadingIndicator
                id="updating-nodes-progress"
                label={t('settings.editUser.nodes.loading.updatingNodes')}
                size="76"
            />
        </Box>
    );

    /**
     * Renders a skeleton
     *
     * @returns A `Box` component that includes a `SkeletonTable` component.
     */
    const renderSkeleton = () => (
        <Box
            data-testid="user-nodes-skeleton"
            className={classes.skeletonContainer}
        >
            <SkeletonTable />
        </Box>
    );

    return (
        <div
            data-testid="ih-nodes-container"
            className={classes.ihNodeContainer}
        >
            {!error && !loading && updating && renderUpdater()}
            <Box
                data-testid="user-nodes-combo-box-container"
                className={`${classes.tableContainer} ${
                    updating ? classes.updating : ''
                }`}
            >
                <Typography variant="h2" className={classes.infoTextMargin}>
                    {t('settings.editUser.nodes.title')}
                </Typography>
                <Typography className={classes.infoTextMargin}>
                    {t('settings.editUser.nodes.description')}
                </Typography>
                {(nodeOptions.length === 0 || !initialNodes || loading) &&
                    !error &&
                    renderSkeleton()}
                {error && renderError()}
                {nodeOptions.length > 0 &&
                    !loading &&
                    initialNodes &&
                    !error && (
                        <>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    data-testid="select-all-checkbox"
                                                    checked={allTopAncesorsAreSelected()}
                                                    onChange={selectAllNodesChecked()}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {t(
                                                    'settings.editUser.nodes.nodeColumnName',
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{renderTableRow()}</TableBody>
                                </Table>
                            </TableContainer>
                            <div className={classes.pagination}>
                                <Combobox
                                    id="page-size-combobox"
                                    floatingLabel
                                    className={classes.pageSizeComboBox}
                                    isSearchable={false}
                                    label="Items"
                                    defaultValue={{
                                        value: pageSize.toString(),
                                        label: pageSize.toString(),
                                    }}
                                    onChange={(option: ComboboxOption) => {
                                        handlePageSizeChange(
                                            parseInt(option.value, 10),
                                        );
                                    }}
                                    strings={comboBoxTextValues}
                                    options={pageOptions}
                                />
                                <PagingControls
                                    pageNumber={getPageNumber(location)}
                                    pageNumberOptions={getPageNumberOptions(
                                        pageSize,
                                        nodeOptions.length,
                                    )} // Get this from the size of the nodeOptions array
                                    updateCurrentPage={updateCurrentPage}
                                />
                            </div>
                        </>
                    )}
            </Box>
        </div>
    );
};
