import * as React from 'react';

import { SnackbarProvider } from '@bb-ui/react-library/dist/components/Snackbar';
import { AppConfigProvider } from '../src/contexts/AppConfigProvider';
import { I18nProvider } from '../src/components/I18nProvider/I18nProvider';
import { AppWithAuthentication } from '../src/auth/AppWithAuthentication';
import { AppLoadingAndErrorOverlay } from '../src/components/AppLoadingAndErrorOverlay/AppLoadingAndErrorOverlay';
import { AppThemeProvider } from '../src/components/AppThemeProvider/AppThemeProvider';

// eslint-disable-next-line no-console
console.info(
    `Anthology Illuminate UI - (c) ${new Date().getFullYear()} Anthology Inc. and its affiliates. All rights reserved.`,
);

const App: React.FunctionComponent = () => (
    <AppThemeProvider>
        <AppLoadingAndErrorOverlay>
            <I18nProvider>
                <SnackbarProvider variant="default">
                    <AppConfigProvider>
                        <AppWithAuthentication />
                    </AppConfigProvider>
                </SnackbarProvider>
            </I18nProvider>
        </AppLoadingAndErrorOverlay>
    </AppThemeProvider>
);

export default App;
