import React from 'react';
import { Typography, useTheme } from '@bb-ui/react-library';
import {
    PrimaryButton,
    OutlineButton,
} from '@bb-ui/react-library/dist/components/Button';
import { Trans, useTranslation } from 'react-i18next';

import { ActionAreaButtons } from '../ActionAreaButtons/ActionAreaButtons';
import { useApplicationTitle } from '../../hooks/useApplicationTitle';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { useStyles } from './CustomReportsTeaser.styles';
import ImageHandler from '../ImageHandler/ImageHandler';

export const CustomReportsTeaser: React.FunctionComponent = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();

    // This does not get localized
    useApplicationTitle('Custom Reports');
    const getHelpLink = useHelpLinks();

    return (
        <div
            id="custom-reports-teaser"
            data-testid="custom-reports-teaser"
            className={classes.teaserContainer}
        >
            <Typography
                variant="h1"
                data-testid="main-header-title"
                className={classes.text}
            >
                { /* prettier-ignore */ }
                <Trans i18nKey="authoring.teaser.title">Create reports tailored to your unique needs with <span style={{ fontWeight: 'bold' }}>Custom Reports</span></Trans>
            </Typography>
            <div
                className={classes.imageContainer}
                data-testid="custom-reports-illustration"
            >
                <ImageHandler
                    imgSrc="/images/CustomReports/illustration.svg"
                    className={classes.image}
                />
            </div>
            <Typography
                variant="h2"
                component="p"
                data-testid="custom-reports-description"
                className={classes.text}
            >
                { /* prettier-ignore */ }
                <Trans i18nKey="authoring.teaser.description">This is one of our <span style={{ fontWeight: 'bold' }} lang="en">Illuminate Enhanced</span> features</Trans>
            </Typography>
            <div className={classes.buttonsContainer}>
                <OutlineButton
                    onClick={() => {
                        window.open(
                            getHelpLink('customReports'),
                            '_blank',
                            'noopener noreferrer',
                        );
                    }}
                    className={classes.button}
                    data-testid="custom-reports-secondary-button"
                >
                    {t('authoring.teaser.howCustomReportsWorksButton')}
                </OutlineButton>
                <PrimaryButton
                    onClick={() => {
                        window.open(
                            getHelpLink('aboutIlluminate'),
                            '_blank',
                            'noopener noreferrer',
                        );
                    }}
                    className={classes.button}
                    data-testid="custom-reports-primary-button"
                >
                    <Trans i18nKey="authoring.teaser.exploreIEButton">
                        Explore <span lang="en">Illuminate Enhanced</span>
                    </Trans>
                </PrimaryButton>
            </div>
            <ActionAreaButtons helpLink={getHelpLink('customReports')} />
        </div>
    );
};
