export const langageSelectOptionsDefault = [
    { value: 'cy', label: 'Cymraeg (Cymru)' },
    { value: 'en-AU', label: 'English (Australia)' },
    { value: 'en-GB', label: 'English (United Kingdom)' },
    { value: 'en-US-k12', label: 'English for K-12 (United States)' },
    { value: 'en-US-pro', label: 'English for Pro-Ed (United States)' },
    { value: 'en', label: 'English (United States)' },
    { value: 'es', label: 'Español (España)' },
    { value: 'de', label: 'Deutsch (Deutschland)' },
    { value: 'fr', label: 'Français (France)' },
    { value: 'it', label: 'Italiano (Italia)' },
    { value: 'ja', label: '日本語 (日本‭)' },
    { value: 'ko', label: '한국어 (한국‭)' },
    { value: 'nb', label: 'Bokmål (Norge)' },
    { value: 'nl', label: '	Nederlands (Nederland)' },
    { value: 'pt-BR', label: 'Português (Brasil)' },
    { value: 'pl', label: 'Polski (Polska)' },
    { value: 'sv', label: 'Svenska (Sverige)' },
    { value: 'tr', label: 'Türkçe (Türkiye)' },
    { value: 'zh-CN', label: '中文（简体)' },
    { value: 'zh-TW', label: '中文（中国)' },
];

export const langageSelectOptionsWithTestLocales = [
    ...langageSelectOptionsDefault,
    { value: 'en-SM', label: 'English (Test)' },
];

const langageSelectOptionsKeys = langageSelectOptionsWithTestLocales.map(
    option => option.value,
);

const reportApiLngMap = new Map([
    ['cy', 'cy_GB'],
    ['en', 'en_US'],
    ['en-AU', 'en_AU'],
    ['en-GB', 'en_GB'],
    ['en-US-k12', 'en_US_k12'],
    ['en-US-pro', 'en_US_pro'],
    ['en-SM', 'en_SM'],
    ['ja', 'ja_JP'],
    ['es', 'es_ES'],
    ['fr', 'fr_FR'],
    ['pt-BR', 'pt_BR'],
    ['ko', 'ko_KR'],
    ['nb', 'nb_NO'],
    ['nl', 'nl_NL'],
    ['sv', 'sv_SE'],
    ['tr', 'tr_TR'],
    ['de', 'de_DE'],
    ['pl', 'pl_PL'],
    ['it', 'it_IT'],
    ['zh-CN', 'zh_CN'],
    ['zh-TW', 'zh_TW'],
]);

export interface IQSLng {
    qsLngCode: string;
    apiCode: string;
    hourFormat: string;
    firstDayOfWeek: number;
}

// qsLngCode stands for languages codes sent to QuickSight
// apiCode stands for languages codes sent to our Dashboard API
// hourFormat specifies hour time format (p. e. 12/24) which we use in QuickSight reports
// firstDayOfWeek specifies which day (p. e. Sunday/Monday, Sunday is 0) week starts for country, we use it in QuickSight reports
const qsLngMap = new Map([
    [
        'en',
        {
            qsLngCode: 'en-US',
            apiCode: 'en_US',
            hourFormat: 'hh A',
            firstDayOfWeek: 0,
        },
    ],
    [
        'en-AU',
        {
            qsLngCode: 'en-US',
            apiCode: 'en_AU',
            hourFormat: 'hh A',
            firstDayOfWeek: 1,
        },
    ],
    [
        'en-CA',
        {
            qsLngCode: 'en-US',
            apiCode: 'en_US',
            hourFormat: 'hh A',
            firstDayOfWeek: 0,
        },
    ],
    [
        'en-GB',
        {
            qsLngCode: 'en-US',
            apiCode: 'en_GB',
            hourFormat: 'HH',
            firstDayOfWeek: 1,
        },
    ],
    [
        'en-US-k12',
        {
            qsLngCode: 'en-US',
            apiCode: 'en_US_k12',
            hourFormat: 'hh A',
            firstDayOfWeek: 0,
        },
    ],
    [
        'en-US-pro',
        {
            qsLngCode: 'en-US',
            apiCode: 'en_US_pro',
            hourFormat: 'hh A',
            firstDayOfWeek: 0,
        },
    ],
    [
        'en-SM',
        {
            qsLngCode: 'en-US',
            apiCode: 'en_SM',
            hourFormat: 'HH A',
            firstDayOfWeek: 0,
        },
    ],
    [
        'de',
        {
            qsLngCode: 'de-DE',
            apiCode: 'de_DE',
            hourFormat: 'HH',
            firstDayOfWeek: 1,
        },
    ],
    [
        'ja',
        {
            qsLngCode: 'ja-JP',
            apiCode: 'ja_JP',
            hourFormat: 'H',
            firstDayOfWeek: 0,
        },
    ],
    [
        'es',
        {
            qsLngCode: 'es-ES',
            apiCode: 'es_ES',
            hourFormat: 'HH',
            firstDayOfWeek: 1,
        },
    ],
    [
        'fr',
        {
            qsLngCode: 'fr-FR',
            apiCode: 'fr_FR',
            hourFormat: 'HH',
            firstDayOfWeek: 1,
        },
    ],
    [
        'it',
        {
            qsLngCode: 'it-IT',
            apiCode: 'it_IT',
            hourFormat: 'HH',
            firstDayOfWeek: 1,
        },
    ],
    [
        'pl',
        {
            qsLngCode: 'pl-PL',
            apiCode: 'pl_PL',
            hourFormat: 'HH',
            firstDayOfWeek: 1,
        },
    ],
    [
        'pt-BR',
        {
            qsLngCode: 'pt-BR',
            apiCode: 'pt_BR',
            hourFormat: 'HH',
            firstDayOfWeek: 0,
        },
    ],
    [
        'ko',
        {
            qsLngCode: 'ko-KR',
            apiCode: 'ko_KR',
            hourFormat: 'HH',
            firstDayOfWeek: 0,
        },
    ],
    [
        'zh-CN',
        {
            qsLngCode: 'zh-CN',
            apiCode: 'zh_CN',
            hourFormat: 'HH',
            firstDayOfWeek: 1,
        },
    ],
    [
        'zh-TW',
        {
            qsLngCode: 'zh-TW',
            apiCode: 'zh_TW',
            hourFormat: 'HH',
            firstDayOfWeek: 0,
        },
    ],
    [
        'da',
        { qsLngCode: 'da-DK', apiCode: '', hourFormat: '', firstDayOfWeek: 1 },
    ],
    [
        'nb',
        {
            qsLngCode: 'nb-NO',
            apiCode: 'nb_NO',
            hourFormat: 'HH',
            firstDayOfWeek: 1,
        },
    ],
    [
        'nl',
        {
            qsLngCode: 'nl-NL',
            apiCode: 'nl_NL',
            hourFormat: 'HH',
            firstDayOfWeek: 1,
        },
    ],
    [
        'sv',
        {
            qsLngCode: 'sv-SE',
            apiCode: 'sv_SE',
            hourFormat: 'HH',
            firstDayOfWeek: 1,
        },
    ],
]);

export const getLanguageFamily = (
    language: string | undefined,
    fallback?: string | undefined,
): string => {
    if (!language) return fallback || 'en';
    return language.split('-')[0];
};

export const lngWhiteList = Array.from(
    new Set(
        langageSelectOptionsWithTestLocales.map(option =>
            getLanguageFamily(option.value),
        ),
    ),
);

export const getSelectedLanguage = (language?: string): string => {
    if (!language) {
        return 'en';
    }

    if (langageSelectOptionsKeys.includes(language)) {
        return language;
    }

    const languageFamily = getLanguageFamily(language);
    if (langageSelectOptionsKeys.includes(languageFamily)) {
        return languageFamily;
    }

    return 'en';
};

const getLocale = (
    map: Map<string, any>,
    key: string | undefined,
    fallback_key: string,
) => {
    if (!key) {
        return map.get(fallback_key);
    }

    return (
        map.get(key) ?? map.get(getLanguageFamily(key)) ?? map.get(fallback_key)
    );
};

export const getReportApiLanguage = (language?: string) =>
    getLocale(reportApiLngMap, language, 'en');

export const getQsLocale = (language?: string): IQSLng =>
    getLocale(qsLngMap, language, 'en');

export const getWordBreakStyle = (
    language?: string,
): React.CSSProperties | undefined => {
    if (getLanguageFamily(language) === 'ja') {
        return { wordBreak: 'keep-all' };
    }
};
