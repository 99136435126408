import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        developerPageContainer: {
            paddingTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(3),
            },
        },
        gridContainer: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: `${theme.spacing(2)}px`,
            [theme.breakpoints.up('md')]: {
                gap: `${theme.spacing(4)}px`,
            },
        },
        twoCards: {
            display: 'grid',
            gap: `${theme.spacing(2)}px`,
            [theme.breakpoints.up('sm')]: {
                gridTemplateColumns: '1fr 1fr',
            },
            [theme.breakpoints.up('md')]: {
                gap: `${theme.spacing(4)}px`,
            },
        },
        threeCards: {
            display: 'grid',
            gap: `${theme.spacing(2)}px`,
            [theme.breakpoints.only('sm')]: {
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: 'auto auto',
                '& > div:first-child': {
                    gridColumn: '1 / -1',
                },
            },
            [theme.breakpoints.up('md')]: {
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: `${theme.spacing(4)}px`,
            },
        },
        stack: {
            display: 'grid',
            padding: '16px',
            gridGap: '16px',
        },
        unorderedList: {
            margin: 0,
            paddingLeft: theme.spacing(4),
        },
        orderedList: {
            margin: 0,
            paddingLeft: theme.spacing(2),
            display: 'grid',
            gridGap: theme.spacing(2),
        },
        // Looks like a link but is really a button
        linkButton: {
            justifyContent: 'flex-start',
        },
        linkButtonText: {
            marginRight: theme.spacing(0.5),
        },
        explorersName: {
            // Typescript complains when I use `theme.typography.fontWeightBold`
            fontWeight: 700,
        },
        snowflakeButton: {
            fontWeight: theme.typography.fontWeightSemiBold,
            display: 'inline-block',
            width: '250px',
            height: '45px',
            [theme.breakpoints.up('md')]: {
                width: '100%',
            },
        },
        errorText: {
            color: theme.palette.error.main,
        },
    });

const options = {
    name: 'DeveloperPage-',
};

export const useStyles = makeStyles(styles, options);
