import {
    makeStyles,
    createStyles,
} from '@bb-ui/react-library/dist/components/styles';

const styles = () =>
    createStyles({
        placeholder: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
        },
    });

const options = {
    name: 'ImageHandler-',
};

export const useStyles = makeStyles(styles, options);
