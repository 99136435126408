import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        title: {
            fontWeight: theme.typography.fontWeightSemiBold,
        },
        image: {
            flex: '1 1',
            objectFit: 'cover',
            objectPosition: 'center',
            transition: 'transform 0.3s ease-in-out',
            '$card:hover &': {
                transform: 'scale(1.03)',
            },
        },
        card: {
            borderRadius: '2px',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: '320px',
            },
            [theme.breakpoints.up('xl')]: {
                minHeight: '350px',
            },
            '&:hover': {
                border: `1px solid ${theme.palette.background.b8}`,
                boxShadow: `0px 4px 6px ${theme.palette.border.main}`,
            },
        },
        contentSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: theme.spacing(2),
            padding: theme.spacing(1),
            flex: '1 1 100%',
            [theme.breakpoints.up('sm')]: {
                padding: `${theme.spacing(3)}px ${theme.spacing(2.5)}px`,
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(3),
            },
        },
    });

const options = {
    name: 'CustomCardSecondary-',
};

export const useStyles = makeStyles(styles, options);
