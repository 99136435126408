import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useLogin } from '../../auth/hooks/useLogin';
import { ISignInCallbackProps } from './SignInCallback.types';

export const SignInCallback: React.FunctionComponent<
    ISignInCallbackProps
> = props => {
    const { uasTenant, setUasTenant } = props;
    const { login } = useLogin();
    const location = useLocation();

    React.useEffect(() => {
        if (uasTenant) {
            // component has rerendered and we have a uas tenant and UASProvider has been set up correctly by the parent component
            login();
        } else {
            // returning from tenant picker with a tenantUriComponent
            const params = new URLSearchParams(location.search);

            setUasTenant(params.get('tenantUriComponent') ?? ''); // changing this changes state and causes us to rerender
        }
    }, [uasTenant, setUasTenant, login, location]);
    return null;
};
