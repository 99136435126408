import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        qSearchBarErrorMessage: {
            fontSize: theme.typography.body1.fontSize,
            fontStyle: 'normal',
        },
        alertText: {
            fontSize: theme.typography.h3.fontSize,
            fontWeight: theme.typography.h3.fontWeight,
            marginBottom: theme.spacing(1),
        },
        alertError: {
            color: theme.palette.error.main,
        },
    });

const options = {
    name: 'QuickSightQError-',
};

export const useStyles = makeStyles(styles, options);
