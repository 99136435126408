import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Questioning } from '@bb-ui/icons/dist/medium';

import { IconButton } from '@bb-ui/react-library';
import { Tooltip } from '@bb-ui/react-library/dist/components/Tooltip';

import { useStyles } from './ActionAreaButtons.styles';

export interface IActionAreaButtonsAction {
    icon: React.ReactNode;
    title: string;
    action: () => void;
}

export interface IActionAreaButtonsProps {
    action?: IActionAreaButtonsAction;
    helpLink: string;
}

export const ActionAreaButtons: React.FunctionComponent<
    IActionAreaButtonsProps
> = ({ action, helpLink }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <aside>
            <div className={classes.actionAreaButtonsContainer}>
                {action && (
                    <Tooltip title={t(action.title)} placement="left">
                        <IconButton
                            className={classes.floatingButton}
                            onClick={action.action}
                            aria-label={t(action.title)}
                        >
                            {action.icon}
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip
                    title={
                        <Trans i18nKey="actionAreaButtons.helpButton.label">
                            <span lang="en">Anthology Illuminate</span> Help
                        </Trans>
                    }
                    placement={action ? 'left' : 'top-end'}
                    id="help-button-tooltip"
                >
                    <a
                        aria-label={t('actionAreaButtons.helpButton.ariaLabel')}
                        className={classes.floatingButton}
                        href={helpLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-testid="help-button"
                    >
                        <Questioning />
                    </a>
                </Tooltip>
            </div>
        </aside>
    );
};
