import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            loadingIndicator: {
                paddingTop: theme.spacing(4),
            },
            frameReset: {
                padding: `0px ${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
            },
            mainBox: {
                overflow: 'visible',
                paddingTop: theme.spacing(1),
                marginBottom: `-${theme.spacing(1)}px`,
            },
            breadcrumbLink: {
                cursor: 'pointer',
            },
            buttonContainer: {
                bottom: 0,
                width: '100%',
                alignItems: 'center',
                padding: theme.spacing(2),
                columnGap: theme.spacing(2),
                justifyContent: 'end',
            },
            cancelButton: {
                width: '13rem',
            },
            saveButton: {
                width: '13rem',
            },
        }),
    {
        name: 'EditUser-',
    },
);
