import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { InputAdornment } from '@bb-ui/react-library/dist/components/InputAdornment';
import { Menu } from '@bb-ui/react-library/dist/components/Menu';
import { MenuItem } from '@bb-ui/react-library/dist/components/MenuItem';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import {
    useTheme,
    useMediaQuery,
} from '@bb-ui/react-library/dist/components/styles';
import { Language } from '@bb-ui/icons/dist/small/Language';

import { useStyles } from './LanguagePicker.styles';
import { useI18nContext } from '../I18nProvider/I18nProvider';
import {
    getSelectedLanguage,
    langageSelectOptionsDefault,
    langageSelectOptionsWithTestLocales,
} from '../../utilities/localization';
import { useFeatureToggle } from '../../featureFlags/useFeatureToggle';

export const LanguagePicker: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { userSelectedLang, switchLanguage } = useI18nContext();
    const classes = useStyles();
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const showTestLocales = useFeatureToggle('showTestLocales');

    const [anchorEl, setAnchor] = React.useState(null);
    const handleClose = () => {
        setAnchor(null);
    };
    const handleClick = (event: any) => {
        setAnchor(event.currentTarget);
    };

    const getMenuItems = () => {
        const menuType = mdUp ? 'select' : 'menu';
        const hideSelectedMenuItem = mdUp;
        const options = showTestLocales
            ? [...langageSelectOptionsWithTestLocales]
            : [...langageSelectOptionsDefault];

        if (hideSelectedMenuItem) {
            options.sort(
                (a, b) =>
                    Number(a.value === getSelectedLanguage(userSelectedLang)) -
                    Number(b.value === getSelectedLanguage(userSelectedLang)),
            );
        }

        return options.map((option, index) => (
            <MenuItem
                key={option.value}
                value={option.value}
                lang={option.value}
                className={
                    option.value === getSelectedLanguage(userSelectedLang)
                        ? classes.selectedMenuItem
                        : undefined
                }
                selected={
                    !hideSelectedMenuItem &&
                    option.value === getSelectedLanguage(userSelectedLang)
                }
                autoFocus={hideSelectedMenuItem && index === 0}
                onClick={() => {
                    if (menuType === 'menu') {
                        switchLanguage(option.value);
                        handleClose();
                    }
                }}
                button
            >
                {option.label}
            </MenuItem>
        ));
    };

    const [menuItems, setMenuItems] = React.useState(getMenuItems());

    return (
        <>
            {mdUp ? (
                <TextField
                    className={classes.languagePicker}
                    select
                    id="language-picker"
                    value={getSelectedLanguage(userSelectedLang)}
                    onChange={event => {
                        switchLanguage(event.target.value);
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                id="inputAdornment-globe"
                                position="start"
                                disableTypography={true}
                            >
                                <Language />
                            </InputAdornment>
                        ),
                        'aria-describedby': 'inputAdornment-globe',
                        classes: {
                            root: classes.inputRoot,
                        },
                    }}
                    label={t('languagePicker.label')}
                    data-testid="language-picker-select"
                    SelectProps={{
                        onTransitionEnd: () => setMenuItems(getMenuItems()),
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.label,
                        },
                    }}
                    // This gets passed through to the FormControl component used internally in MUI
                    classes={{
                        root: classes.container,
                    }}
                >
                    {menuItems}
                </TextField>
            ) : (
                <>
                    <IconButton
                        aria-controls={
                            anchorEl ? 'language-picker-menu' : undefined
                        }
                        aria-expanded={anchorEl !== null}
                        aria-haspopup="menu"
                        aria-label={t('languagePicker.label')}
                        onClick={handleClick}
                        data-testid="language-picker-button"
                    >
                        <Language />
                    </IconButton>
                    <Menu
                        id="language-picker-menu"
                        anchorEl={anchorEl}
                        open={anchorEl !== null}
                        onClose={handleClose}
                        onTransitionEnd={() => setMenuItems(getMenuItems())}
                        MenuListProps={{
                            'aria-label': t('languagePicker.menuLabel'),
                        }}
                    >
                        {menuItems}
                    </Menu>
                </>
            )}
        </>
    );
};
