import {
    QSearchExperience,
    FrameOptions,
    ChangeEvent,
    EmbeddingEvents,
    ExperienceFrameMetadata,
    GenerativeQnAContentOptions,
    GenerativeQnAPanelType,
} from 'amazon-quicksight-embedding-sdk';
import React from 'react';

import classnames from 'classnames';

import { Typography } from '@bb-ui/react-library';
import { useTranslation } from 'react-i18next';
import { Frame } from '../components/Frame/Frame';
import { LoadingIndicator } from '../components/LoadingIndicator/LoadingIndicator';
import { ResponsiveBox } from '../components/ResponsiveBox/ResponsiveBox';

import { useStyles } from './QuickSightQSearchBar.styles';
import {
    EmbeddedQSearchBarState,
    IQuickSightQSearchBarProps,
} from './QuickSightQSearchBar.types';
import { getEmbeddingContext } from './QuickSightEmbeddingContext';
import QuickSightQError from './QuickSightQError/QuickSightQError';

export const QuickSightQSearchBar: React.FunctionComponent<
    IQuickSightQSearchBarProps
> = ({ embedData, isError }) => {
    const { t } = useTranslation();
    const [qSearchBarState, setQSearchBarState] =
        React.useState<EmbeddedQSearchBarState>('notLoaded');

    const classes = useStyles({ qSearchBarState });
    const qSearchBarRef = React.useRef<QSearchExperience>();

    const embed = React.useCallback(async () => {
        const embeddingContext = await getEmbeddingContext();
        const { embedGenerativeQnA } = embeddingContext;

        const frameOptions: FrameOptions = {
            url: embedData.url,
            height: 'AutoFit',
            container: '#q-searchbar-container',
            onChange: (
                changeEvent: EmbeddingEvents,
                metadata: ExperienceFrameMetadata | undefined,
            ) => {
                if (changeEvent instanceof ChangeEvent) {
                    if (changeEvent.eventLevel === 'ERROR') {
                        console.error(
                            'QuickSight Q threw the following error: ',
                            changeEvent,
                        );
                        setQSearchBarState('failedToLoad');
                    }
                }
            },
        };

        const contentOptions: GenerativeQnAContentOptions = {
            panelOptions: {
                panelType: GenerativeQnAPanelType.SEARCH_BAR,
            },
            showTopicName: true,
            showPinboard: true,
            allowTopicSelection: true,
            allowFullscreen: true,
            onMessage: (messageEvent: EmbeddingEvents) => {
                switch (messageEvent.eventName) {
                    case 'CONTENT_LOADED': {
                        setQSearchBarState('loaded');
                        break;
                    }
                    case 'ERROR_OCCURRED': {
                        // Q_INITIAL_TOPIC_NOT_FOUND is a known error that occurs when the topic passed in is not found
                        // We can ignore this error as we don't currently know a default topic ID for any tenant
                        const { errorCode } = messageEvent.message || {};

                        if (
                            errorCode !== 'Q_INITIAL_TOPIC_NOT_FOUND' &&
                            errorCode !== 'Q_TOPIC_EXPERIENCE_MISMATCH'
                        ) {
                            setQSearchBarState('failedToLoad');
                        }
                        break;
                    }
                }
            },
        };

        await embedGenerativeQnA(frameOptions, contentOptions);
    }, [embedData]);

    React.useEffect(() => {
        if (!qSearchBarRef.current) {
            embed().catch(() => {
                setQSearchBarState('failedToLoad');
            });
        }
    }, [embed, qSearchBarRef]);

    return (
        <ResponsiveBox
            className={classes.qSearchBarContainer}
            paddingX={{ xs: 0 }}
            lang="en"
        >
            <Frame
                collapseTop
                className={classnames(
                    classes.qSearchBarFrame,
                    classes.qSearchBarError,
                    classes.qSearchBarReset,
                )}
            >
                {qSearchBarState === 'failedToLoad' || isError ? (
                    <QuickSightQError />
                ) : (
                    <div>
                        <Typography
                            component="p"
                            variant="h3"
                            className={classes.screenSizeWarning}
                        >
                            For the best experience, please try using a device
                            with a larger screen, such as a desktop or tablet.
                        </Typography>
                        <div
                            data-testid="q-searchbar-container"
                            id="q-searchbar-container"
                            title="Q Search Bar"
                            className={classes.qSearchBarMain}
                        />
                        <LoadingIndicator
                            aria-hidden="false"
                            label={t('reports.loading.loadingData')}
                            id="loading-quicksight-q-searchbar"
                            data-testid="loading-quicksight-q-searchbar"
                            className={classes.qSearchBarLoadingIndicator}
                        />
                    </div>
                )}
            </Frame>
        </ResponsiveBox>
    );
};
