import { FeatureName } from '../../featureFlags/Feature.types';

export type OpenNavDrawer = 'none' | 'secondary' | 'global';

export enum ReportingArea {
    Learning = 'Learning',
    Teaching = 'Teaching',
    Leading = 'Leading',
}

export interface IQuickSightDashboard {
    id: string;
    name: string;
    urlSlug: string;
    locale?: ILocalizedReportCard;
    featureFlag?: string;
    helpLink?: string;
}

export interface IQuickSightReport {
    id: string;
    name: string;
    urlSlug: string;
    locale: ILocalizedReportCard;
    reportingArea: ReportingArea;
    featureToggleName?: FeatureName;
    helpLink?: string;
}

export interface ILocalizedReportCard {
    reportTitle: string;
    primaryQuestion: string;
    secondaryQuestions: string[];
    additionInfo?: string;
}

export enum TenantStatus {
    Asleep = 'asleep',
    Provisioning = 'provisioning',
    Awake = 'awake',
}

export enum TenantStatusApiValue {
    Awake = 'awake',
    ProvisioningRequested = 'provisioning_requested',
    DeprovisioningRequested = 'de_provisioning_requested',
    Asleep = 'asleep',
    Excluded = 'excluded',
    InProvisioning = 'in_provisioning',
    InDeprovisioning = 'in_de_provisioning',
}
