import React from 'react';
import { Trans } from 'react-i18next';
import { BbThemeProvider } from '@bb-ui/react-library/dist/components/BbThemeProvider';
import {
    useMediaQuery,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';
import { Box } from '@bb-ui/react-library/dist/components/Box';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useStyles } from './DeveloperPageHero.styles';
import { IDeveloperPageHeroProps } from './DeveloperPageHero.types';

export const DeveloperPageHero: React.FunctionComponent<
    IDeveloperPageHeroProps
> = props => {
    const classes = useStyles(props);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <BbThemeProvider theme="dark">
            <Box width="100%" className={classes.heroContainer}>
                <div className={classes.contentContainer}>
                    <Typography
                        variant={smUp ? 'display2' : 'subtitle1'}
                        className={classes.titleText}
                        component="h1"
                        data-testid="developer-page-title"
                    >
                        { /* prettier-ignore */ }
                        <Trans i18nKey="developer.hero.title">
                            Direct access to your <span lang="en">Anthology Illuminate</span> canonical data model.
                        </Trans>
                    </Typography>
                    <Typography
                        variant={smUp ? 'subtitle1' : 'body2'}
                        component="p"
                        className={classes.text}
                        data-testid="developer-page-hero-text"
                    >
                        { /* prettier-ignore */ }
                        <Trans i18nKey="developer.hero.text">
                            Use <span lang="en">SQL</span> and <span lang="en">Snowflake</span> to consult your data and connect your own <span lang="en">Business Intelligence (BI)</span> tool to build custom reports and dashboards.
                        </Trans>
                    </Typography>
                </div>
                <div className={classes.imageContainer}>
                    <img
                        src="/images/DeveloperPage/heroIllustration.svg"
                        alt=""
                        className={classes.image}
                        loading="lazy"
                    />
                </div>
            </Box>
        </BbThemeProvider>
    );
};
