import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';
import { IMessageProps } from './Message.types';

const styles = (theme: Theme) =>
    createStyles({
        container: (props: IMessageProps) => ({
            display: 'flex',
            flexDirection: 'column',
            background: theme.palette.background.b1,
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(2),
            flex: 1,
            margin: props.noMargin ? 0 : theme.spacing(2.5),
        }),
        attentionIcon: {
            color: theme.palette.error.main,
        },
        primaryMessage: {
            paddingTop: theme.spacing(0.75),
            fontWeight: theme.typography.fontWeightSemiBold,
            fontStyle: 'italic',
        },
        secondaryMessage: {
            fontSize: theme.typography.fontSizeMedium,
        },
    });

const options = {
    name: 'Message-',
};

export const useStyles = makeStyles(styles, options);
