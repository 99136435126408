import {
    makeStyles,
    createStyles,
    Theme,
    getColor,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            baseOutlineLink: {
                borderRadius: '2px',
                border: `1px solid ${theme.palette.text.primary}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'fit-content',
                textDecoration: 'none',
                outline: 'none',
                color: theme.palette.text.primary,
                whiteSpace: 'nowrap',
                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                '&:focus': {
                    border: `1px solid ${theme.palette.focus.main}`,
                    boxShadow: `0px 0px 0px 1px ${theme.palette.focus.light}`,
                },
            },
            outlineLinkLightTheme: {
                backgroundColor: getColor('background.b10', 'dark'),
                '&:hover': {
                    backgroundColor: getColor('background.b9', 'dark'),
                },
            },
            outlineLinkDarkTheme: {
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: getColor('background.b8', 'light'),
                },
            },
            label: {
                whiteSpace: 'nowrap',
            },
        }),
    {
        name: 'OutlineLink-',
    },
);
