import React from 'react';
import { useCounter, useInterval, usePrevious } from 'react-use';

interface UseIntervalWithLimitResult {
    /**
     * Enables the hook, which makes it start calling the callback function
     * every <interval>
     */
    enable: () => void;
    disable: () => void;
    /**
     * Resets the call-limit counter
     */
    resetCounter: () => void;
    isRunning: boolean;
    isStopped: boolean;
}

export const useIntervalWithLimit = (
    callback: () => void,
    interval: number,
    callLimit: number,
    skipCallCondition?: boolean,
): UseIntervalWithLimitResult => {
    const [started, setStarted] = React.useState<boolean>(false);
    const [remaining, { dec, reset, set }] = useCounter(callLimit);
    const previousCallLimit = usePrevious(callLimit);

    React.useEffect(() => {
        if (previousCallLimit && callLimit !== previousCallLimit) {
            set(callLimit);
            dec(previousCallLimit - remaining);
        }
    }, [callLimit, dec, previousCallLimit, remaining, set]);

    const running = remaining > 0 && started;

    useInterval(
        () => {
            if (!skipCallCondition) {
                callback();
                dec();
            }
        },
        running ? interval : null,
    );

    return {
        enable: () => setStarted(true),
        disable: () => setStarted(false),
        resetCounter: reset,
        isRunning: running,
        isStopped: !running,
    };
};
