import * as React from 'react';
import classnames from 'classnames';

import {
    useMediaQuery,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { ResponsiveBox } from '../ResponsiveBox/ResponsiveBox';

import { useStyles } from './MainHeader.styles';
import { IMainHeaderProps } from './MainHeader.types';

export const MainHeader: React.FunctionComponent<IMainHeaderProps> = props => {
    const { details, withSideNavButton, updateFocus, title, children } = props;
    const detailsLength = details ? details.length : 0;
    const classes = useStyles(props);

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <header
            className={classes.container}
            onBlur={() => (updateFocus ? updateFocus(false) : null)}
        >
            <ResponsiveBox>
                <div
                    className={classnames(
                        classes.secondaryContainer,
                        withSideNavButton
                            ? classes.accommodateSideNavButton
                            : '',
                    )}
                >
                    <Typography
                        variant={smUp ? 'h1' : 'subtitle1'}
                        component="h1"
                        data-testid="main-header-title"
                    >
                        {title}
                    </Typography>
                    {details && detailsLength > 0 ? (
                        <ul className={classes.details}>
                            {details.map(detail => (
                                <li
                                    className={classes.detail}
                                    key={detail.label}
                                >
                                    <span>{detail.label}: </span>
                                    <span className={classes.detailValues}>
                                        {detail.values.join(', ')}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    ) : null}
                    {children ? (
                        <div
                            className={classes.child}
                            data-testid="main-header-children"
                        >
                            {children}
                        </div>
                    ) : null}
                </div>
            </ResponsiveBox>
        </header>
    );
};
