import React from 'react';
import { useAuth0Context } from '@bb-ui/auth/dist/auth0/Auth0Context';
import { useAppConfigContext } from '../../../../contexts/AppConfigProvider';
import { useApi, IApiConfig } from '../../../../hooks/useApi';
import { IQuickSightDashboard } from '../../../MainPage/MainPage.types';
import { getTenantId } from '../../../../utilities/utilities';
import { IEmbedDashboardAPI } from '../../../../quicksight/QuickSightReport';
import { useUser } from '../../../../hooks/useUser';
import { useI18nContext } from '../../../I18nProvider/I18nProvider';
import { getReportApiLanguage } from '../../../../utilities/localization';
import { QuickSightVisual } from '../../../../quicksight/QuickSightVisual';

export const SnowflakeCreditsBurndown: React.FunctionComponent = () => {
    const { userSelectedLang } = useI18nContext();
    const apiLocaleSettings = getReportApiLanguage(userSelectedLang);

    const { api, uas } = useAppConfigContext();
    const { user: auth0ContextUser } = useAuth0Context();
    const user = useUser();

    const tenantIdOrLocator = getTenantId(auth0ContextUser, uas);

    const getDashboardsUrl = encodeURI(
        `${
            api!.qsHostname
        }/v1/data/dashboards/tenants/${tenantIdOrLocator}/users/${user?.userId}/dashboards`,
    );

    const getDashboardsConfig = React.useMemo<IApiConfig>(
        () => ({
            url: getDashboardsUrl,
            useAuth: true,
            method: 'GET',
        }),
        [getDashboardsUrl],
    );

    // Known bug: AB#2257725
    // GET dashboards API returns 403 in case there are no remaining seats for the tenant
    // This is required functionality across the app, but it breaks the Snowflake Credits Burndown chart loading
    // there is a workaround in the branch bugfix/2257725-workaround
    const { data: dashboardsData, error: dashboardsError } = useApi<{
        results: IQuickSightDashboard[];
    }>(getDashboardsConfig);

    const burndownDashboardId = React.useMemo(
        () =>
            dashboardsData?.results?.find(({ name }) =>
                name.endsWith('V_RA_CREDIT_BURNDOWN'),
            )?.id,
        [dashboardsData],
    );

    const getEmbedDataUrl = encodeURI(
        `${api!.qsHostname}/v1/data/dashboards/tenants/${tenantIdOrLocator}` +
            `/users/${user?.userId}` +
            `/dashboards/${burndownDashboardId}?locale=${apiLocaleSettings}`,
    );

    const getEmbedDataConfig = React.useMemo<IApiConfig>(
        () => ({
            url: getEmbedDataUrl,
            useAuth: true,
            executeType: 'manual',
        }),
        [getEmbedDataUrl],
    );

    const {
        data: getEmbedData,
        execute: getEmbedExecute,
        error: getEmbedError,
    } = useApi<IEmbedDashboardAPI>(getEmbedDataConfig);

    React.useEffect(() => {
        if (burndownDashboardId) {
            getEmbedExecute();
        }
    }, [getEmbedExecute, burndownDashboardId]);

    const isEmbedDataError =
        !!getEmbedError ||
        !!dashboardsError ||
        (dashboardsData && !burndownDashboardId);

    return (
        <QuickSightVisual embedData={getEmbedData} error={isEmbedDataError} />
    );
};
