export type ListNavVariant = 'global' | 'secondary';

export enum BadgeType {
    NEW = 'NEW',
}

export interface INavListItem {
    label: string;
    path: string;
    // true makes the nav link appear selected only when the path matches exactly
    exact?: boolean;
    disableListItem?: boolean;
    icon?: JSX.Element;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    testId?: string;
    badgeType?: BadgeType;
    keepEnglishLabel?: boolean;
}

export interface IListNavProps {
    listItems: INavListItem[];
    variant: ListNavVariant;
    subtitleStyle?: React.CSSProperties;
}
