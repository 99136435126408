import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            loadingTableCell: {
                height: theme.spacing(5),
                variant: 'text',
                animation: 'wave',
            },
            loadingTableContentDivs: {
                display: 'flex',
                alignItems: 'center',
                height: theme.spacing(7),
                justifyContent: 'space-between',
                borderBottom: '0.0625rem solid rgba(205, 205, 205, 0.5)',
            },
        }),
    {
        name: 'SkeletonTable-',
    },
);
