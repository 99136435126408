import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { CircularProgress } from '@bb-ui/react-library/dist/components/Progress';
import React from 'react';
import { Trans } from 'react-i18next';

import {
    DefaultButton,
    PrimaryButton,
} from '@bb-ui/react-library/dist/components/Button';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';

import { useStyles } from './SnowflakeLauncherModal.styles';
import { SnowflakeLauncherModalState } from './SnowflakeLauncherModal.types';

interface Empty {}

type ContentProps = {
    titleTextKey: string;
    titleTextContent: React.ReactElement;

    progressProps: Partial<{
        error?: boolean;
        success?: boolean;
    }>;

    onClose?: () => void;
} & (
    | {
          aboveProgressTextKey: string;
          aboveProgressTextContent: React.ReactElement;
      }
    | { aboveProgressTextDisabled: true }
) &
    (
        | {
              belowProgressTextKey: string;
              belowProgressTextContent: React.ReactElement;
          }
        | { belowProgressTextDisabled: true }
    ) &
    (
        | {
              primaryButtonTextKey: string;
              primaryButtonOnClick: () => void;
              primaryButtonTextContent: React.ReactElement;
          }
        | Empty
    ) &
    (
        | {
              secondaryButtonTextKey: string;
              secondaryButtonOnClick: () => void;
              secondaryButtonTextContent: React.ReactElement;
          }
        | Empty
    );

const getKey = (key: string) =>
    `developer.cards.accessInstitutionalData.snowflakeUserProvisioning.${key}`;

const SnowflakeLauncherModalContent: React.FC<
    ContentProps & { state: SnowflakeLauncherModalState }
> = props => {
    const styles = useStyles(props.state);

    return (
        <>
            <DialogTitle
                hideCloseButton={!props.onClose}
                className={styles.title}
                onClose={props.onClose}
                data-testid="sf-launcher-modal-title"
                id="snowflake-launcher-modal-title"
            >
                <Trans i18nKey={props.titleTextKey}>
                    {...props.titleTextContent.props.children}
                </Trans>
            </DialogTitle>
            <DialogContent classes={{ root: styles.dialogContent }}>
                {!('aboveProgressTextDisabled' in props) && (
                    <div
                        className={styles.textAboveIndicator}
                        data-testid="sf-launcher-modal-upper-text"
                    >
                        <Trans i18nKey={props.aboveProgressTextKey}>
                            {...props.aboveProgressTextContent.props.children}
                        </Trans>
                    </div>
                )}
                <div className={styles.indicatorWrapper}>
                    <CircularProgress
                        label={
                            'belowProgressTextDisabled' in props
                                ? ''
                                : ((
                                      <span data-testid="sf-launcher-modal-lower-text">
                                          <Trans
                                              i18nKey={
                                                  props.belowProgressTextKey
                                              }
                                          >
                                              {...props.belowProgressTextContent
                                                  .props.children}
                                          </Trans>
                                      </span>
                                  ) as unknown as string)
                        }
                        size={50}
                        id="loadingSnowflakeLauncherModal"
                        {...props.progressProps}
                    />
                </div>
            </DialogContent>
            {('primaryButtonTextKey' in props ||
                'secondaryButtonTextKey' in props) && (
                <DialogActions>
                    {'secondaryButtonTextKey' in props && (
                        <DefaultButton
                            className={styles.button}
                            onClick={props.secondaryButtonOnClick}
                            data-testid="sf-launcher-modal-secondary-btn"
                        >
                            <Trans i18nKey={props.secondaryButtonTextKey}>
                                {...props.secondaryButtonTextContent.props
                                    .children}
                            </Trans>
                        </DefaultButton>
                    )}
                    {'primaryButtonTextKey' in props && (
                        <PrimaryButton
                            className={styles.button}
                            onClick={props.primaryButtonOnClick}
                            data-testid="sf-launcher-modal-primary-btn"
                        >
                            <Trans i18nKey={props.primaryButtonTextKey}>
                                {...props.primaryButtonTextContent.props
                                    .children}
                            </Trans>
                        </PrimaryButton>
                    )}
                </DialogActions>
            )}
        </>
    );
};

export const SnowflakeLauncherModal: React.FC<{
    state: SnowflakeLauncherModalState;
    close: () => void;
    redirect: () => void;
}> = props => {
    const styles = useStyles(props.state);

    const modalContent = React.useMemo(
        () =>
            ({
                [SnowflakeLauncherModalState.IN_PROGRESS]: {
                    titleTextKey: getKey('inProgressTitle'),
                    titleTextContent: (
                        <>
                            Your <span lang="en">Snowflake</span> access is
                            being configured
                        </>
                    ),

                    aboveProgressTextKey: getKey('inProgressText'),
                    aboveProgressTextContent: (
                        <>
                            This process is automatic and will only take a
                            moment.
                        </>
                    ),
                    progressProps: {},
                    belowProgressTextKey: getKey('redirectInfoText'),
                    belowProgressTextContent: (
                        <>
                            You can launch&#32;
                            <span lang="en">Snowflake</span> once the
                            configuration is completed.
                        </>
                    ),
                    onClose: props.close,
                },
                [SnowflakeLauncherModalState.PROVISIONING_FAILED]: {
                    titleTextKey: getKey('provisioningFailureTitle'),
                    titleTextContent: (
                        <>
                            Your <span lang="en">Snowflake</span> access
                            configuration failed
                        </>
                    ),

                    aboveProgressTextKey: getKey('provisioningFailureInfoText'),
                    aboveProgressTextContent: (
                        <>
                            We couldn't configure your&#32;
                            <span lang="en">Snowflake</span> access at this
                            moment
                        </>
                    ),
                    progressProps: {
                        error: true,
                    },
                    belowProgressTextKey: getKey(
                        'provisioningFailureNextUserAction',
                    ),
                    belowProgressTextContent: (
                        <>
                            Try to launch Snowflake&#32;
                            <span lang="en">Snowflake</span> again in a few
                            minutes.
                        </>
                    ),
                    primaryButtonOnClick: props.close,
                    primaryButtonTextKey: 'general.close',
                    primaryButtonTextContent: <>Close</>,
                    onClose: props.close,
                },
                [SnowflakeLauncherModalState.PROVISIONING_SUCCESS]: {
                    titleTextKey: getKey('provisioningFinishedTitle'),
                    titleTextContent: (
                        <>
                            Your <span lang="en">Snowflake</span> access was
                            successfully configured
                        </>
                    ),
                    aboveProgressTextDisabled: true,
                    progressProps: {
                        success: true,
                    },
                    belowProgressTextDisabled: true,
                    primaryButtonTextKey:
                        'developer.cards.accessInstitutionalData.launchSnowflakeButton',
                    primaryButtonOnClick: props.redirect,
                    secondaryButtonOnClick: props.close,
                    secondaryButtonTextKey: 'general.close',
                    secondaryButtonTextContent: <>Close</>,
                    primaryButtonTextContent: (
                        <>
                            Launch <span lang="en">Snowflake</span>
                        </>
                    ),
                    onClose: props.close,
                },
                [SnowflakeLauncherModalState.REDIRECT_FAILED]: {
                    titleTextKey: getKey('snowflakeRedirectFailed'),
                    titleTextContent: (
                        <>
                            Sorry, we couldn't launch&#32;
                            <span lang="en">Snowflake</span>
                        </>
                    ),
                    aboveProgressTextDisabled: true,
                    belowProgressTextKey: getKey(
                        'snowflakeRedirectFailureNextUserAction',
                    ),
                    belowProgressTextContent: (
                        <>Please reload the page and try again.</>
                    ),
                    progressProps: {
                        error: true,
                    },
                    primaryButtonOnClick: props.close,
                    primaryButtonTextKey: 'general.close',
                    primaryButtonTextContent: <>Close</>,
                    onClose: props.close,
                },
            }) as {
                [key in Exclude<
                    SnowflakeLauncherModalState,
                    SnowflakeLauncherModalState.INACTIVE
                >]: ContentProps;
            },
        [props.close, props.redirect],
    );

    if (props.state === SnowflakeLauncherModalState.INACTIVE) {
        return <></>;
    }

    return (
        <Dialog
            open={true}
            aria-labelledby="snowflake-launcher-modal-title"
            PaperProps={{
                className: styles.dialogPaper,
            }}
        >
            <SnowflakeLauncherModalContent
                {...modalContent[props.state]}
                state={props.state}
            />
        </Dialog>
    );
};
