import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) => {
    const BACKGROUND_COLOR = theme.palette.background.b9;

    return createStyles({
        titleSection: {
            [theme.breakpoints.only('xs')]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
        titleText: {
            fontSize: theme.typography.fontSizeLarge,
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.typography.fontSizeXLarge,
            },
            display: 'inline-block',
        },
        discussionIcon: {
            marginRight: theme.spacing(3),
            position: 'relative',
            top: theme.spacing(0.5),
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginBottom: '0',
            },
        },
        normalText: {
            fontSize: theme.typography.fontSizeSmall,
            paddingTop: theme.spacing(2),
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.typography.fontSizeDefault,
            },
        },
        joinTheCommunityContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: BACKGROUND_COLOR,
            borderRadius: '4px',
            color: theme.palette.common.white,
            paddingTop: theme.spacing(3.75),
            paddingBottom: theme.spacing(3.75),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                paddingLeft: theme.spacing(6),
            },
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
                boxShadow: `0px 4px 6px ${theme.palette.border.main}`,
            },
        },
        contentSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        linkContainer: {
            paddingTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(1.5),
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(3),
            },
            [theme.breakpoints.only('xs')]: {
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
    });
};

const options = {
    name: 'JoinTheCommunityBanner-',
};

export const useStyles = makeStyles(styles, options);
