import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        authorPage: {
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            alignSelf: 'center',
        },
        authorPageExpanded: {
            width: '100%',
        },
        headerRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        badge: {
            marginLeft: theme.spacing(1),
        },
        titlesContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2, 0, 2, 0),
        },
        headerText: {
            marginRight: theme.spacing(2),
        },
        mainTitleRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        authoringContainer: {
            display: 'flex',
            minHeight: '70vh',
            width: '100%',
            border: `1px solid ${theme.palette.background.b4}`,
            boxShadow: `1px 5px ${theme.palette.border.main}`,
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(1),
        },
        authoringContainerExpanded: {
            minHeight: '93vh',
        },
        loading: {
            height: '70vh',
            display: 'flex',
            AlignItems: 'center',
            justifyContent: 'center',
        },
        collapseButton: {
            color: `${theme.palette.action.active}`,
            '&:hover': {
                color: `${theme.palette.brand.main}`,
            },
            '&:focus': {
                color: `${theme.palette.brand.main}`,
            },
        },
    });

const options = {
    name: 'CustomReports-',
};

export const useStyles = makeStyles(styles, options);
