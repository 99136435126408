import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        title: {
            fontWeight: theme.typography.fontWeightSemiBold,
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            transition: 'transform 0.3s ease-in-out',
            '$card:hover &': {
                transform: 'scale(1.03)',
            },
        },
        card: {
            borderRadius: '2px',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
            },
            [theme.breakpoints.up('lg')]: {
                height: '460px',
            },
            '&:hover': {
                border: `1px solid ${theme.palette.background.b8}`,
                boxShadow: `0px 4px 6px ${theme.palette.border.main}`,
            },
        },
        imageContainerLeft: {
            [theme.breakpoints.up('sm')]: {
                flex: '1 1 50%',
                height: '100%',
            },
        },
        imageContainerRight: {
            [theme.breakpoints.up('sm')]: {
                order: 2,
                flex: '1 1 50%',
                height: '100%',
            },
        },
        contentSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: `${theme.spacing(3)}px`,
            padding: `${theme.spacing(2)}px`,
            [theme.breakpoints.up('sm')]: {
                padding: `${theme.spacing(3)}px ${theme.spacing(2.5)}px`,
                flex: '1 1 50%',
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(6),
            },
        },
    });

const options = {
    name: 'CustomCardPrimary-',
};

export const useStyles = makeStyles(styles, options);
