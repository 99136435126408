import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    Typography,
} from '@bb-ui/react-library';
import React from 'react';
import { Avatar } from '@bb-ui/react-library/dist/components/Avatar';
import { User } from '@bb-ui/icons/dist/small/User';
import { useTranslation } from 'react-i18next';
import { Messages } from '@bb-ui/icons/dist/small/Messages';
import { useStyles } from './UserInfoContainer.styles';

interface IUserInfoContainerProps {
    userName: string;
    role: string;
}

export const UserInfo: React.FunctionComponent<IUserInfoContainerProps> = ({
    userName,
    role,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div data-testid="user-info-container">
            <List className={classes.list}>
                <ListItem className={classes.listItem}>
                    <ListItemAvatar>
                        <Avatar size="small" alt={userName} />
                    </ListItemAvatar>
                    <Box display="flex" flexDirection="column">
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.box}
                        >
                            <Messages />
                            <Typography
                                variant="body1"
                                className={classes.userName}
                            >
                                {userName}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.roleBox}
                        >
                            <User />
                            <Typography
                                variant="body1"
                                className={classes.role}
                            >
                                {t(
                                    `settings.licenseManagement.filterByRole.${role.toLowerCase()}`,
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </ListItem>
            </List>
        </div>
    );
};
