import React from 'react';

import { Banner } from '@bb-ui/react-library/dist/components/Banner';
import { OutlineButton } from '@bb-ui/react-library/dist/components/Button';
import { Link } from '@bb-ui/react-library';
import { Attention } from '@bb-ui/icons/dist/small/Attention';
import { CheckCircle } from '@bb-ui/icons/dist/small/CheckCircle';
import { Information } from '@bb-ui/icons/dist/small/Information';
import { Trans, useTranslation } from 'react-i18next';
import { useStyles } from './UasMigration.styles';
import { useHelpLinks } from '../../../hooks/useHelpLinks';

import { UasMigrationStatus } from '../../../contexts/UasMigrationContext/UasMigrationStatusContext.types';
import {
    UasMigrationStatusContextProvider,
    useUasMigrationStatusContext,
} from '../../../contexts/UasMigrationContext';
import { UasMigrationDialog } from './UasMigrationDialog';

export const INTERVAL_TO_DISPLAY_SUCCEEDED_MIGRATION_IN_MILLIS: number =
    30 * 24 * 60 * 60 * 1000; // 30 days

export const UasMigrationBanner: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { data, firstExecutionAvailable } = useUasMigrationStatusContext();

    const getHelpLink = useHelpLinks();

    const [uasMigrationModalOpen, setUasMigrationModalOpen] =
        React.useState(false);

    // Info banner (blue) - account/user is IA and status endpoint: NOT_MIGRATED
    const showMigrationAvailableBanner = firstExecutionAvailable;

    // Info banner (blue) - status endpoint: RUNNING
    const showMigrationProgressBanner =
        data?.migrationStatus === UasMigrationStatus.RUNNING;

    // Success banner (green) - status endpoint: SUCCEEDED and migration finished no later than a defined time ago
    const successMessageExpirationDate = React.useMemo(
        () =>
            data?.startDate
                ? new Date(data?.startDate).getTime() +
                  INTERVAL_TO_DISPLAY_SUCCEEDED_MIGRATION_IN_MILLIS
                : 0,
        [data?.startDate],
    );
    const showMigrationSuccessBanner =
        data?.migrationStatus === UasMigrationStatus.SUCCEEDED &&
        successMessageExpirationDate > Date.now();
    const successMessageExpirationString = React.useMemo(
        () =>
            successMessageExpirationDate &&
            new Date(successMessageExpirationDate).toLocaleDateString(),
        [successMessageExpirationDate],
    );

    // Error banner (red) - status endpoint: FAILED or FAILED_CAN_RETRY
    const canRetry =
        data?.migrationStatus === UasMigrationStatus.FAILED_CAN_RETRY;
    const showMigrationFailedBanner =
        canRetry || data?.migrationStatus === UasMigrationStatus.FAILED;

    return (
        <>
            {showMigrationAvailableBanner && (
                <Banner
                    severity="info"
                    icon={<Information />}
                    className={classes.banner}
                    data-testid="migration-available-banner"
                    actions={[
                        <OutlineButton
                            onClick={() => setUasMigrationModalOpen(true)}
                            key="configure-migration"
                            data-testid="configure-migration-button"
                        >
                            {t(
                                'settings.uasMigration.migrationAvailableBanner.button',
                            )}
                        </OutlineButton>,
                    ]}
                >
                    { /* prettier-ignore */ }
                    <Trans i18nKey="settings.uasMigration.migrationAvailableBanner.title">
                        The migration to <span lang="en">Universal Authentication System</span> is available.
                        <Link href={getHelpLink('uasMigration')}
                              target="_blank"
                              rel="noopener noreferrer"
                        >
                            How it works
                        </Link>
                        .
                    </Trans>
                </Banner>
            )}
            {showMigrationProgressBanner && (
                <Banner
                    severity="info"
                    icon={<Information />}
                    className={classes.banner}
                    data-testid="uas-migration-progress-banner"
                >
                    {/* prettier-ignore */}
                    <Trans i18nKey="settings.uasMigration.migrationProgressBanner.title">
                        The migration to <span lang="en">Universal Authentication System</span> is in progress.
                        Check back in several minutes to confirm it's done.
                    </Trans>
                </Banner>
            )}
            {showMigrationSuccessBanner && (
                <Banner
                    severity="success"
                    icon={<CheckCircle />}
                    className={classes.banner}
                    data-testid="uas-migration-success-banner"
                >
                    {/* prettier-ignore */}
                    <Trans
                        i18nKey="settings.uasMigration.migrationSuccessBanner.title"
                        values={{date:successMessageExpirationString}}
                    >
                        The migration to <span lang="en">Universal Authentication System</span> was successful.
                        This message will disappear on {{date: successMessageExpirationString}}."
                    </Trans>
                </Banner>
            )}
            {showMigrationFailedBanner && (
                <Banner
                    severity="error"
                    icon={<Attention />}
                    className={classes.banner}
                    data-testid="migration-error-banner"
                    actions={
                        canRetry
                            ? [
                                  <OutlineButton
                                      onClick={() => {
                                          setUasMigrationModalOpen(true);
                                      }}
                                      key="reconfigure-migration"
                                      data-testid="reconfigure-migration-button"
                                  >
                                      {t(
                                          'settings.uasMigration.migrationFailedCanRetryBanner.button',
                                      )}
                                  </OutlineButton>,
                              ]
                            : []
                    }
                >
                    {canRetry ? (
                        /* prettier-ignore */
                        <Trans i18nKey="settings.uasMigration.migrationFailedCanRetryBanner.title">
                            The migration to <span lang="en">Universal Authentication System</span>
                            couldn’t be completed. Please try again or
                            <Link
                                href={getHelpLink('contactSupport')}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                contact support
                            </Link>
                            .
                        </Trans>
                    ) : (
                        /* prettier-ignore */
                        <Trans i18nKey="settings.uasMigration.migrationFailedBanner.title">
                            The migration to <span lang="en">Universal Authentication System</span>
                            couldn’t be completed. To get assistance, please
                            <Link
                                href={getHelpLink('contactSupport')}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                contact support
                            </Link>
                            .
                        </Trans>
                    )}
                </Banner>
            )}
            {uasMigrationModalOpen && (
                <UasMigrationDialog
                    onClose={() => setUasMigrationModalOpen(false)}
                />
            )}
        </>
    );
};

export const UasMigration: React.FC = () => (
    <UasMigrationStatusContextProvider>
        <UasMigrationBanner />
    </UasMigrationStatusContextProvider>
);
