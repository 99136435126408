import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';

import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { useStyles } from './SnowflakeLauncher.styles';
import {
    SnowflakeInfoState,
    useTenantSnowflakeInfo,
} from '../../hooks/useTenantSnowflakeInfo';
import {
    SnowflakeUserProvisioningState,
    useSnowflakeUserProvisioning,
} from '../../hooks/useSnowflakeUserProvisioning';
import { SnowflakeLauncherModal } from './SnowflakeLauncherModal';
import { SnowflakeLauncherModalState } from './SnowflakeLauncherModal.types';

export const SnowflakeLauncher: React.FunctionComponent<{
    ButtonComponent?: any;
    className?: string;
}> = ({ ButtonComponent = PrimaryButton, className }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const provisioning = useSnowflakeUserProvisioning();

    const snowflakeInfo = useTenantSnowflakeInfo();

    const [modalState, setModalState] =
        React.useState<SnowflakeLauncherModalState>(
            SnowflakeLauncherModalState.INACTIVE,
        );

    const [provisioningRestarted, setProvisioningRestarted] =
        React.useState<boolean>(false);

    const [snowflakeLaunchInitiated, setSnowflakeLaunchInitiated] =
        React.useState(false);

    const [redirectFailed, setRedirectFailed] = React.useState<boolean>(false);

    const [redirectInitiated, setRedirectInitiated] =
        React.useState<boolean>(false);

    const redirectToSnowflake = React.useCallback(
        () => setRedirectInitiated(true),
        [],
    );

    React.useEffect(() => {
        // wait for provisioning to switch from FAILED state when initiating restart
        if (
            provisioningRestarted &&
            provisioning.state ===
                SnowflakeUserProvisioningState.GETTING_INITIAL_STATE
        ) {
            setProvisioningRestarted(false);
            setSnowflakeLaunchInitiated(true);
        }
    }, [provisioning.state, provisioningRestarted]);

    React.useEffect(() => {
        if (
            redirectInitiated &&
            snowflakeInfo.state === SnowflakeInfoState.SUCCESS &&
            snowflakeInfo.snowflakeUrl
        ) {
            window.open(
                snowflakeInfo.snowflakeUrl,
                '_blank',
                'noopener noreferrer',
            );
            setRedirectInitiated(false);
            setRedirectFailed(false);
        } else if (
            redirectInitiated &&
            snowflakeInfo.state === SnowflakeInfoState.FAILED
        ) {
            setRedirectFailed(true);
            setRedirectInitiated(false);

            // so that clicking on the button again causes re-fetch
            snowflakeInfo.restart();
        }
    }, [snowflakeInfo, redirectInitiated]);

    React.useEffect(() => {
        if (
            redirectFailed &&
            modalState === SnowflakeLauncherModalState.PROVISIONING_SUCCESS
        ) {
            setModalState(SnowflakeLauncherModalState.REDIRECT_FAILED);
        }
    }, [redirectFailed, modalState]);

    React.useEffect(() => {
        // send initial GET request to check state when provisioning is intiated
        if (
            snowflakeLaunchInitiated &&
            provisioning.state === SnowflakeUserProvisioningState.UNKNOWN_STATE
        ) {
            provisioning.getProvisioningState();
        }
    }, [snowflakeLaunchInitiated, provisioning]);

    React.useEffect(() => {
        if (
            snowflakeLaunchInitiated &&
            snowflakeInfo.state === SnowflakeInfoState.NOT_FETCHED
        ) {
            snowflakeInfo.fetch();
        }
    }, [snowflakeLaunchInitiated, snowflakeInfo]);

    React.useEffect(() => {
        if (!snowflakeLaunchInitiated) {
            return;
        }
        // redirect to snowflake straight away if user already is provisioned or
        // provisioning is not supported (incremental auth)
        if (
            (provisioning.state ===
                SnowflakeUserProvisioningState.NOT_SUPPORTED ||
                provisioning.state === SnowflakeUserProvisioningState.READY) &&
            (snowflakeInfo.state === SnowflakeInfoState.SUCCESS ||
                snowflakeInfo.state === SnowflakeInfoState.FAILED)
        ) {
            setSnowflakeLaunchInitiated(false);
            redirectToSnowflake();
        }
        if (
            provisioning.state === SnowflakeUserProvisioningState.IN_PROGRESS &&
            (snowflakeInfo.state === SnowflakeInfoState.SUCCESS ||
                snowflakeInfo.state === SnowflakeInfoState.FAILED)
        ) {
            setSnowflakeLaunchInitiated(false);
            setModalState(SnowflakeLauncherModalState.IN_PROGRESS);
        }

        if (
            provisioning.state ===
                SnowflakeUserProvisioningState.NOT_PROVISIONED &&
            (snowflakeInfo.state === SnowflakeInfoState.SUCCESS ||
                snowflakeInfo.state === SnowflakeInfoState.FAILED)
        ) {
            // open the "in progress" modal & start provisioning if user is not provisioned yet
            setSnowflakeLaunchInitiated(false);
            provisioning.invokeProvisioning();
            setModalState(SnowflakeLauncherModalState.IN_PROGRESS);
        }

        if (provisioning.state === SnowflakeUserProvisioningState.FAILED) {
            setSnowflakeLaunchInitiated(false);
            setModalState(SnowflakeLauncherModalState.PROVISIONING_FAILED);
        }
    }, [
        provisioning,
        redirectToSnowflake,
        snowflakeInfo,
        snowflakeLaunchInitiated,
    ]);

    React.useEffect(() => {
        if (modalState !== SnowflakeLauncherModalState.IN_PROGRESS) {
            return;
        }

        if (provisioning.state === SnowflakeUserProvisioningState.READY) {
            setModalState(SnowflakeLauncherModalState.PROVISIONING_SUCCESS);
        }

        if (provisioning.state === SnowflakeUserProvisioningState.FAILED) {
            setModalState(SnowflakeLauncherModalState.PROVISIONING_FAILED);
        }
    }, [modalState, provisioning]);

    return (
        <>
            <SnowflakeLauncherModal
                state={modalState}
                close={() => {
                    setModalState(SnowflakeLauncherModalState.INACTIVE);
                    // to clear the error message, since it's supposed to be shown in modal or beneath the button
                    setRedirectFailed(false);
                }}
                redirect={redirectToSnowflake}
            />
            {ButtonComponent === PrimaryButton && (
                <Typography data-testid="developer-page-snowflake-access-text">
                    <Trans i18nKey="developer.cards.accessInstitutionalData.text">
                        Use <span lang="en">Snowflake</span> to run customized
                        <span lang="en">SQL</span> queries and analysis based on
                        your institutional needs.
                    </Trans>
                </Typography>
            )}
            <ButtonComponent
                onClick={() => {
                    if (
                        provisioning.state ===
                        SnowflakeUserProvisioningState.FAILED
                    ) {
                        provisioning.retryProvisioning();
                        setProvisioningRestarted(true);
                    } else {
                        setSnowflakeLaunchInitiated(true);
                    }
                }}
                className={className || classes.launchSnowflakeButton}
                data-testid="developer-page-launch-snowflake"
            >
                {snowflakeLaunchInitiated ? (
                    <span
                        aria-label={t(
                            'developer.cards.accessInstitutionalData.loading',
                        )}
                        role="status"
                    >
                        <LoadingIndicator
                            aria-label={
                                <Trans i18nKey="developer.cards.accessInstitutionalData.launcherLoadingAriaLabel">
                                    <span lang="en">Snowflake</span> launcher
                                    loading
                                </Trans>
                            }
                            size={24}
                            id="loadingSnowflakeLauncher"
                        />
                    </span>
                ) : (
                    // Wrapping with span just to add a test id
                    <span data-testid="launch-snowflake-button-text">
                        <Trans i18nKey="developer.cards.accessInstitutionalData.launchSnowflakeButton">
                            Launch <span lang="en">Snowflake</span>
                        </Trans>
                    </span>
                )}
            </ButtonComponent>

            {modalState === SnowflakeLauncherModalState.INACTIVE &&
                redirectFailed && (
                    <Typography
                        role="alert"
                        component="p"
                        variant="body2"
                        className={classes.launchButtonErrorText}
                        data-testid="snowflake-locator-error-message"
                    >
                        <Trans i18nKey="developer.cards.accessInstitutionalData.launchError">
                            Sorry, we couldn't launch
                            <span lang="en">Snowflake</span>. Please reload the
                            page and try again.
                        </Trans>
                    </Typography>
                )}
        </>
    );
};
