import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { AlertError } from '@bb-ui/icons/dist/medium';
import { useStyles } from './QuickSightQError.styles';
import { Message } from '../../components/Message/Message';

const QuickSightQError: React.FC = () => {
    const classes = useStyles();
    return (
        <Message
            message={
                <div className={classes.qSearchBarErrorMessage}>
                    <AlertError className={classes.alertError} />
                    <Typography className={classes.alertText}>
                        Data Q&A search bar didn’t load properly
                    </Typography>
                    <Typography>
                        Try refreshing the page. If the problem continues,
                        contact Support on{' '}
                        <Link
                            href="https://blackboard.my.salesforce-sites.com/btbb_publichome"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Behind the Blackboard.
                        </Link>
                    </Typography>
                </div>
            }
            variant="info"
        />
    );
};

export default QuickSightQError;
