import * as React from 'react';
import { useLocalStorage } from 'react-use';
import merge from 'lodash/merge';

import config from '../../configs/config.json';

import { AppConfigProps, ConfigData } from './AppConfigProvider.types';

export const AppConfigContext = React.createContext<AppConfigProps>({
    featureFlags: {},
    managedFeatureFlags: [],
    region: undefined,
    setRegion: () => {},
    regions: [],
});

export const useAppConfigContext = (): AppConfigProps =>
    React.useContext(AppConfigContext);
export const auth0DefaultRegionLocalStorageName = 'auth0DefaultRegion';

export const AppConfigProvider: React.FunctionComponent = ({ children }) => {
    const configData = config as any as ConfigData;

    const [selectedRegion, setSelectedRegion] = useLocalStorage<
        string | undefined
    >(
        auth0DefaultRegionLocalStorageName,
        configData.regional ? Object.keys(configData.regional)[0] : undefined,
    );

    const regionalConfig = {};
    merge(
        regionalConfig,
        configData.global,
        (selectedRegion && (configData.regional ?? {})[selectedRegion]) || {},
    );

    const context: AppConfigProps = {
        featureFlags: {},
        managedFeatureFlags: [],
        ...regionalConfig,
        region: selectedRegion,
        setRegion: regionName => {
            setSelectedRegion(regionName);
        },
        regions: Object.keys(configData.regional || {}),
    };
    return (
        <AppConfigContext.Provider value={context}>
            {children}
        </AppConfigContext.Provider>
    );
};
