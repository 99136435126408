import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';
import { zIndex } from '../../utilities/sharedStyles';

const globalNavDrawerWidth = 260;
const secondaryNavDrawerWidth = 220;

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            mainContainer: {
                display: 'flex',
                minHeight: '100vh',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.b3,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.standard,
                }),
            },
            globalNavDrawerOpen: {
                marginLeft: globalNavDrawerWidth,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.standard,
                }),
            },
            secondaryContainer: {
                display: 'flex',
                minHeight: 'calc(100vh - 70px)',
                flexDirection: 'column',
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.standard,
                }),
            },
            secondaryNavDrawerOpen: {
                marginLeft: secondaryNavDrawerWidth,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.standard,
                }),
            },
            skipLink: {
                // Needs to appear layered on top of secondary nav drawer
                zIndex: theme.zIndex.drawer + 1,
            },
            content: {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
            },
            loadingIndicator: {
                height: '50vh',
                display: 'flex',
                verticalAlign: 'middle',
            },
            navFlexColumnWrapper: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                height: '100%',
            },

            navSection: {
                // Tried using theme.palette.border.main but it uses the light
                // theme color instead of the dark theme color, not sure why
                borderTop: `1px solid #8c8c8c`,
                marginTop: theme.spacing(0.5),
            },
            globalNavButton: {
                '&:hover': {
                    textDecoration: 'none',
                    backgroundColor: theme.palette.background.b2,
                    '& .primary-text': {
                        textDecoration: 'none',
                    },
                },
            },
            secondaryNavDrawerPaper: {
                width: secondaryNavDrawerWidth,
                [theme.breakpoints.up('md')]: {
                    marginTop: '70px',
                    transition: `${theme.transitions.create(['transform'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.standard,
                    })} !important`,
                },
            },
            secondaryNavTopSection: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: theme.spacing(2),
            },
            secondaryNavTitle: {
                padding: theme.spacing(0, 1.5),
            },
            openSecondaryNavDrawerButton: {
                color: theme.palette.common.white,
                backgroundColor: '#8D2E9E',
                width: '30px',
                height: '39px',
                position: 'fixed',
                top: '84px',
                left: 0,
                zIndex: zIndex.navButton,
                borderTopRightRadius: theme.spacing(0.75),
                borderBottomRightRadius: theme.spacing(0.75),
                '&:focus': {
                    boxShadow: `0px 0px 0px 4px ${theme.palette.focus.light}`,
                },
                // These `top` values are set to align the button with the title from the MainHeader component
                [theme.breakpoints.up('sm')]: {
                    top: '88px',
                },
                [theme.breakpoints.up('md')]: {
                    top: '92px',
                },
                [theme.breakpoints.up('lg')]: {
                    top: '88px',
                    width: '52px',
                    height: '52px',
                },
            },
            closeSecondaryNavDrawerButton: {
                '&:focus': {
                    color: theme.palette.common.white,
                },
            },
            IdleSnackbarButton: {
                color: theme.palette.common.white,
                direction: 'rtl',
            },
        }),
    {
        name: 'MainPage-',
    },
);
