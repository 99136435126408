import React from 'react';
import {
    makeStyles,
    createStyles,
} from '@bb-ui/react-library/dist/components/styles';

const useStyles = makeStyles(
    () =>
        createStyles({
            screenReaderOnly: {
                clipPath: 'inset(100%)',
                clip: 'rect(1px 1px 1px 1px)',
                height: '1px',
                overflow: 'hidden',
                position: 'absolute',
                whiteSpace: 'nowrap',
                width: '1px',
            },
        }),
    {
        name: 'ScreenReaderOnly-',
    },
);

interface ScreenReaderOnlyProps extends React.HTMLProps<HTMLDivElement> {
    type: 'p' | 'div' | 'span';
}

export const ScreenReaderOnly = React.forwardRef<
    HTMLElement,
    React.PropsWithChildren<ScreenReaderOnlyProps>
>((props, ref) => {
    const UsedHtmlTag = props.type;
    const styles = useStyles();

    return (
        <UsedHtmlTag
            {...props}
            ref={ref as any}
            className={styles.screenReaderOnly}
            tabIndex={-1}
        >
            {props.children}
        </UsedHtmlTag>
    );
});

ScreenReaderOnly.displayName = 'ScreenReaderOnly';
