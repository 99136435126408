import * as React from 'react';

export const toTitleCase = (text: string) =>
    text
        .replace(/-/g, ' ')
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

// Tranform text into camel case format.
// Works for kebab cased text and space separated text
export const toCamelCase = (text: string) => {
    const tempString: string = text
        .toLowerCase()
        .replace(/(?:(^.)|([-_\s]+.))/g, match =>
            match.charAt(match.length - 1).toUpperCase(),
        );
    return tempString.charAt(0).toLowerCase() + tempString.substring(1);
};

// Returns a new string with all dots replaced with dashes
export const dotToDash = (text: string) => text.replace(/\./g, '-');

// Returns a new string with all dashes replaced with dots
export const dashToDot = (text: string) => text.replace(/-/g, '.');

// From response to https://stackoverflow.com/questions/5999118/how-can-i-add-or-update-a-query-string-parameter
// Updated to use ES6 variable declarations and template strings, as well as eslint rules
export const updateQueryString = (
    key: string,
    value: string | number | boolean,
    url: string,
) => {
    const re = new RegExp(`([?&])${key}=.*?(&|#|$)(.*)`, 'gi');
    let hash;
    let newUrl;

    if (re.test(url)) {
        if (typeof value !== 'undefined' && value !== null) {
            return url.replace(re, `$1${key}=${value}$2$3`);
        }
        hash = url.split('#');
        newUrl = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
        if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
            newUrl += `#${hash[1]}`;
        }
        return newUrl;
    }
    if (typeof value !== 'undefined' && value !== null) {
        const separator = url.indexOf('?') !== -1 ? '&' : '?';
        hash = url.split('#');
        newUrl = `${hash[0]}${separator}${key}=${value}`;
        if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
            newUrl += `#${hash[1]}`;
        }
        return newUrl;
    }
    return url;
};

interface IPartsObject {
    schema?: string;
    table?: string;
    column?: string;
}

// Take an ID in the format `SCHEMA.TABLE.COLUMN` and return an object with three properties
// schema, table, and column
// The TABLE and COLUMN portions of the input ID are optional, so either COLUMN can be left out,
// or both TABLE and COLUMN can be left out.
export const splitDBId = (id: string) => {
    const partsArray = id.split('.');
    const partsObject: IPartsObject = {};

    if (partsArray.length === 1) {
        [partsObject.schema] = partsArray;
    } else if (partsArray.length === 2) {
        [partsObject.schema, partsObject.table] = partsArray;
    } else if (partsArray.length === 3) {
        [partsObject.schema, partsObject.table, partsObject.column] =
            partsArray;
    }

    return partsObject;
};

interface WithLocalUID {
    localUID: number;
}

// Add an id to each object that is unique within the given array of objects (locally)
// It does not have to be unique when compared to other arrays (globally)
// Using standard javascript function (not arrow function) because of generics
export const addLocalUIds = function addUniqueLocalUIds<T>(
    objectsArray: T[],
): (T & WithLocalUID)[] {
    return objectsArray.map((object: T, index: number) => ({
        ...object,
        localUID: index,
    }));
};

// This takes a string that may have special symbols, like the registered trademark symbol, and returns JSX element
// with the special symbol adjusted, for example, to be a superscript or a subscript.
// Currently this only adjusts the registered trademark symbol.
export const withAdjustedSymbols = (text: string) =>
    text.split('').map((symbol: string) => {
        if (symbol.charCodeAt(0) === 174) {
            return <sup key={symbol}>{symbol}</sup>;
        }
        return symbol;
    });

// This can be used to simulate a long running asynchronous request with the async await syntax
// Example: In an async function, put `await timeout(3000)`
export function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const formatVersion = (
    majorVersion: number | null,
    minorVersion: number | null,
) =>
    majorVersion === null || minorVersion === null
        ? '-'
        : `${majorVersion}.${minorVersion}`;

export const focusTopOfPage = () => document.querySelector('body')?.focus();

export const getTenantId = (
    auth0ContextUser: any,
    uas?: { bbNamespace: string },
) =>
    // This check is needed because the namespace for Inc Auth will always be https://bb-foundations.com,
    // even in prod, so we still need to support this. If a user logs in with UAS auth,
    // we'll check if https://blackboard.com/tenantId is in the user object and for Inc auth,
    // this will return false so we return a default
    auth0ContextUser[`${uas?.bbNamespace}/tenantId`] ??
    auth0ContextUser['https://bb-foundations.com/tenantId'];

/** creates a span to place the objectId part into to allow the string to flex */
const createSpan = (
    key: string,
    part: string | JSX.Element[],
    splitCharacter?: string,
) => (
    <span key={key}>
        {part}
        {splitCharacter}
    </span>
);
/**
 * Takes a Data Dictionary Object ID in the format of CDM_LMS.TABLE_SCHEMA.ROW_NAME
 * and allows the object ID to flex in the space given by placing a part into a span
 * @param objectId string
 * @returns an array of elements or a single element
 * @example
 * input: CDM_LMS.TABLE_SCHEMA.ROW_NAME
 * output: <span>CDM_</span>
 *         <span>LMS.</span>
 *         <span>TABLE_</span>
 *         <span>SCHEMA.</span>
 *         <span>ROW_</span>
 *         <span>NAME</span>
 */
export const formatObjectId = (objectId: string) => {
    const idParts = objectId.split('.');

    if (idParts.length === 0) {
        return null;
    }

    return idParts.map((part, index) => {
        const key = part + index;

        if (part.includes('_')) {
            const partWithUnderscore = part.split('_');
            return partWithUnderscore.map((text, textIndex) => {
                const textKey = text + textIndex;
                // when the partWithUnderscore and part are both last, do not add a period
                if (
                    textIndex === partWithUnderscore.length - 1 &&
                    index === idParts.length - 1
                ) {
                    return createSpan(textKey, text);
                }
                // when partWithUnderscore is last, add a period
                if (textIndex === partWithUnderscore.length - 1) {
                    return createSpan(textKey, text, '.');
                }

                return createSpan(textKey, text, '_');
            });
        }

        if (index === idParts.length - 1) {
            return createSpan(key, part);
        }

        return createSpan(key, part, '.');
    });
};
