import {
    makeStyles,
    createStyles,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    () =>
        createStyles({
            paper: {
                minHeight: '70vh',
            },
            title: {
                paddingBottom: '22px',
            },
            tabs: {
                paddingBottom: '2%',
            },
        }),
    {
        name: 'UserSettings-',
    },
);
