import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            regionPickerMenuItemContent: {
                display: 'grid',
                gridTemplateColumns: 'auto 30px',
                width: '100%',
            },
            regionPickerSelect: {
                width: '311px',
                height: '38px',
                backgroundColor: 'white',
                color: 'black',
                fontSize: '14px',
                lineHeight: '38px',
                paddingLeft: '10px',
                userSelect: 'none',
                display: 'grid',
                gridTemplateColumns: 'auto 30px',
                alignItems: 'center',
            },
            regionPickerSelectBig: {
                width: '311px',
                height: '48px',
                backgroundColor: 'white',
                color: 'black',
                fontSize: '14px',
                paddingLeft: '10px',
                userSelect: 'none',
                display: 'grid',
                gridTemplateColumns: 'auto 30px',
                alignItems: 'center',
            },
            regionPickerSelectWithFlag: {
                gridTemplateColumns: 'auto 30px 30px !important',
            },
            regionPickerIcon: {
                height: '21px',
                width: '15px',
            },
            regionPickerMenuItem: {
                '&:hover': {
                    color: 'white',
                    backgroundColor: '#262626',
                    textDecoration: 'underline',
                },
            },
            regionPickerMenuContainer: {
                backgroundColor: 'white',
                color: 'black',
                width: '311px',
                zIndex: 1000000000000,
                border: '1px solid #CDCDCD',
                top: '4px !important',
                boxSizing: 'border-box',
                borderRadius: '2px',
            },
            regionPickerHeader: {
                fontWeight: 600,
                fontSize: '16px',
            },
            regionPickerHeaderContainer: {
                height: '69px',
                display: 'flex',
                justifyContent: 'center',
            },
            regionPickerContainer: {
                height: '200px',
            },
            regionPickerButton: {
                width: '140px',
                height: '40px',
                fontWeight: 600,
                fontSize: '14px',
                textTransform: 'uppercase',
            },
            regionPickerItemContainer: {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            },
            regionPickerButtonContainer: {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '40px',
            },
            dialogPaper: {
                backgroundColor: theme.palette.common.black,
                padding: theme.spacing(2),
                width: '96vw',
                borderRadius: theme.spacing(2),
                [theme.breakpoints.up('sm')]: {
                    width: '600px',
                    padding: theme.spacing(4),
                },
            },
            dialogTitle: {
                borderBottom: 'none !important',
                marginTop: theme.spacing(4),
                '& h1': {
                    color: 'transparent',
                    width: '100%',
                },
                '& button': {
                    position: 'absolute',
                    top: theme.spacing(1),
                    right: theme.spacing(1),
                },
                [theme.breakpoints.up('sm')]: {
                    '& button': {
                        top: theme.spacing(2),
                        right: theme.spacing(2),
                    },
                },
            },
            dialogContent: {
                padding: theme.spacing(2, 4, 4, 4),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            selectSignInText: {
                marginBottom: theme.spacing(2),
                fontSize: theme.typography.fontSizeMedium,
                fontWeight: theme.typography.fontWeightRegular,
            },
            orText: {
                marginBottom: theme.spacing(2),
                fontSize: theme.typography.fontSizeMedium,
                fontWeight: theme.typography.fontWeightRegular,
            },
            dialogButton: {
                fontSize: theme.typography.fontSizeMedium,
                fontWeight: theme.typography.fontWeightSemiBold,
                padding: theme.spacing(2),
                marginBottom: theme.spacing(3),
                borderRadius: 0,
                // Content can be wrapped incorrectly if this is not set to 'inline'. See MVK-208
                display: 'inline',
            },
        }),
    {
        name: 'SignInModal-',
    },
);
