import {
    createStyles,
    makeStyles,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    createStyles({
        banner: {
            marginBottom: '2%',
            minHeight: '62px',
        },
        dialog: {
            maxWidth: '450px !important',
            minHeight: '260px',
        },
    }),
    {
        name: 'UasMigration-',
    },
);
