import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    createStyles,
    makeStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    PrimaryButton,
    DialogActions,
} from '@bb-ui/react-library';
import { Information } from '@bb-ui/icons/dist/medium/Information';
import { Attention } from '@bb-ui/icons/dist/medium/Attention';
import classNames from 'classnames';
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        message: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            marginBottom: theme.spacing(2),
        },
        errorIcon: {
            color: theme.palette.error.main,
        },
        textMessage: {
            textAlign: 'center',
        },
        title: {
            fontWeight: theme.typography.fontWeightSemiBold,
        },
        description: {
            marginBottom: theme.spacing(0),
            fontWeight: theme.typography.fontWeightLight,
        },
    }),
);

interface UasMigrationStatusDialogProps {
    onClose: () => void;
    open: boolean;
    loading?: boolean;
    error?: any;
}

export const UasMigrationStatusDialog: FunctionComponent<
    UasMigrationStatusDialogProps
> = ({ onClose, open, loading, error }) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const dialogId = 'uas-migration-status';

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            onClose={onClose}
            aria-labelledby={`${dialogId}-dialog-title`}
            data-testid={`${dialogId}-dialog`}
        >
            <DialogTitle onClose={onClose} id={`${dialogId}-dialog-title`}>
                { /* prettier-ignore */}
                <Trans i18nKey="settings.uasMigration.title">
                    Migration to <span lang="en">Universal Authentication</span>
                    (<span lang="en">UAS</span>)
                </Trans>
            </DialogTitle>
            <DialogContent className={classes.message}>
                {loading && (
                    <LoadingIndicator
                        id="uas-migration-launching-indicator"
                        label={t('settings.uasMigration.statusDialog.starting')}
                    />
                )}
                {error && (
                    <>
                        <Attention
                            className={classNames(
                                classes.icon,
                                classes.errorIcon,
                            )}
                        />
                        <div className={classes.textMessage}>
                            <DialogContentText className={classes.title}>
                                {t(
                                    'settings.uasMigration.statusDialog.startFailed',
                                )}
                            </DialogContentText>
                            <DialogContentText className={classes.description}>
                                {t(
                                    'settings.uasMigration.statusDialog.startFailHint',
                                )}
                            </DialogContentText>
                        </div>
                    </>
                )}
                {!loading && !error && (
                    <>
                        <Information className={classes.icon} />
                        <div className={classes.textMessage}>
                            <DialogContentText className={classes.title}>
                                {t(
                                    'settings.uasMigration.statusDialog.started',
                                )}
                            </DialogContentText>
                            <DialogContentText className={classes.description}>
                                {t(
                                    'settings.uasMigration.statusDialog.checkBack',
                                )}
                            </DialogContentText>
                            <DialogContentText className={classes.description}>
                                {t(
                                    'settings.uasMigration.statusDialog.closeModal',
                                )}
                            </DialogContentText>
                        </div>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <PrimaryButton
                    data-testid={`${dialogId}-close-button`}
                    onClick={onClose}
                    disabled={loading}
                >
                    {t('general.close')}
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
