import * as React from 'react';

import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';
import { BoxProps } from '@material-ui/core/Box';
import { Box } from '@bb-ui/react-library/dist/components/Box';
import classNames from 'classnames';

/**
 *
 * The `ResponsiveBox` component provides a convenient way to apply
 * the standard horizontal padding, width, and centering used for most components.
 *
 * These "standard" values by breakpoint are:
 *
 *      xs: paddingX = 2 units (16px)
 *      sm / md: paddingX = 6 units (48px) - This is set this way because of the secondary nav button
 *      lg / xl: paddingX = 8 units (64px), marginX = auto, width = width of lg breakpoint
 *
 * To override the width limiting and auto margins for the lg / xl breakpoints, pass
 * in the `fullWidth` prop (as true)
 *
 * To override the horizontal padding, pass in an object for the `paddingX` prop with the values
 * you want, for example: paddingX={{ xs: 0 }} to override the padding for the xs breakpoint.
 *
 * See: https://mui.com/system/getting-started/#object
 *
 */

export interface IResponsiveBoxProps extends BoxProps {
    fullWidth?: boolean;
    className?: string;
    dataTestId?: string;
    style?: React.CSSProperties;
}

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: (props: IResponsiveBoxProps) => ({
                margin: 0,
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: 0,
                    maxWidth: '90rem',
                    flex: '1',
                    margin: props.fullWidth ? 0 : '0 auto',
                },
                [theme.breakpoints.up('lg')]: {
                    maxWidth: '100rem',
                },
                [theme.breakpoints.up('xl')]: {
                    maxWidth: '130rem',
                },
            }),
            wrapper: {
                display: 'flex',
                width: '100%',
            },
        }),
    {
        name: 'ResponsiveBox-',
    },
);

export const ResponsiveBox: React.FunctionComponent<
    IResponsiveBoxProps
> = props => {
    const { children, paddingX } = props;
    const classes = useStyles(props);

    const getPaddingX = () => {
        const defaultPaddingX = { xs: 1, sm: 2, lg: 2, xl: 8 };

        if (paddingX) {
            return {
                ...defaultPaddingX,
                ...paddingX,
            };
        }

        return defaultPaddingX;
    };

    return (
        <div className={classes.wrapper} style={props.style}>
            <Box
                paddingX={getPaddingX()}
                className={
                    props.className
                        ? classNames(classes.root, props.className)
                        : classes.root
                }
                data-testid={props.dataTestId}
            >
                {children}
            </Box>
        </div>
    );
};
