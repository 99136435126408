import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        pageContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: '100vh',
            gap: '10px',
            padding: '15px',
        },
        illustration: {
            alignSelf: 'center',
            minWidth: '18%',
        },
        reloadButton: {
            minWidth: '140px',
            margin: theme.spacing(2),
        },
        text: {
            // this design variant doesn't exist in bb-ui
            fontWeight: 'normal',
            fontSize: '18px',
        },
    });

const options = {
    name: 'ErrorPage-',
};

export const useStyles = makeStyles(styles, options);
