import {
    makeStyles,
    createStyles,
} from '@bb-ui/react-library/dist/components/styles';

const styles = () =>
    createStyles({
        placeHolder: {
            height: '40vh',
        },
    });

const options = {
    name: 'QuickSightVisual-',
};

export const useStyles = makeStyles(styles, options);
