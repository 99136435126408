import React from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { useAuth0Context } from '@bb-ui/auth/dist/auth0/Auth0Context';
import { Collapse } from '@bb-ui/icons/dist/small/Collapse';
import { Expand } from '@bb-ui/icons/dist/small/Expand';
import Box from '@bb-ui/react-library/dist/components/Box/Box';
import { Typography, useTheme } from '@bb-ui/react-library';
import Badge from '@bb-ui/react-library/dist/components/Badge/Badge';
import { useStyles } from './CustomReports.styles';
import { QuickSightConsole } from '../../quicksight/QuickSightConsole';
import { getTenantId } from '../../utilities/utilities';
import { useAppConfigContext } from '../../contexts/AppConfigProvider';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { useApi } from '../../hooks/useApi';
import { useUser } from '../../hooks/useUser';
import { ActionAreaButtons } from '../ActionAreaButtons/ActionAreaButtons';
import { useApplicationTitle } from '../../hooks/useApplicationTitle';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { usePageExpand } from '../../contexts/PageExpandContext';
import { CustomReportsUnauthorized } from './CustomReportsUnauthorized';

export interface IEmbedConsoleAPI {
    id: string;
    name: string;
    url: string;
}

export const CustomReports: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles(theme);

    // This does not get localized
    useApplicationTitle('Custom Reports');

    const getHelpLink = useHelpLinks();
    const { api, uas } = useAppConfigContext();
    const { user } = useAuth0Context();
    const bbUser = useUser();
    const isUserAuthor = bbUser?.isUniversalUser() && bbUser.isAuthor();
    const tenantId = getTenantId(user, uas);
    const userId = user.sub;
    const { expanded, setExpanded } = usePageExpand();

    const url = encodeURI(
        `${
            api!.qsHostname
        }/v1/data/dashboards/tenants/${tenantId}/users/${userId}/${
            isUserAuthor ? 'authoring' : 'reader'
        }`,
    );

    const {
        data: authorData,
        error: authorError,
        loading: authorLoading,
    } = useApi<IEmbedConsoleAPI>({
        url,
        useAuth: true,
        method: 'GET',
    });

    React.useEffect(
        () =>
            // This function will be run when the component is unmounted
            () => {
                setExpanded(false);
            },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    if (authorError?.response) {
        if (
            authorError.response.data?.details.includes(
                'author_qs_seats_quota_exceeded',
            )
        ) {
            return <CustomReportsUnauthorized />;
        }
    }

    const Title: React.FC<{ author: boolean | undefined }> = ({ author }) => (
        <div className={classes.titlesContainer}>
            <div className={classes.headerRow}>
                <Typography
                    variant="h1"
                    component="h1"
                    data-testid="main-header-title"
                >
                    {t('authoring.title')}
                </Typography>
                {!author && (
                    <Badge
                        position="inline"
                        badgeContent={t('authoring.viewer').toUpperCase()}
                        offScreenLabel={t('authoring.viewer')}
                        color="primary"
                        data-testid="viewerBadge"
                        className={classes.badge}
                    />
                )}
            </div>
            <Typography>
                {t(`authoring.subtitle${!author ? 'ForViewer' : ''}`)}
            </Typography>
        </div>
    );

    return (
        <div
            className={
                expanded
                    ? classNames(classes.authorPage, classes.authorPageExpanded)
                    : classes.authorPage
            }
            data-testid="custom-reports-container"
        >
            {!expanded && <Title author={isUserAuthor} />}
            {authorLoading ? (
                <Box className={classes.loading}>
                    <LoadingIndicator
                        label={t('reports.loading.loadingData')}
                        id="loading-custom-reports"
                        data-testid="loading-custom-reports"
                    />
                </Box>
            ) : (
                <Box
                    className={
                        expanded
                            ? classNames(
                                  classes.authoringContainer,
                                  classes.authoringContainerExpanded,
                              )
                            : classes.authoringContainer
                    }
                    data-testid="author-container"
                >
                    {authorData && (
                        <QuickSightConsole
                            embedData={authorData}
                            isError={!!authorError}
                        />
                    )}
                </Box>
            )}
            <ActionAreaButtons
                action={
                    expanded
                        ? {
                              icon: <Collapse />,
                              title: 'actionAreaButtons.actions.collapse.title',
                              action: () => setExpanded(false),
                          }
                        : {
                              icon: <Expand />,
                              title: 'actionAreaButtons.actions.expand.title',
                              action: () => setExpanded(true),
                          }
                }
                helpLink={getHelpLink('customReports')}
            />
        </div>
    );
};
