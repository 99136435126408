import { Formats } from 'intl-messageformat';

export const formats: Formats = {
    number: {
        displayDelta: {
            style: 'percent',
            maximumSignificantDigits: 3,
        },
    },
    date: {
        shortMonthNumDay: {
            month: 'short',
            day: 'numeric',
            timeZone: 'UTC',
        },
        numYearShortMonthNumDay: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            timeZone: 'UTC',
        },
        utc: {
            timeZone: 'UTC',
        },
        dayOfWeek: {
            weekday: 'long',
            timeZone: 'UTC',
        },
    },
    time: {
        shortTime: {
            hour: 'numeric',
            minute: '2-digit',
            timeZone: 'UTC',
        },
    },
};
export default formats;
