import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            list: {
                marginTop: theme.spacing(0),
            },
            listItem: {
                alignItems: 'center',
            },
            box: {
                marginTop: theme.spacing(1),
            },
            roleBox: {
                marginTop: theme.spacing(1),
                color: theme.palette.text.secondary,
            },
            userName: {
                fontWeight: theme.typography.fontWeightSemiBold,
                marginLeft: theme.spacing(2),
            },
            role: {
                color: theme.palette.text.secondary,
                marginLeft: theme.spacing(2),
            },
        }),
    {
        name: 'UserInfo-',
    },
);
