import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';
import { EmbeddedQSearchBarState } from './QuickSightQSearchBar.types';

const styles = (theme: Theme) =>
    createStyles({
        // This is not being hidden on lower screen size, as the test suite runs
        // on an xs screen size
        qSearchBarContainer: {
            borderRadius: theme.spacing(2),
            padding: 0,
            margin: 'auto',
            textAlign: 'center',
        },
        qSearchBarReset: {
            padding: 0,
        },
        qSearchBarMain: (props: {
            qSearchBarState: EmbeddedQSearchBarState;
        }) => ({
            display: props.qSearchBarState === 'loaded' ? 'block' : 'none',
            margin: 'auto',
            textAlign: 'center',
        }),
        qSearchBarLoadingIndicator: (props: {
            qSearchBarState: EmbeddedQSearchBarState;
        }) => ({
            display: props.qSearchBarState === 'notLoaded' ? 'block' : 'none',
            padding: `${theme.spacing(2)}px ${theme.spacing(
                2,
            )}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
            margin: 'auto',
            textAlign: 'center',
            backgroundColor: theme.palette.background.paper,
            '@media (max-width: 776px)': {
                display: 'none',
            },
        }),
        qSearchBarError: (props: {
            qSearchBarState: EmbeddedQSearchBarState;
        }) =>
            props.qSearchBarState === 'failedToLoad'
                ? {
                      flex: 1,
                      display:
                          props.qSearchBarState === 'failedToLoad'
                              ? 'flex'
                              : 'initial', // if failedToLoad is true, set flex so that the error message centers in the Frame
                  }
                : {},
        qSearchBarFrame: {
            borderRaidus: theme.spacing(0),
            border: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            [theme.breakpoints.down('xs')]: {
                borderRadius: 0,
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        screenSizeWarning: {
            display: 'none',
            '@media (max-width: 776px)': {
                display: 'block',
                borderRadius: theme.spacing(2),
                backgroundColor: theme.palette.background.paper,
                padding: theme.spacing(2),
            },
        },
    });

const options = {
    name: 'QuickSightQSearchBar-',
};

export const useStyles = makeStyles(styles, options);
