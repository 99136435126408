// This component is used for displaying a QuickSight visual.
// It creates an embedding context with options for content and frame. The URL for the embed is provided by embedData.
// Options are passed to embedVisual function on created embedding context.
import React from 'react';

import {
    EmbeddingEvents,
    ExperienceFrameMetadata,
    FrameOptions,
    VisualContentOptions,
    VisualExperience,
} from 'amazon-quicksight-embedding-sdk';
import { Skeleton } from '@bb-ui/react-library';
import { AlertError } from '@bb-ui/icons/dist/small';
import { Banner } from '@bb-ui/react-library/dist/components/Banner';
import { useTranslation } from 'react-i18next';
import { useStyles } from './QuickSightVisual.styles';
import { IQuickSightVisualProps } from './QuickSightVisual.types';
import { getQsLocale } from '../utilities/localization';
import { useI18nContext } from '../components/I18nProvider/I18nProvider';
import { getEmbeddingContext } from './QuickSightEmbeddingContext';

export const QuickSightVisual: React.FunctionComponent<
    IQuickSightVisualProps
> = ({ embedData, error }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { userSelectedLang } = useI18nContext();
    const visualFrameRef = React.useRef<VisualExperience>();
    const [loaded, setLoaded] = React.useState(false);
    const [isEmbedError, setIsEmbedError] = React.useState(false);

    const qsLocaleSettings = getQsLocale(userSelectedLang);

    const linkToSupport = (
        <a
            href="https://blackboard.my.salesforce-sites.com"
            target="_blank"
            rel="noreferrer"
        >
            Behind the Blackboard
        </a>
    );
    const bannerText = (
        <>
            {t('reports.error.banner')} {linkToSupport}.
        </>
    );

    const embed = React.useCallback(async () => {
        if (!embedData) return;

        const frameOptions: FrameOptions = {
            url: embedData.url, // fetched from the QuickSight embed service
            container: '#visual-container',
            resizeHeightOnSizeChangedEvent: true,
        };

        const contentOptions: VisualContentOptions = {
            locale: qsLocaleSettings.qsLngCode,
            parameters: [
                ...(embedData.localeParams
                    ? Object.entries(embedData.localeParams)
                    : []
                ).map(([key, value]) => ({
                    Name: key,
                    Values: [value],
                })),
            ],
            fitToIframeWidth: false,
            onMessage: (
                messageEvent: EmbeddingEvents,
                metadata?: ExperienceFrameMetadata,
            ) => {
                switch (messageEvent.eventName) {
                    case 'SIZE_CHANGED': {
                        setLoaded(true);
                        break;
                    }
                    case 'ERROR_OCCURRED': {
                        setIsEmbedError(true);
                        break;
                    }
                }
            },
        };

        const embeddingContext = await getEmbeddingContext();
        await embeddingContext.embedVisual(frameOptions, contentOptions);
    }, [embedData, qsLocaleSettings.qsLngCode]);

    React.useEffect(() => {
        if (!visualFrameRef.current) embed();
    }, [embed, visualFrameRef]);

    return (
        <>
            {error || isEmbedError ? (
                <Banner
                    severity="warning"
                    icon={<AlertError color="primary" />}
                    tabIndex={0} // for AX purpose, so that focus would be on Banner with error message
                    data-testid="error-banner"
                >
                    {bannerText}
                </Banner>
            ) : (
                !loaded && (
                    <Skeleton
                        className={classes.placeHolder}
                        variant="rect"
                        animation="wave"
                        data-testid="loading-indicator"
                    />
                )
            )}
            <div
                style={
                    !loaded || error || isEmbedError
                        ? { zIndex: '-1', position: 'absolute' }
                        : {}
                }
                data-testid="visual-container"
                id="visual-container"
                title="Visual" // For AX purpose, the frame needs to have a title
            />
        </>
    );
};
