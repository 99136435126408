import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        blockContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: theme.spacing(2),
            height: '80vh',
            width: '100%',
            position: 'relative',
            minHeight: '650px',
        },
        imageContainer: {
            width: '80%',
            maxWidth: '680px', // 680px is the width of the image in Figma
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        image: {
            width: '100%',
        },
        textContainer: {
            width: '75%',
            maxWidth: '640px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.spacing(2),
        },
        text: {
            textAlign: 'center',
        },
        button: {
            marginTop: theme.spacing(2),
        },
    });

const options = {
    name: 'DataQAndAUnathorized-',
};

export const useStyles = makeStyles(styles, options);
