import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            content: {
                display: 'flex',
                flexDirection: 'row',
                gap: theme.spacing(2),
            },
            section: {
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
            },
        }),
    {
        name: 'StyledItem-',
    },
);
