import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        dataQAndAContainer: {
            backgroundColor: 'white',
            borderRadius: theme.spacing(0.5),
            margin: theme.spacing(2),
            padding: theme.spacing(2),
        },
        qSearchBarLoadingIndicator: {
            backgroundColor: theme.palette.background.paper,
            padding: `${theme.spacing(2)}px ${theme.spacing(
                2,
            )}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
            margin: 'auto',
            marginBottom: theme.spacing(2),
            textAlign: 'center',
        },
        descriptionContainer: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
        howToContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        svgWrapper: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            padding: `${theme.spacing(2)}px ${theme.spacing(
                4,
            )}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        },
        textWrapper: {
            flex: 25,
        },
        subHeaderSpacer: {
            marginRight: theme.spacing(1),
        },
        helpLink: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
        },
        listSpacing: {
            marginBottom: theme.spacing(0.5),
        },
        poweredByContainer: {
            textAlign: 'right',
            marginTop: theme.spacing(1),
        },
        poweredByText: {
            fontSize: theme.typography.fontSizeSmall,
        },
        banner: {
            marginTop: theme.spacing(5),
        },
    });

const options = {
    name: 'DataQAndA-',
};

export const useStyles = makeStyles(styles, options);
