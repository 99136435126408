import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

import { IDeveloperPageHeroProps } from './DeveloperPageHero.types';

const styles = {
    vertical: (theme: Theme) =>
        createStyles({
            heroContainer: {
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridGap: theme.spacing(4),
                justifyItems: 'center',
                borderRadius: '4px',
                backgroundColor: 'black',
                backgroundImage: 'url(images/Shared/heroBackground.svg)',
                padding: `${theme.spacing(6)}px ${theme.spacing(
                    2,
                )}px 0 ${theme.spacing(2)}px`,
                [theme.breakpoints.up('sm')]: {
                    padding: `${theme.spacing(6)}px ${theme.spacing(
                        4,
                    )}px 0 ${theme.spacing(4)}px`,
                },
            },
            contentContainer: {
                textAlign: 'center',
                maxWidth: '400px',
                [theme.breakpoints.up('sm')]: {
                    maxWidth: '550px',
                },
            },
            titleText: {
                color: theme.palette.common.white,
                marginBottom: theme.spacing(2),
                // This override is needed because the size of the closest bb-ui variant is 16px
                [theme.breakpoints.only('xs')]: {
                    fontSize: theme.typography.fontSizeLarge,
                },
            },
            text: {
                color: theme.palette.common.white,
                textAlign: 'center',
            },
            image: {
                display: 'block',
                width: '80%',
                maxWidth: '250px',
                [theme.breakpoints.up('sm')]: {
                    width: '100%',
                    maxWidth: '350px',
                },
            },
            imageContainer: {
                display: 'flex',
                justifyContent: 'center',
            },
        }),
    horizontal: (theme: Theme) =>
        createStyles({
            heroContainer: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                borderRadius: '4px',
                backgroundColor: 'black',
                backgroundImage: 'url(images/Shared/heroBackground.svg)',
                minHeight: '250px',
                gridGap: theme.spacing(8),
                alignItems: 'center',
                padding: `${theme.spacing(6)}px ${theme.spacing(
                    8,
                )}px 0 ${theme.spacing(8)}px`,
            },
            contentContainer: {
                textAlign: 'left',
                justifySelf: 'start',
                maxWidth: '550px',
                marginBottom: `${theme.spacing(6)}px`,
            },
            titleText: {
                color: theme.palette.common.white,
                marginBottom: theme.spacing(2),
            },
            text: {
                color: theme.palette.common.white,
            },
            image: {
                display: 'block',
                width: '100%',
                maxWidth: '450px',
            },
            imageContainer: {
                justifyContent: 'center',
                display: 'block',
                alignSelf: 'end',
                justifySelf: 'end',
            },
        }),
};

const options = {
    name: 'DeveloperPageHero-',
};

export const useStyles = (props: IDeveloperPageHeroProps) =>
    makeStyles(styles[props.orientation], options)(props);
