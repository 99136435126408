import * as React from 'react';
import { SvgIcon } from '@bb-ui/react-library/dist/components/SvgIcon';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';
import classNames from 'classnames';

interface IAnthologyLogoProps {
    className?: string;
}

const styles = () =>
    createStyles({
        logo: {
            height: '100%',
        },
    });

const options = {
    name: 'AnthologyLogo-',
};

export const useStyles = makeStyles(styles, options);

export const AnthologyLogo: React.FunctionComponent<
    IAnthologyLogoProps
> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const color = theme.palette.text.primary;

    return (
        <SvgIcon
            viewBox="0 0 159 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className={
                props.className
                    ? classNames(classes.logo, props.className)
                    : classes.logo
            }
        >
            <g>
                <path
                    d="M8.04953 5.90424H4.81079V31.6427H8.04953V5.90424Z"
                    fill="#8CC63F"
                />
                <path
                    d="M12.8603 0H9.62155V31.6427H12.8603V0Z"
                    fill="#FFC20E"
                />
                <path
                    d="M17.6711 5.90424H14.4324V31.6427H17.6711V5.90424Z"
                    fill="#F15A24"
                />
                <path
                    d="M22.4819 13.2155H19.2432V31.6427H22.4819V13.2155Z"
                    fill="#662D91"
                />
                <path
                    d="M3.23873 13.2155H0V31.6427H3.23873V13.2155Z"
                    fill="#00B1EB"
                />
                <path
                    d="M74.1816 28.2284C74.1816 30.2099 74.5418 30.9724 75.3861 31.3665V31.639H70.3569V31.3665C71.2012 30.9687 71.5906 30.2099 71.5906 28.2284V12.8656C71.5906 11.4033 70.9283 10.6409 70.266 10.3646V10.0589L74.1816 8.71823V19.0203C76.0193 17.0092 78.0971 15.8195 80.2333 15.8195C83.3956 15.8195 84.2398 17.8306 84.2398 20.453V28.2247C84.2398 30.2063 84.6328 30.9687 85.4735 31.3628V31.6354H80.4443V31.3628C81.2886 30.965 81.6488 30.2063 81.6488 28.2247V21.3039C81.6488 18.895 80.7464 17.8895 78.7594 17.8895C77.0127 17.8895 75.266 18.9245 74.1816 19.6869V28.2284Z"
                    fill={color}
                />
                <path
                    d="M64.7238 31.7643C66.1684 31.7643 67.7369 31.0018 68.6393 29.9669H68.6357L68.4865 29.6943C67.675 30.0589 66.8308 30.1805 66.1684 30.1805C64.5709 30.1805 63.9996 29.326 63.9996 27.2228V17.4401H68.1262L68.3955 16.0368L63.9996 16.3425V11.9521H63.3082C62.5258 14.5746 60.9574 15.9448 59.0614 16.7072V17.4401H61.4122V27.6501C61.4122 30.453 62.4967 31.7643 64.7238 31.7643Z"
                    fill={color}
                />
                <path
                    d="M46.7761 28.2284C46.7761 30.2099 47.1363 30.9724 47.9806 31.3665V31.639H42.9515V31.3665C43.7957 30.9687 44.1851 30.2099 44.1851 28.2284V19.8158C44.1851 18.1989 43.4609 17.6832 42.8605 17.3775V17.105L46.7761 15.7643V19.0276C48.6138 17.0166 50.6917 15.8269 52.8278 15.8269C55.961 15.8269 56.8343 17.8379 56.8343 20.4604V28.232C56.8343 30.2136 57.2565 30.9761 58.068 31.3702V31.6427H53.0388V31.3702C53.8831 30.9724 54.2434 30.2136 54.2434 28.232V21.3112C54.2434 18.9024 53.3409 17.8969 51.354 17.8969C49.6072 17.8969 47.8605 18.9024 46.7761 19.6943V28.2284Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M41.9071 30.453V30.7587C41.3357 31.3996 40.5825 31.8269 39.407 31.8269C38.2316 31.8269 37.3292 31.0645 37.089 29.6317C36.2447 30.8214 34.6508 31.9484 32.5402 31.9484C30.0693 31.9484 28.2643 30.453 28.2643 27.9263C28.2643 25.0018 30.7352 23.1418 34.8583 23.1418C35.5497 23.1418 36.423 23.2044 37.0271 23.2928V20.6409C37.0271 17.9595 36.0919 16.5856 33.9267 16.5856C32.6021 16.5856 31.6377 17.0424 31.0664 17.8343C31.7287 18.0773 32.209 18.6261 32.209 19.3886C32.209 20.3352 31.4849 21.035 30.5533 21.035C29.6217 21.035 28.9266 20.302 28.9266 19.2965C28.9266 17.2228 31.3357 15.8232 34.1668 15.8232C38.2935 15.8232 39.6181 17.9558 39.6181 20.7293V28.4715C39.6181 29.9668 39.9784 30.453 41.4558 30.453H41.9071ZM37.0271 24.1142C36.6669 24.0516 36.2156 23.9926 35.7607 23.9926C32.6894 23.9926 31.0336 25.4549 31.0336 27.6796C31.0336 29.418 32.1472 30.5156 33.7738 30.5156C35.0693 30.5156 36.2738 29.7532 37.0271 28.7477V24.1142Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M87.071 24.1436C87.071 28.6845 89.9014 31.977 94.3873 31.9779C98.997 31.9771 102.097 28.7176 102.097 23.7495C102.097 19.2081 99.4764 15.8232 94.7493 15.8232C90.0223 15.8232 87.071 19.2044 87.071 24.1436ZM99.2363 24.2689C99.2363 28.6261 97.3695 31.1565 94.8112 31.1565H94.8076C91.7654 31.1565 89.8985 27.8674 89.8985 23.477C89.8985 19.3923 91.7071 16.6188 94.3272 16.6188C97.2785 16.6188 99.2363 19.6059 99.2363 24.2689Z"
                    fill={color}
                />
                <path
                    d="M107.61 28.2284C107.61 30.2099 108.003 30.9724 108.843 31.3665V31.639H103.785V31.3665C104.629 30.9687 105.019 30.2099 105.019 28.2284V12.8656C105.019 11.4033 104.356 10.6409 103.694 10.3646V10.0589L107.61 8.71823V28.2284Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M110.532 24.1436C110.532 28.6845 113.362 31.977 117.848 31.9779C122.458 31.9771 125.557 28.7176 125.557 23.7495C125.557 19.2081 122.937 15.8232 118.21 15.8232C113.483 15.8232 110.532 19.2044 110.532 24.1436ZM122.697 24.2689C122.697 28.6261 120.83 31.1565 118.272 31.1565C115.23 31.1565 113.363 27.8674 113.363 23.477C113.363 19.3923 115.168 16.6188 117.788 16.6188C120.739 16.6188 122.697 19.6059 122.697 24.2689Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M136.853 29.3886C140.073 29.3886 141.522 30.302 141.522 32.6483C141.522 35.7569 137.817 39.2634 132.577 39.2634C128.993 39.2634 127.006 37.6464 127.006 35.3923C127.006 33.6538 128.392 32.1289 130.259 31.5212C129.295 31.0939 128.752 30.3315 128.752 29.326C128.752 27.7422 130.168 26.6114 131.041 26.1252C129.025 25.3923 127.697 23.6869 127.697 21.2781C127.697 18.14 130.226 15.8232 133.931 15.8232C135.284 15.8232 136.489 16.1584 137.424 16.7698C137.966 15.2744 138.989 14.302 140.255 14.302C141.278 14.302 141.882 14.9724 141.882 15.7643C141.882 16.5562 141.402 17.1676 140.528 17.1676C139.837 17.1676 139.324 16.7403 139.113 16.1326C138.632 16.2247 138.21 16.5599 137.879 17.0792C138.993 17.9926 139.626 19.3333 139.626 21.0129C139.626 24.2431 137.006 26.5009 133.363 26.5009C132.792 26.5009 132.22 26.4383 131.707 26.3168C131.194 26.8656 130.834 27.4438 130.834 28.0221C130.834 28.9355 131.467 29.3923 132.912 29.3923H136.857L136.853 29.3886ZM132.635 31.919C132.002 31.919 131.431 31.8564 130.95 31.7348C129.928 32.4052 129.233 33.4401 129.233 34.7219C129.233 36.8877 131.009 38.1068 133.57 38.1068C136.824 38.1068 139.411 36.4899 139.411 33.8085C139.411 32.4383 138.538 31.919 136.729 31.919H132.635ZM130.346 21.0055C130.346 23.9006 131.791 25.7311 133.658 25.7311C135.525 25.7311 136.969 23.9337 136.969 21.2486C136.969 18.5635 135.583 16.5562 133.658 16.5562C131.733 16.5562 130.346 18.4162 130.346 21.0055Z"
                    fill={color}
                />
                <path
                    d="M143.338 16.4346C144.484 17.2265 145.117 18.4162 146.02 20.4567L146.016 20.4604L150.925 31.8895C149.269 36.7072 145.314 37.5617 142.424 37.7127V37.9853C143.119 38.7477 144.142 39.2965 145.437 39.2965C148.418 39.2965 150.041 36.0847 152.188 30.6924C154.335 25.2965 155.743 21.4954 155.743 21.4954C157.009 18.2652 157.821 17.046 158.996 16.4346V16.1621H154.36V16.4346C155.532 17.1639 155.503 19.0239 154.63 21.5543L152.13 28.6262L148.847 20.6409C148.036 18.6593 147.734 17.256 148.909 16.4346V16.1621H143.338V16.4346Z"
                    fill={color}
                />
            </g>
        </SvgIcon>
    );
};
