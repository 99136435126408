import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';
import { zIndex } from '../../utilities/sharedStyles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            container: {
                flex: 1,
                display: 'flex',
            },
            skipLinkHidden: {
                opacity: 0,
                zIndex: -1,
                position: 'absolute',
            },
            skipLinkVisible: {
                width: '100%',
                // background color and zIndex are needed to ensure that the
                // skip link is layered on top of the side navigation drawer
                backgroundColor: theme.palette.background.b1,
                zIndex: zIndex.skipLink,
                // focus palette is not included when rendering dynamically in accessible ERD
                // but that is only for screen readers so we can skip the shadow
                boxShadow: theme.palette.focus
                    ? `0px 0px 0px 2px ${theme.palette.focus.main}`
                    : 'none',
                margin: theme.spacing(0.25),
                padding: theme.spacing(0.5, 1, 0.5, 1),
            },
        }),
    {
        name: 'SkipLink-',
    },
);
