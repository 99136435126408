import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            userRolesContainer: {
                position: 'relative',
            },
            comboBoxContainer: {
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(4),
            },
            skeletonContainer: {
                cursor: 'progress',
            },
            skeletonBigBar: {
                maxWidth: '25rem',
            },
            skeletonSmallBar: {
                maxWidth: '12.5rem',
            },
            updating: {
                opacity: '.2',
                pointerEvents: 'none',
                filter: 'blur(4px)',
            },
            box: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'end',
                paddingBottom: theme.spacing(4),
            },
            userRolesComboBox: {
                width: '30rem',
                position: 'relative',
                marginRight: theme.spacing(4),
                [theme.breakpoints.only('sm')]: {
                    minWidth: '20rem',
                    maxWidth: '20rem',
                },
                [theme.breakpoints.only('xs')]: {
                    minWidth: '15rem',
                    maxWidth: '15rem',
                },
            },
            userRolesMenuContainer: {
                padding: theme.spacing(0.5),
            },
            userRolesMenuItem: {
                width: '100%',
            },
            checkBoxContainer: {
                width: '1.5rem',
            },
            userRolesEntry: {
                padding: `0px 0px 0px ${theme.spacing(2)}px`,
            },
            userRolesLabel: {
                fontSize: 'larger',
            },
            userRoleDescription: {
                fontSize: 'smaller',
                padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
            },
            infoTextMargin: {
                marginBottom: theme.spacing(2),
            },
            infoIcon: {
                marginRight: theme.spacing(1),
            },
            assignedRoleRow: {
                padding: `${theme.spacing(1)}px 0px 0px 0px`, // Reset the inherited padding
            },
            assignedRoleRowText: {
                padding: '0px 0px', // Reset the inherited padding
            },
            addRoleButton: {
                marginTop: theme.spacing(2),
            },
            removeRoleButton: {
                cursor: 'pointer',
                background: 'none',
                color: 'inherit',
                border: 'none',
                padding: '0',
                font: 'inherit',
            },
            updatingInfo: {
                width: '100%',
                position: 'absolute',
                marginTop: theme.spacing(8),
                cursor: 'progress',
            },
        }),
    {
        name: 'UserRoles-',
    },
);
