import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            ihNodeContainer: {
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(4),
                fontSize: 'small',
                alignItems: 'center',
                justifyContent: 'center',
            },
            updating: {
                opacity: '.2',
                pointerEvents: 'none',
                filter: 'blur(4px)',
            },
            updatingInfo: {
                position: 'absolute',
                marginTop: theme.spacing(8),
            },
            skeletonContainer: {
                cursor: 'progress',
            },
            errorText: {
                color: theme.palette.error.main,
            },
            tableContainer: {
                width: '100%',
                flexDirection: 'column',
            },
            disabledCheckBox: {
                color: `${theme.palette.success.main} !important`,
                cursor: 'not-allowed',
            },
            pagination: {
                paddingTop: theme.spacing(4),
                display: 'flex',
                justifyContent: 'end',
                [theme.breakpoints.only('xs')]: {
                    justifyContent: 'center',
                },
            },
            noChildrenFoundIcon: {
                marginRight: theme.spacing(2),
                color: theme.palette.indicatorSecondary.main,
                opacity: 0.2,
                cursor: 'not-allowed',
            },
            expandChildrenIcon: {
                marginRight: theme.spacing(2),
                cursor: 'pointer',
            },
            collapseChildrenIcon: {
                marginRight: theme.spacing(2),
                cursor: 'pointer',
            },
            loadingChildrenIcon: {
                marginLeft: theme.spacing(4),
            },
            infoTextMargin: {
                marginBottom: theme.spacing(2),
            },
            pageSizeComboBox: {
                width: '2rem',
                minWidth: '6rem',
            },
            nodeRow: {
                display: 'flex',
                alignItems: 'center',
            },
        }),
    {
        name: 'IHNodes-',
    },
);
