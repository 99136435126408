import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        selectedMenuItem: {
            [theme.breakpoints.up('md')]: {
                display: 'none !important',
            },
        },
        languagePicker: {
            width: '100%',
            justifyContent: 'flex-end',
            '& #language-picker': {
                display: 'block',
                paddingTop: '0.6875rem',
            },
        },
        // Put the label on the side instead of above
        container: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        label: {
            marginBottom: 0,
            marginRight: theme.spacing(1),
        },
        inputRoot: {
            flex: 1,
            maxWidth: '300px',
        },
    });

const options = {
    name: 'LanguagePicker-',
};

export const useStyles = makeStyles(styles, options);
