import React from 'react';
import { useLocation } from 'react-router-dom';

// See https://v5.reactrouter.com/web/guides/scroll-restoration */
const ScrollToTop: React.FunctionComponent = () => {
    const { pathname } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export default ScrollToTop;
