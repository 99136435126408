import React from 'react';
import { Link, PrimaryButton, Typography } from '@bb-ui/react-library';

import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useStyles } from '../ErrorPages.styles';
import { useApplicationTitle } from '../../../hooks/useApplicationTitle';

export const GeneralErrorPage: React.FunctionComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    useApplicationTitle(t('general.defaultPageError'));

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <Grid
            className={classes.pageContainer}
            data-testid="general-error-page-container"
            container
            alignItems="center"
            justifyContent="center"
        >
            <img
                src={require('../../../../app/assets/images/copy/ErrorPages/generalErrorScreenIllustration.svg')}
                alt=""
                className={classes.illustration}
            />
            <Typography
                data-testid="general-error-page-title"
                variant="h1"
                align="center"
            >
                { /* prettier-ignore */ }
                <Trans i18nKey="generalErrorScreen.title">
                    Sorry, we couldn’t load <span lang="en">Anthology Illuminate</span>
                </Trans>
            </Typography>
            <Typography className={classes.text} align="center">
                <Trans i18nKey="generalErrorScreen.text">
                    Try to reload the page.
                </Trans>
            </Typography>
            <PrimaryButton
                className={classes.reloadButton}
                onClick={reloadPage}
                data-testid="reload-button"
            >
                <Trans i18nKey="generalErrorScreen.reloadButton">Reload</Trans>
            </PrimaryButton>
            <Link
                href="https://help.blackboard.com/Anthology_Illuminate/Reporting/Reporting_Basics"
                target="_blank"
                rel="noopener noreferrer"
                variant="subtitle1"
                data-testid="help-link"
            >
                <Typography>
                    {/* prettier-ignore */}
                    <Trans i18nKey="generalErrorScreen.linkToHelp">
                        <span lang="en">Anthology Illuminate</span> is still not working?
                    </Trans>
                </Typography>
            </Link>
        </Grid>
    );
};
