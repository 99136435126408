import React from 'react';
import { OpenNavDrawer } from '../components/MainPage/MainPage.types';

interface AppNavigationProps {
    // Tells you which drawer is currently open
    openNavDrawer: OpenNavDrawer;
    openGlobalNavDrawer: () => void;
    closeGlobalNavDrawer: () => void;
    openSecondaryNavDrawer: () => void;
    closeSecondaryNavDrawer: () => void;

    signInModalIsOpen: boolean;
    openSignInModal: () => void;
    closeSignInModal: () => void;
}

export const AppNavigationContext = React.createContext<
    AppNavigationProps | undefined
>(undefined);

export const AppNavigationProvider: React.FunctionComponent = ({
    children,
}) => {
    const [openNavDrawer, setOpenNavDrawer] =
        React.useState<OpenNavDrawer>('none');

    const openGlobalNavDrawer = () => {
        setOpenNavDrawer('global');
    };
    const closeGlobalNavDrawer = () => {
        if (openNavDrawer === 'global') {
            setOpenNavDrawer('none');
        }
    };
    const openSecondaryNavDrawer = () => {
        setOpenNavDrawer('secondary');
    };
    const closeSecondaryNavDrawer = () => {
        if (openNavDrawer === 'secondary') {
            setOpenNavDrawer('none');
        }
    };

    const [signInModalIsOpen, setSignInModalIsOpen] = React.useState(false);

    const openSignInModal = () => {
        setSignInModalIsOpen(true);
    };

    const closeSignInModal = () => {
        setSignInModalIsOpen(false);
    };

    return (
        <AppNavigationContext.Provider
            value={{
                openNavDrawer,
                openGlobalNavDrawer,
                closeGlobalNavDrawer,
                openSecondaryNavDrawer,
                closeSecondaryNavDrawer,
                signInModalIsOpen,
                openSignInModal,
                closeSignInModal,
            }}
        >
            {children}
        </AppNavigationContext.Provider>
    );
};

export const useAppNavigationContext = () => {
    const context = React.useContext(AppNavigationContext);
    if (context === undefined) {
        throw new Error(
            'useAppNavigationContext must be used within a AppNavigationProvider',
        );
    }
    return context;
};
