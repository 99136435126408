import React from 'react';
import { Link } from '@material-ui/core';
import { t } from 'i18next';
import { Message } from '../../../Message/Message';
import { useHelpLinks } from '../../../../hooks/useHelpLinks';

// This component is used to display an error message when the user is unable to edit a user.
// I'd like to leave the option open to use this to display a more detail error so we don't have to guess
export const EditUserError: React.FC<{ error: string }> = ({ error }) => {
    const getHelpLink = useHelpLinks();

    console.error('Error: ', error);

    return (
        <Message
            data-testid="edit-user-error"
            variant="error"
            message={t('general.contentError')}
            secondaryMessage={t('general.contentErrorRefresh', {
                action: (
                    <Link
                        href={getHelpLink('contactSupport')}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-testid="edit-user-help-link"
                    >
                        {t('general.contactSupportAction')}
                    </Link>
                ),
            })}
        />
    );
};
