import { Skeleton } from '@bb-ui/react-library/dist/components/Skeleton';
import React from 'react';
import { Card } from '@bb-ui/react-library/dist/components/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import { useStyles } from './SkeletonCard.styles';

// WARNING: Please include accessibility component such as Off-screen Announcement when using this component
// More info in docs - https://bb-ui.bb-fnds.com/docs/react-library/components/skeleton
export const SkeletonCard: React.FC = () => {
    const classes = useStyles();

    return (
        <Card className={classes.card} data-testid="skeleton-card">
            <CardContent>
                <Skeleton
                    variant="text"
                    animation="wave"
                    data-testid="skeleton-row1"
                    className={classes.row1}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    data-testid="skeleton-row2"
                    className={classes.row2}
                />
            </CardContent>
        </Card>
    );
};
