import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Link } from '@bb-ui/react-library/dist/components/Link';

import { MainHeader } from '../MainHeader/MainHeader';
import { useStyles } from './PrivacyAndSecurity.styles';
import { ActionAreaButtons } from '../ActionAreaButtons/ActionAreaButtons';
import { ResponsiveBox } from '../ResponsiveBox/ResponsiveBox';
import { Frame } from '../Frame/Frame';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { useApplicationTitle } from '../../hooks/useApplicationTitle';

export const PrivacyAndSecurity: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const getHelpLink = useHelpLinks();
    useApplicationTitle(t('privacyAndSecurity.title'));

    return (
        <>
            <MainHeader title={t('privacyAndSecurity.title')} />
            <ResponsiveBox>
                <Frame>
                    <div
                        className={classes.policies}
                        data-testid="privacy-and-policy-section"
                    >
                        <div
                            className={classes.policySection}
                            data-testid="privacy-section"
                        >
                            <Typography
                                variant="h1"
                                component="h2"
                                gutterBottom
                                data-testid="privacy-section-title"
                            >
                                {t('privacyAndSecurity.privacy')}
                            </Typography>
                            <Typography
                                component="div"
                                data-testid="policy-section-text"
                            >
                                <Trans i18nKey="privacyAndSecurity.policyText">
                                    <p>
                                        Data, analytics, and privacy go hand in
                                        hand. That’s why
                                        <span lang="en">
                                            Anthology{'&nbsp;'}Illuminate
                                        </span>
                                        is not just a part of our platform; it’s
                                        also a major Privacy team initiative. We
                                        have been using our established Privacy
                                        by Design framework to make sure that we
                                        can:
                                    </p>
                                    <ul>
                                        <li>
                                            Provide a data analytics platform
                                            that has strong privacy
                                            characteristics built in from the
                                            start
                                        </li>
                                        <li>
                                            Minimize the impact of
                                            <span lang="en">
                                                Anthology{'&nbsp;'}Illuminate
                                            </span>
                                            on the privacy of your users
                                        </li>
                                        <li>
                                            Comply with privacy obligations that
                                            apply to us
                                        </li>
                                        <li>
                                            Help you comply with your own
                                            privacy obligations
                                        </li>
                                    </ul>
                                    <p>
                                        How do we achieve this? Starting from
                                        the early design phase, we created a
                                        cross-functional working group that
                                        included our Global Privacy Officer and
                                        Chief Information Security Officer to
                                        manage legal, privacy and security
                                        questions and requirements, and help set
                                        the general parameters for the
                                        architecture and use of data.
                                    </p>
                                    <p style={{ paddingTop: '1.3rem' }}>
                                        This working group remains active and
                                        will continuously provide guidance as we
                                        grow
                                        <span lang="en">
                                            Anthology{'&nbsp;'}Illuminate’s
                                        </span>
                                        capabilities.
                                    </p>
                                    <ul>
                                        <li>
                                            As new features are designed, we use
                                            our privacy by design checklist and
                                            our standard data protection impact
                                            assessment as a framework to review
                                            and build out in a privacy-friendly
                                            way.
                                        </li>
                                        <li>
                                            In addition to
                                            <span lang="en">
                                                Anthology{'&nbsp;'}Illuminate
                                            </span>
                                            itself, we also discuss related
                                            aspects and events, including things
                                            like how we can create a safe demo
                                            environment for the
                                            <span lang="en">Hackboard</span>
                                            and
                                            <span lang="en">
                                                21{'&nbsp;'}Days
                                            </span>
                                            events.
                                        </li>
                                    </ul>
                                    <p>
                                        We hope you enjoy some of the features
                                        that have come out of this partnership,
                                        like the Data Dictionary entry flag for
                                        directly-identifiable information. We
                                        are also providing
                                        <Link
                                            href={getHelpLink(
                                                'privacyAndSecurity',
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            more information on how personal
                                            information is typically used in
                                            <span lang="en">
                                                Anthology{'&nbsp;'}Illuminate
                                            </span>
                                            here
                                        </Link>
                                        to help you with updating your privacy
                                        notices or conducting privacy impact
                                        assessments.
                                    </p>
                                    <p
                                        style={{
                                            paddingTop: '1.3rem',
                                            paddingBottom: '1.3rem',
                                        }}
                                    >
                                        You can find more information on our
                                        data privacy approach in our
                                        <Link
                                            href={getHelpLink('privacyCenter')}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            lang="en"
                                        >
                                            Privacy Center
                                        </Link>
                                        and in our
                                        <Link
                                            href={getHelpLink(
                                                'anthologyPrivacyStatement',
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Statement
                                        </Link>
                                        .
                                    </p>
                                    <p>
                                        We are keen to hear your feedback on our
                                        approach. Please email us at
                                        <Link
                                            href="mailto:privacy@anthology.com"
                                            lang="en"
                                        >
                                            privacy@anthology.com
                                        </Link>
                                        to ask us questions or suggest
                                        improvements.
                                    </p>
                                </Trans>
                            </Typography>
                        </div>
                        <div
                            className={classes.policySection}
                            data-testid="security-section"
                        >
                            <Typography
                                variant="h1"
                                component="h2"
                                gutterBottom
                                data-testid="security-section-title"
                            >
                                {t('privacyAndSecurity.security')}
                            </Typography>
                            <Typography
                                component="div"
                                data-testid="security-section-text"
                            >
                                <Trans i18nKey="privacyAndSecurity.securityText">
                                    <p>
                                        <span lang="en">Anthology's</span>
                                        security program leverages a proactive
                                        approach to security for our products.
                                        Our team performs internal security
                                        testing at both the code-level (static
                                        analysis) and application-level (dynamic
                                        analysis) to ensure it meets both
                                        <span lang="en">Anthology</span> and
                                        customer expectations. Furthermore, to
                                        regularly get fresh eyes on our
                                        applications,
                                        <span lang="en">Anthology</span>
                                        obtains security penetration testing
                                        from third party security vendors. Any
                                        identified issues are targeted for
                                        resolution.
                                    </p>
                                    <p style={{ paddingTop: '1.3rem' }}>
                                        <span lang="en">Anthology's</span>
                                        security program continues to raise the
                                        bar on security in
                                        <span lang="en">Anthology</span>
                                        products.
                                    </p>
                                </Trans>
                            </Typography>
                        </div>
                        <div
                            className={classes.policySection}
                            data-testid="build-with-security-in-mind-section"
                        >
                            <Typography
                                variant="h1"
                                component="h2"
                                gutterBottom
                                data-testid="build-with-security-in-mind-section-title"
                            >
                                {t(
                                    'privacyAndSecurity.builtWithSecurityInMind',
                                )}
                            </Typography>
                            <Typography
                                component="div"
                                data-testid="build-with-security-in-mind-section-text"
                            >
                                <Trans i18nKey="privacyAndSecurity.builtWithSecurityInMindText">
                                    <p>
                                        <span lang="en">Anthology</span> is
                                        committed to providing our clients
                                        secure applications.
                                        <span lang="en">Anthology</span>
                                        develops our products according to a set
                                        of security engineering guidelines
                                        derived from many organizations such as
                                        the
                                        <span lang="en">
                                            Open Web Application Security
                                            Project (OWASP)
                                        </span>
                                        . <span lang="en">Anthology</span>
                                        incorporates these security practices in
                                        all phases of the software development
                                        lifecycle
                                        <span lang="en">(SDLC)</span>.
                                    </p>
                                    <p style={{ paddingTop: '1.3rem' }}>
                                        <span lang="en">Anthology</span>
                                        utilizes several methods to protect our
                                        applications including “top-down”
                                        security assessments through Threat
                                        Modeling and analysis as well as
                                        “bottom-up” code-level threat detection
                                        through static analysis, dynamic
                                        analysis, and manual penetration
                                        testing.
                                        <span lang="en">Anthology</span>
                                        follows best practice guidance from many
                                        organizations to help strengthen the
                                        security of our products and programs. A
                                        few organizations are noted here:
                                    </p>
                                    <ul>
                                        <li>
                                            National Institute of Standards and
                                            Technology
                                            <span lang="en">(NIST)</span>
                                        </li>
                                        <li>
                                            European Network and Information
                                            Security Agency
                                            <span lang="en">(ENISA)</span>
                                        </li>
                                        <li lang="en">SANS Institute </li>
                                        <li lang="en">
                                            Open Web Application Security
                                            Project (OWASP)
                                        </li>
                                        <li lang="en">
                                            Cloud Security Alliance (CSA)
                                        </li>
                                    </ul>
                                </Trans>
                            </Typography>
                        </div>
                    </div>
                </Frame>
            </ResponsiveBox>
            <ActionAreaButtons helpLink={getHelpLink('privacyAndSecurity')} />
        </>
    );
};
