import * as React from 'react';
import { SvgIcon } from '@bb-ui/react-library/dist/components/SvgIcon';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = () =>
    createStyles({
        logo: {
            width: '146px',
            height: '50px',
        },
    });

const options = {
    name: 'AnthologyIlluminateLogoTwoLines-',
};

export const useStyles = makeStyles(styles, options);

export const AnthologyIlluminateLogoTwoLines: React.FunctionComponent = () => {
    const classes = useStyles();
    const theme = useTheme();
    const color = theme.palette.text.primary;

    return (
        <SvgIcon
            viewBox="0 0 146 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="presentation"
            className={classes.logo}
        >
            <g>
                <path
                    d="M14.6562 1.47498H9.39646V42.5606H14.6562V1.47498Z"
                    fill="#F7934E"
                />
                <path
                    d="M5.25971 13.1474H0V42.5606H5.25971V13.1474Z"
                    fill="#FDBD3A"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.0377 13.1022L37.7758 17.1163L37.7716 17.1204H39.6212L32.7101 1.47906H31.1289L24.2219 17.1163H26.0962L27.8343 13.1022H36.0377ZM35.3895 11.5944H28.4784L31.9339 3.58676L35.3895 11.5944Z"
                    fill={color}
                />
                <path
                    d="M50.3925 17.1163V10.3289H50.3966C50.3966 7.47345 49.0961 6.04778 46.4952 6.04778C45.6777 6.04778 44.9511 6.22034 44.3112 6.56957C43.6713 6.9188 43.1717 7.42415 42.8167 8.08974V6.31484H41.0332V17.1121H42.8373V10.9246C42.8373 9.88929 43.1346 9.07169 43.7291 8.46362C44.3236 7.85966 45.1204 7.55562 46.1153 7.55562C46.9741 7.55562 47.6057 7.7816 47.9979 8.24175C48.3901 8.70191 48.5883 9.43324 48.5883 10.4398V17.1163H50.3925Z"
                    fill={color}
                />
                <path
                    d="M58.9962 15.7645L58.9957 15.7728C58.6656 15.818 58.3396 15.8385 58.0137 15.8385C56.4407 15.8385 55.6522 14.988 55.6522 13.2871V7.74051H58.7279V6.31895H55.6522V3.03621H53.848V6.31895H51.7301V7.74051H53.848V13.4185C53.848 14.6306 54.1783 15.5755 54.8389 16.2576C55.4994 16.9355 56.482 17.2765 57.7907 17.2765C58.1912 17.2765 58.5627 17.2436 58.9054 17.1861L58.9957 15.7728L58.9962 15.7728V15.7645Z"
                    fill={color}
                />
                <path
                    d="M70.5189 17.1163V10.3289C70.5189 7.47346 69.2184 6.04779 66.6175 6.04779C65.8 6.04779 65.0775 6.21624 64.4459 6.55725C63.8142 6.89826 63.3188 7.3954 62.9637 8.04455V1.47906H61.1596V17.1122H62.9637V10.9247C62.9637 9.8893 63.261 9.07169 63.8555 8.46362C64.45 7.85967 65.2468 7.55563 66.2418 7.55563C67.1005 7.55563 67.7321 7.7816 68.1244 8.24176C68.5166 8.70192 68.7147 9.43325 68.7147 10.4398V17.1163H70.5189Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M74.6763 16.5862C73.896 16.1261 73.2932 15.4728 72.8721 14.6224C72.4469 13.7719 72.2363 12.7858 72.2363 11.6601C72.2363 10.5343 72.4469 9.54828 72.8721 8.69781C73.2974 7.84733 73.896 7.19407 74.6763 6.73391C75.4566 6.27375 76.3607 6.04778 77.3846 6.04778C78.4084 6.04778 79.3167 6.27786 80.1053 6.73391C80.8938 7.19407 81.4966 7.84733 81.9218 8.69781C82.347 9.54828 82.5576 10.5343 82.5576 11.6601C82.5576 12.7858 82.347 13.7719 81.9218 14.6224C81.4966 15.4728 80.8938 16.1261 80.1053 16.5862C79.3167 17.0464 78.4126 17.2724 77.3846 17.2724C76.3566 17.2724 75.4566 17.0423 74.6763 16.5862ZM79.8493 14.7333C80.4232 14.0307 80.708 13.0077 80.708 11.6601C80.708 10.3125 80.419 9.32642 79.8369 8.61153C79.2589 7.89253 78.4415 7.53508 77.3846 7.53508C76.3277 7.53508 75.5102 7.89253 74.9322 8.61153C74.3543 9.33052 74.0611 10.3453 74.0611 11.6601C74.0611 12.9748 74.346 14.0102 74.9199 14.721C75.4937 15.4317 76.3153 15.7851 77.3846 15.7851C78.4539 15.7851 79.2754 15.4359 79.8493 14.7333Z"
                    fill={color}
                />
                <path
                    d="M84.4319 1.47906V17.1204H86.2361V1.47906H84.4319Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M90.571 16.5862C89.7907 16.1261 89.1879 15.4728 88.7668 14.6224C88.3416 13.7719 88.1311 12.7858 88.1311 11.6601C88.1311 10.5343 88.3416 9.54828 88.7668 8.69781C89.1921 7.84733 89.7907 7.19407 90.571 6.73391C91.3513 6.27375 92.2554 6.04778 93.2793 6.04778C94.3032 6.04778 95.2114 6.27786 96 6.73391C96.7885 7.19407 97.3913 7.84733 97.8165 8.69781C98.2417 9.54828 98.4523 10.5343 98.4523 11.6601C98.4523 12.7858 98.2417 13.7719 97.8165 14.6224C97.3913 15.4728 96.7885 16.1261 96 16.5862C95.2114 17.0464 94.3073 17.2724 93.2793 17.2724C92.2513 17.2724 91.3513 17.0423 90.571 16.5862ZM95.744 14.7333C96.3179 14.0307 96.6027 13.0077 96.6027 11.6601C96.6027 10.3125 96.3137 9.32642 95.7316 8.61153C95.1536 7.89253 94.3362 7.53508 93.2793 7.53508C92.2224 7.53508 91.4049 7.89253 90.827 8.61153C90.249 9.33052 89.9559 10.3453 89.9559 11.6601C89.9559 12.9748 90.2407 14.0102 90.8146 14.721C91.3884 15.4317 92.21 15.7851 93.2793 15.7851C94.3486 15.7851 95.1701 15.4359 95.744 14.7333Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M110.38 16.3849V6.31483H108.575V8.18012C108.249 7.50221 107.766 6.97631 107.126 6.60654C106.49 6.23677 105.739 6.05188 104.876 6.05188C103.923 6.05188 103.08 6.27375 102.345 6.71747C101.611 7.1612 101.037 7.78981 100.628 8.6033C100.219 9.4168 100.017 10.3577 100.017 11.4218C100.017 12.4859 100.219 13.4268 100.628 14.2403C101.037 15.0538 101.611 15.6824 102.345 16.1261C103.08 16.5698 103.927 16.7917 104.876 16.7917C105.751 16.7917 106.511 16.6068 107.159 16.237C107.803 15.8672 108.282 15.3331 108.596 14.6388V16.5452C108.596 17.6093 108.332 18.4187 107.795 18.9733C107.263 19.5239 106.474 19.8033 105.434 19.8033C104.645 19.8033 103.935 19.6964 103.295 19.491C102.655 19.2856 101.97 18.9446 101.243 18.4721L100.64 19.8033C101.933 20.777 103.53 21.2659 105.434 21.2659C107.068 21.2659 108.299 20.855 109.133 20.0333C109.963 19.2116 110.38 17.9955 110.38 16.3849ZM108.575 11.4177C108.575 12.6297 108.278 13.5829 107.684 14.269C107.089 14.9551 106.288 15.3003 105.231 15.3003C104.174 15.3003 103.349 14.9551 102.746 14.269C102.143 13.5788 101.842 12.6133 101.842 11.4177C101.842 10.2221 102.143 9.27711 102.746 8.59098C103.349 7.90485 104.174 7.55973 105.231 7.55973C106.288 7.55973 107.11 7.90485 107.696 8.59098C108.282 9.27711 108.575 10.2056 108.575 11.4177Z"
                    fill={color}
                />
                <path
                    d="M121.101 6.33539H122.905L116.486 21.1098H114.636L116.465 16.9848L111.874 6.33539H113.79L117.423 15.1647L121.101 6.33539Z"
                    fill={color}
                />
                <path
                    d="M89.3531 23.5749H85.654C85.6127 23.5749 85.5755 23.6078 85.5755 23.653V26.8618C85.5755 26.9028 85.6085 26.9398 85.654 26.9398H89.3531C89.3944 26.9398 89.4315 26.907 89.4315 26.8618V23.653C89.4315 23.6119 89.3985 23.5749 89.3531 23.5749Z"
                    fill={color}
                />
                <path
                    d="M27.9417 23.8666H24.5563C24.513 23.8666 24.4779 23.9016 24.4779 23.9447V42.4866C24.4779 42.5298 24.513 42.5647 24.5563 42.5647H27.9417C27.985 42.5647 28.0201 42.5298 28.0201 42.4866V23.9447C28.0201 23.9016 27.985 23.8666 27.9417 23.8666Z"
                    fill={color}
                />
                <path
                    d="M55.6934 29.5447H58.9962V29.5488C59.0375 29.5488 59.0747 29.5816 59.0747 29.6268V42.4866C59.0747 42.5277 59.0417 42.5647 58.9962 42.5647H55.7719C55.7306 42.5647 55.6934 42.5318 55.6934 42.4866V40.8432C54.8182 42.158 53.5095 42.8276 51.8085 42.8276C48.6007 42.8276 46.9741 41.0158 46.9741 37.4372V29.6268C46.9741 29.5857 47.0071 29.5488 47.0525 29.5488H50.3553C50.3966 29.5488 50.4337 29.5816 50.4337 29.6268V37.4906C50.4337 38.3781 50.6154 39.0437 50.9704 39.4627C51.3214 39.8777 51.8994 40.0913 52.6796 40.0913C53.5342 40.0913 54.2443 39.7914 54.7893 39.208C55.3384 38.6205 55.615 37.8275 55.615 36.8538V29.6227C55.615 29.5816 55.648 29.5447 55.6934 29.5447Z"
                    fill={color}
                />
                <path
                    d="M75.3368 29.9638C76.1047 29.5118 76.9882 29.2817 77.9667 29.2817C80.9103 29.2817 82.4048 31.0895 82.4048 34.6475V42.4826C82.4048 42.5236 82.3718 42.5606 82.3264 42.5606H79.0236C78.9823 42.5606 78.9451 42.5278 78.9451 42.4826V34.779C78.9451 33.8176 78.7841 33.1068 78.4704 32.6672C78.1649 32.2358 77.6282 32.018 76.885 32.018C76.0511 32.018 75.3864 32.3139 74.9116 32.8973C74.4327 33.4848 74.1891 34.3106 74.1891 35.3583V42.4826C74.1891 42.5236 74.1561 42.5606 74.1107 42.5606H70.8079C70.7666 42.5606 70.7294 42.5278 70.7294 42.4826V34.779C70.7294 33.8176 70.5684 33.1068 70.2547 32.6672C69.945 32.2358 69.4207 32.018 68.6941 32.018C67.8601 32.018 67.1954 32.3139 66.7207 32.8973C66.2418 33.4848 65.9982 34.3106 65.9982 35.3583V42.4826C65.9982 42.5236 65.9651 42.5606 65.9197 42.5606H62.6169C62.5756 42.5606 62.5385 42.5278 62.5385 42.4826V29.6228C62.5385 29.5817 62.5715 29.5447 62.6169 29.5447H65.8413C65.8826 29.5447 65.9197 29.5776 65.9197 29.6228V31.3073C66.2954 30.7156 66.7991 30.239 67.4101 29.8857C68.1078 29.4831 68.9212 29.2817 69.8294 29.2817C71.7327 29.2817 73.0166 30.0911 73.6483 31.6935C74.0529 30.9704 74.6185 30.3869 75.3368 29.9638Z"
                    fill={color}
                />
                <path
                    d="M85.8645 29.5447H89.1714C89.2148 29.5447 89.2499 29.5796 89.2499 29.6227V42.4825C89.2499 42.5256 89.2148 42.5606 89.1714 42.5606H85.8645C85.8212 42.5606 85.7861 42.5256 85.7861 42.4825V29.6227C85.7861 29.5796 85.8212 29.5447 85.8645 29.5447Z"
                    fill={color}
                />
                <path
                    d="M100.269 29.2817C99.3275 29.2817 98.4647 29.479 97.7092 29.8693C97.0321 30.2185 96.4706 30.7156 96.0413 31.3484V29.6228C96.0413 29.5776 96.0041 29.5447 95.9628 29.5447H92.7385C92.693 29.5447 92.66 29.5817 92.66 29.6228V42.4826C92.66 42.5278 92.6972 42.5606 92.7385 42.5606H96.0413C96.0867 42.5606 96.1197 42.5236 96.1197 42.4826V35.2515C96.1197 34.2778 96.4004 33.4848 96.9578 32.9014C97.511 32.318 98.2706 32.018 99.2119 32.018C99.9757 32.018 100.545 32.2399 100.909 32.6713C101.272 33.1109 101.458 33.8258 101.458 34.8037V42.4826C101.458 42.5278 101.495 42.5606 101.536 42.5606H104.839C104.885 42.5606 104.918 42.5236 104.918 42.4826V34.6475C104.918 31.0895 103.353 29.2817 100.269 29.2817Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M113.451 29.2817C112.832 29.2817 112.163 29.3475 111.469 29.479C110.772 29.6104 110.103 29.7953 109.475 30.0336C108.848 30.2719 108.295 30.5513 107.832 30.8718C107.799 30.8923 107.791 30.9334 107.803 30.9663L108.753 33.2013C108.761 33.2219 108.778 33.2383 108.802 33.2465C108.823 33.2547 108.848 33.2506 108.869 33.2383C109.632 32.7905 110.4 32.4494 111.147 32.2358C111.895 32.018 112.634 31.9112 113.344 31.9112C114.231 31.9112 114.871 32.0961 115.243 32.4659C115.614 32.8356 115.8 33.4519 115.8 34.3024V34.7749H114.954C113.088 34.7749 111.581 34.8981 110.47 35.1447C109.347 35.3912 108.526 35.802 108.022 36.3649C107.518 36.9278 107.263 37.7043 107.263 38.6657C107.263 39.4504 107.477 40.1653 107.898 40.7857C108.319 41.4102 108.906 41.9074 109.649 42.273C110.388 42.6346 111.238 42.8195 112.18 42.8195C113.121 42.8195 113.885 42.6058 114.549 42.1909C115.111 41.8375 115.548 41.365 115.854 40.7857V42.4826C115.854 42.5278 115.891 42.5606 115.932 42.5606H119.025C119.07 42.5606 119.103 42.5236 119.103 42.4826V34.7009C119.103 32.8644 118.632 31.488 117.703 30.6088C116.775 29.7296 115.346 29.2858 113.447 29.2858L113.451 29.2817ZM115.804 36.8292V37.3304C115.804 38.2179 115.524 38.9574 114.966 39.5367C114.413 40.1119 113.707 40.4077 112.869 40.4077C112.171 40.4077 111.618 40.227 111.23 39.8777C110.842 39.5285 110.648 39.0683 110.648 38.5178C110.648 37.881 110.945 37.4537 111.56 37.2113C112.192 36.9606 113.34 36.8374 114.979 36.8374H115.796L115.804 36.8292Z"
                    fill={color}
                />
                <path
                    d="M130.589 39.6805C130.589 39.6805 130.547 39.6641 130.527 39.6723C130.246 39.7421 129.973 39.8079 129.709 39.8695C129.453 39.9311 129.201 39.9599 128.962 39.9599C128.161 39.9599 127.579 39.7586 127.224 39.3559C126.869 38.9533 126.687 38.3329 126.687 37.5153V32.1783H130.518C130.564 32.1783 130.597 32.1413 130.597 32.1002V29.6269C130.597 29.5817 130.56 29.5488 130.518 29.5488H126.687V25.7895C126.687 25.7443 126.65 25.7114 126.609 25.7114H123.306C123.26 25.7114 123.227 25.7484 123.227 25.7895V29.5488H120.825C120.779 29.5488 120.746 29.5858 120.746 29.6269V32.1002C120.746 32.1454 120.783 32.1783 120.825 32.1783H123.227V37.7002C123.227 41.1021 124.978 42.8277 128.433 42.8277C128.896 42.8277 129.375 42.7743 129.862 42.6674C130.353 42.5606 130.766 42.4168 131.084 42.2402C131.113 42.2237 131.129 42.1908 131.125 42.1539L130.622 39.7339C130.617 39.7134 130.605 39.6928 130.584 39.6846L130.589 39.6805Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M143.428 31.0443C142.351 29.8775 140.869 29.2817 139.023 29.2817C137.764 29.2817 136.629 29.5734 135.642 30.1486C134.655 30.7238 133.875 31.525 133.313 32.5316C132.756 33.5382 132.471 34.7256 132.471 36.065C132.471 37.4044 132.764 38.6493 133.342 39.6518C133.92 40.6584 134.754 41.4513 135.819 41.9977C136.88 42.5442 138.164 42.8236 139.634 42.8236C140.571 42.8236 141.521 42.6716 142.458 42.3716C143.395 42.0717 144.188 41.6608 144.811 41.1473C144.836 41.1267 144.848 41.0898 144.832 41.0569L143.907 38.8465C143.899 38.8218 143.878 38.8054 143.853 38.8013C143.829 38.7972 143.804 38.8013 143.783 38.8177C143.197 39.282 142.536 39.6271 141.826 39.8449C141.116 40.0585 140.394 40.1694 139.684 40.1694C137.343 40.1694 136.075 39.093 135.811 36.8826H144.968C145.014 36.8826 145.047 36.8456 145.047 36.8045V35.8308C145.047 33.8217 144.502 32.2111 143.428 31.0443ZM135.811 34.8324C135.943 33.8792 136.294 33.1109 136.86 32.548C137.425 31.9852 138.214 31.677 139.155 31.677C140.096 31.677 140.774 31.9564 141.277 32.507C141.769 33.0452 142.058 33.8258 142.136 34.8324H135.811Z"
                    fill={color}
                />
                <path
                    d="M36.69 39.6353C35.3111 39.6353 35.0468 38.8588 35.0468 37.4167V23.9447C35.0468 23.8995 35.0097 23.8666 34.9684 23.8666H31.6656C31.6202 23.8666 31.5872 23.9036 31.5872 23.9447V37.8275C31.5872 39.4545 31.9628 40.7035 32.7018 41.5458C33.445 42.3921 34.6051 42.8235 36.1574 42.8235H37.5859C37.6106 42.8235 37.6313 42.8112 37.6478 42.7948C37.6643 42.7783 37.6684 42.7537 37.6643 42.729L37.0368 39.6969C37.0285 39.6599 36.9955 39.6353 36.9583 39.6353H36.69Z"
                    fill={color}
                />
                <path
                    d="M44.6538 39.6353H44.3855C43.0066 39.6353 42.7423 38.8588 42.7423 37.4167V23.9447C42.7423 23.8995 42.7052 23.8666 42.6639 23.8666H39.3611C39.3157 23.8666 39.2827 23.9036 39.2827 23.9447V37.8275C39.2827 39.4545 39.6584 40.7035 40.3974 41.5458C41.1405 42.3921 42.3047 42.8235 43.8529 42.8235H45.2814C45.3061 42.8235 45.3268 42.8112 45.3433 42.7948C45.3598 42.7783 45.3639 42.7537 45.3598 42.729L44.7323 39.6969C44.724 39.6599 44.691 39.6353 44.6538 39.6353Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="145.975" height="50.0144" fill={color} />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
