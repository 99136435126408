import React from 'react';
import { FeatureFlagContextProvider } from '@bb-config-ui/feature-flag-provider';
import { useAuth0Context } from '@bb-ui/auth/dist/auth0/Auth0Context';
import { useAppConfigContext } from './AppConfigProvider';
import { UniversalAuthUser, useUser } from '../hooks/useUser';

export const getFlagPropFromStringArray = (
    strArray: string[] | undefined,
): { flagKey: string; scope: string }[] => {
    const flagProps: { flagKey: string; scope: string }[] = [];

    (strArray ?? []).forEach(f => {
        flagProps.push({ flagKey: f, scope: 'Tenant' });
    });

    return flagProps;
};

/**
 * This component is a wrapper for the FeatureFlagContextProvider component provided by the @bb-config-ui module.
 * It uses a list of feature flags that are passed to the FeatureFlagContextProvider component, which will
 * allow children to access the flag information returned from the Foundations Feature Flag service.
 * This component takes in a set of children nodes that will be render regardless of whether the service
 * @param {React.ReactNode} children - The children that will be rendered in the component.
 * @returns {React.FunctionComponent} The rendered component that contains the children.
 */
export const FeatureFlagServiceContext: React.FunctionComponent = ({
    children,
}) => {
    const { managedFeatureFlags } = useAppConfigContext();
    const { accessToken } = useAuth0Context();
    const user = useUser();
    const tenantId = user?.isUniversalUser()
        ? (user as UniversalAuthUser).tenantId
        : null;

    const flagProps = getFlagPropFromStringArray(managedFeatureFlags);

    return user?.isUniversalUser() && tenantId && accessToken ? (
        <FeatureFlagContextProvider
            apiBaseUrl={`${window.location.origin}`}
            accessToken={accessToken ?? ''}
            tenantId={tenantId}
            flags={flagProps}
        >
            {children}
        </FeatureFlagContextProvider>
    ) : (
        <> {children}</>
    );
};
