import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import classnames from 'classnames';

import {
    useTheme,
    useMediaQuery,
} from '@bb-ui/react-library/dist/components/styles';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { Card } from '@bb-ui/react-library/dist/components/Card';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { ArrowRight } from '@bb-ui/icons/dist/small/ArrowRight';
import { IDeveloperPageCardProps } from './DeveloperPageCard.types';
import { useStyles } from './DeveloperPageCard.styles';

export const DeveloperPageCard: React.FunctionComponent<
    IDeveloperPageCardProps
> = props => {
    const { id, title, link, linkReplacement, image, className, children } =
        props;

    const classes = useStyles(props);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));

    const imageElement = image ? (
        <div className={classes.imageContainer}>
            <img
                src={image?.url}
                alt=""
                className={classes.image}
                loading="lazy"
            />
        </div>
    ) : null;

    const getActionItem = () => {
        // linkReplacement takes priority. If it's not defined and `link` prop is,
        // then show standard link
        if (linkReplacement) {
            return linkReplacement;
        }

        if (link) {
            if (link.isExternal) {
                // External links use bb-ui link and open in a new tab
                return (
                    <Link
                        href={link.to}
                        className={classes.link}
                        data-testid={`developer-page-${id}-card-link`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Typography
                            component="span"
                            variant="subtitle2"
                            className={classes.linkText}
                        >
                            {link.text}
                        </Typography>
                        <ArrowRight role="presentation" />
                    </Link>
                );
            }
            // Internal links use react-router links
            return (
                <RouteLink
                    to={link.to}
                    className={classes.link}
                    data-testid={`developer-page-${id}-card-link`}
                >
                    <Typography
                        component="span"
                        variant="subtitle2"
                        className={classes.linkText}
                    >
                        {link.text}
                    </Typography>
                    <ArrowRight role="presentation" />
                </RouteLink>
            );
        }
    };

    return (
        <Card className={classnames(classes.card, className)} elevation={2}>
            {(!smUp || image?.position === 'left') && imageElement}
            <div className={classes.contentSection}>
                <Typography
                    component="h2"
                    variant="h2"
                    className={classes.title}
                    data-testid={`developer-page-${id}-card-title`}
                >
                    {title}
                </Typography>
                <div className={classes.childrenSection}>{children}</div>
                {getActionItem()}
            </div>
            {smUp && image?.position === 'right' && imageElement}
        </Card>
    );
};
