import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            description: {
                marginBottom: '2%',
            },
            banner: {
                marginBottom: '2%',
            },
            cardsContainer: {
                display: 'flex',
                flexWrap: 'wrap',
                gridGap: '1.5%',
                marginBottom: '2%',
                '& > *': {
                    flex: '1 1 auto',
                },
            },
            card: {
                [theme.breakpoints.only('xs')]: {
                    width: '100%',
                    marginBottom: '2%',
                },
            },
            searchContainer: {
                display: 'flex',
                flexWrap: 'wrap',
                gridGap: '1.5%',
                marginBottom: '2%',
            },
            searchBox: {
                [theme.breakpoints.only('xs')]: {
                    width: '100%',
                    marginBottom: '5%',
                },
                width: '26%',
            },
            filterByTextField: {
                [theme.breakpoints.only('xs')]: {
                    width: '100%',
                    marginBottom: '5%',
                },
                width: '26%',
            },
            checkbox: {
                [theme.breakpoints.only('xs')]: {
                    width: '100%',
                },
                width: '14%',
            },
            tableContainer: {
                overflowX: 'auto',
            },
            noDataCell: {
                textAlign: 'center',
                fontStyle: 'italic',
            },
            pagination: {
                paddingTop: '2%',
                display: 'flex',
                justifyContent: 'center',
            },
            skeletonFiltersContainer: {
                display: 'flex',
                flexWrap: 'wrap',
                gap: theme.spacing(2),
            },
            skeletonFilter: {
                height: theme.spacing(8),
                width: `calc((100% - ${theme.spacing(2)}px * 2) / 3)`,
            },
            errorMessage: {
                marginTop: theme.spacing(8),
            },
            button: {
                marginRight: theme.spacing(2),
            },
            actionsContainer: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                height: '100%',
            },
            editButton: {
                width: '24px',
                height: '24px',
                marginRight: '10px',
            },
            editIcon: {
                color: theme.palette.action.active,
            },
        }),
    {
        name: 'LicenseManagement-',
    },
);
