import * as React from 'react';
import { SvgIcon } from '@bb-ui/react-library/dist/components/SvgIcon';
import {
    createStyles,
    makeStyles,
    useTheme,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        logo: {
            width: '80%',
            [theme.breakpoints.up('xs')]: {
                width: '100%',
                paddingLeft: theme.spacing(1),
            },
        },
    });

const options = {
    name: 'AnthologyIlluminateLogo-',
};

const useStyles = makeStyles(styles, options);

export const AnthologyIlluminateLogo: React.FunctionComponent = () => {
    const classes = useStyles();
    const theme = useTheme();
    const color = theme.palette.text.primary;

    return (
        <SvgIcon
            viewBox="0 0 203 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="presentation"
            className={classes.logo}
            data-testid="anthology-illuminate-logo"
        >
            <path
                d="M7.17084 0.499878H4.59741V20.5881H7.17084V0.499878Z"
                fill="#F7934E"
            />
            <path
                d="M2.57343 6.20898H0V20.5901H2.57343V6.20898Z"
                fill="#FDBD3A"
            />
            <path
                d="M158.531 5.86743H155.66C155.626 5.86743 155.598 5.89556 155.598 5.92971V8.41663C155.598 8.45078 155.626 8.4789 155.66 8.4789H158.531C158.565 8.4789 158.593 8.45078 158.593 8.41663V5.92971C158.593 5.89556 158.565 5.86743 158.531 5.86743Z"
                fill={color}
            />
            <path
                d="M108.258 6.09244H110.88C110.914 6.09244 110.942 6.12032 110.942 6.15472V20.5258C110.942 20.5602 110.914 20.5881 110.88 20.5881H108.258C108.223 20.5881 108.195 20.5602 108.195 20.5258V6.15472C108.195 6.12032 108.223 6.09244 108.258 6.09244Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.4317 16.8979L24.0315 20.5881H25.7302L19.3755 6.21294H17.9191L11.5643 20.5881H13.2873L14.8871 16.8979H22.4317ZM21.8378 15.5118H15.483L18.6604 8.15146L21.8378 15.5118Z"
                fill={color}
            />
            <path
                d="M35.6361 20.5881V14.3507C35.6361 11.7272 34.4403 10.4154 32.0487 10.4154C31.2952 10.4154 30.6266 10.5741 30.0388 10.8935C29.451 11.2129 28.9925 11.679 28.6652 12.2897V10.6585H27.025V20.5881H28.6854V14.8991C28.6854 13.9469 28.9581 13.1936 29.5056 12.6352C30.053 12.0787 30.7842 11.7995 31.6992 11.7995C32.4931 11.7995 33.0708 12.0104 33.4324 12.4323C33.7939 12.8541 33.9757 13.5271 33.9757 14.4512V20.5881H35.6361Z"
                fill={color}
            />
            <path
                d="M43.5503 19.3446L43.4675 20.6504C43.1524 20.7046 42.811 20.7327 42.4414 20.7327C41.2375 20.7327 40.3325 20.4193 39.7245 19.7946C39.1165 19.1699 38.8115 18.3 38.8115 17.1851V11.9662H36.8643V10.6605H38.8115V7.64325H40.4719V10.6605H43.3019V11.9662H40.4719V17.0646C40.4719 18.6275 41.1971 19.4089 42.6454 19.4089C42.9464 19.4089 43.2473 19.3888 43.5483 19.3486L43.5503 19.3446Z"
                fill={color}
            />
            <path
                d="M54.1491 20.5881V14.3507C54.1491 11.7272 52.9532 10.4154 50.5616 10.4154C49.8082 10.4154 49.1436 10.5701 48.5619 10.8835C47.9801 11.1968 47.5256 11.6508 47.1984 12.2495V6.21495H45.538V20.5881H47.1984V14.8991C47.1984 13.9469 47.4711 13.1936 48.0185 12.6351C48.5659 12.0787 49.2971 11.7995 50.2122 11.7995C51.006 11.7995 51.5837 12.0104 51.9453 12.4323C52.3069 12.8541 52.4887 13.5271 52.4887 14.4511V20.5881H54.1491Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.9729 20.1C57.2558 19.6781 56.7023 19.0775 56.3124 18.296C55.9226 17.5146 55.7287 16.6066 55.7287 15.5741C55.7287 14.5415 55.9226 13.6336 56.3124 12.8521C56.7023 12.0707 57.2558 11.4701 57.9729 11.0482C58.6899 10.6264 59.5201 10.4154 60.4635 10.4154C61.4068 10.4154 62.241 10.6264 62.9642 11.0482C63.6893 11.4701 64.2448 12.0707 64.6347 12.8521C65.0245 13.6336 65.2184 14.5415 65.2184 15.5741C65.2184 16.6066 65.0245 17.5146 64.6347 18.296C64.2448 19.0775 63.6873 19.6801 62.9642 20.1C62.239 20.5218 61.4068 20.7327 60.4635 20.7327C59.5201 20.7327 58.6899 20.5218 57.9729 20.1ZM62.7299 18.3965C63.255 17.7516 63.5197 16.8095 63.5197 15.5721C63.5197 14.3346 63.253 13.4287 62.7198 12.7678C62.1865 12.1089 61.4351 11.7794 60.4655 11.7794C59.4959 11.7794 58.7425 12.1089 58.2092 12.7678C57.6759 13.4267 57.4093 14.3608 57.4093 15.5721C57.4093 16.7834 57.6719 17.7336 58.1991 18.3864C58.7243 19.0393 59.4797 19.3647 60.4655 19.3647C61.4512 19.3647 62.2047 19.0413 62.7319 18.3965H62.7299Z"
                fill={color}
            />
            <path
                d="M66.9435 6.21495V20.5901H68.6039V6.21495H66.9435Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72.5913 20.1C71.8742 19.6781 71.3207 19.0775 70.9309 18.296C70.541 17.5146 70.3471 16.6066 70.3471 15.5741C70.3471 14.5415 70.541 13.6336 70.9309 12.8521C71.3207 12.0707 71.8742 11.4701 72.5913 11.0482C73.3084 10.6264 74.1386 10.4154 75.0819 10.4154C76.0252 10.4154 76.8595 10.6264 77.5826 11.0482C78.3078 11.4701 78.8633 12.0707 79.2531 12.8521C79.643 13.6336 79.8369 14.5415 79.8369 15.5741C79.8369 16.6066 79.643 17.5146 79.2531 18.296C78.8633 19.0775 78.3058 19.6801 77.5826 20.1C76.8575 20.5218 76.0252 20.7327 75.0819 20.7327C74.1386 20.7327 73.3084 20.5218 72.5913 20.1ZM77.3483 18.3965C77.8735 17.7516 78.1381 16.8095 78.1381 15.5721C78.1381 14.3346 77.8715 13.4287 77.3382 12.7678C76.8049 12.1089 76.0535 11.7794 75.0839 11.7794C74.1144 11.7794 73.3609 12.1089 72.8276 12.7678C72.2944 13.4267 72.0277 14.3608 72.0277 15.5721C72.0277 16.7834 72.2903 17.7336 72.8175 18.3864C73.3427 19.0393 74.0982 19.3647 75.0839 19.3647C76.0697 19.3647 76.8231 19.0413 77.3503 18.3965H77.3483Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M90.8073 19.9151V10.6605H89.1469V12.374C88.8459 11.7493 88.4015 11.2652 87.8137 10.9257C87.2259 10.5862 86.5351 10.4154 85.7433 10.4154C84.8686 10.4154 84.093 10.6183 83.4163 11.0261C82.7396 11.4339 82.2124 12.0124 81.8367 12.7597C81.461 13.507 81.2731 14.3708 81.2731 15.3491C81.2731 16.3274 81.461 17.1912 81.8367 17.9385C82.2124 18.6857 82.7396 19.2643 83.4163 19.6721C84.093 20.0799 84.8686 20.2828 85.7433 20.2828C86.5492 20.2828 87.2481 20.112 87.844 19.7725C88.4399 19.433 88.8803 18.9429 89.1671 18.3041V20.0578C89.1671 21.0361 88.9227 21.7793 88.4298 22.2896C87.9369 22.7998 87.2138 23.0549 86.2563 23.0549C85.5332 23.0549 84.8767 22.9605 84.2889 22.7697C83.7011 22.5788 83.0709 22.2675 82.4022 21.8316L81.8488 23.0549C83.0365 23.9529 84.507 24.4008 86.2563 24.4008C87.7592 24.4008 88.8944 24.0232 89.66 23.2699C90.4235 22.5146 90.8073 21.3977 90.8073 19.9151ZM89.1469 15.3491C89.1469 16.464 88.8742 17.3358 88.3268 17.9686C87.7794 18.6014 87.0421 18.9168 86.0705 18.9168C85.0989 18.9168 84.3374 18.6014 83.7839 17.9686C83.2304 17.3378 82.9537 16.4499 82.9537 15.3491C82.9537 14.2483 83.2304 13.3825 83.7839 12.7497C84.3374 12.1169 85.0989 11.8015 86.0705 11.8015C87.0421 11.8015 87.7976 12.1169 88.3369 12.7497C88.8762 13.3825 89.1469 14.2342 89.1469 15.3491Z"
                fill={color}
            />
            <path
                d="M100.669 10.6785H102.329L96.4248 24.2582H94.724L96.4046 20.4655L92.1809 10.6785H93.9443L97.2853 18.7942L100.669 10.6785Z"
                fill={color}
            />
            <path
                d="M134.976 10.4958H132.412C132.378 10.4958 132.35 10.5239 132.35 10.558V16.1647C132.35 16.92 132.134 17.5327 131.709 17.9887C131.285 18.4427 130.736 18.6717 130.073 18.6717C129.467 18.6717 129.021 18.5069 128.746 18.1835C128.469 17.8561 128.33 17.3418 128.33 16.6528V10.558C128.33 10.5239 128.302 10.4958 128.267 10.4958H125.704C125.67 10.4958 125.642 10.5239 125.642 10.558V16.6126C125.642 19.3848 126.904 20.791 129.393 20.791C130.714 20.791 131.726 20.2727 132.406 19.2522V20.5258C132.406 20.56 132.435 20.5881 132.469 20.5881H134.97C135.004 20.5881 135.032 20.56 135.032 20.5258V10.556C135.032 10.5219 135.004 10.4938 134.97 10.4938L134.976 10.4958Z"
                fill={color}
            />
            <path
                d="M147.655 10.8212C148.249 10.4696 148.936 10.2929 149.695 10.2929L149.701 10.2909C151.986 10.2909 153.145 11.689 153.145 14.4491V20.5238C153.145 20.5579 153.117 20.5861 153.083 20.5861H150.519C150.485 20.5861 150.457 20.5579 150.457 20.5238V14.5516C150.457 13.8083 150.334 13.2579 150.089 12.9164C149.851 12.5809 149.437 12.4122 148.859 12.4122C148.211 12.4122 147.695 12.6412 147.328 13.0932C146.956 13.5492 146.768 14.19 146.768 15.0015V20.5258C146.768 20.56 146.74 20.5881 146.706 20.5881H144.142C144.108 20.5881 144.08 20.56 144.08 20.5258V14.5536C144.08 13.8103 143.956 13.2599 143.712 12.9184C143.474 12.5829 143.066 12.4142 142.502 12.4142C141.854 12.4142 141.339 12.6432 140.971 13.0952C140.599 13.5512 140.411 14.192 140.411 15.0036V20.5278C140.411 20.562 140.383 20.5901 140.349 20.5901H137.786C137.751 20.5901 137.723 20.562 137.723 20.5278V10.558C137.723 10.5239 137.751 10.4958 137.786 10.4958H140.286C140.321 10.4958 140.349 10.5239 140.349 10.558V11.8638C140.642 11.4037 141.032 11.0341 141.506 10.7609C142.048 10.4496 142.678 10.2929 143.383 10.2929C144.857 10.2929 145.853 10.9216 146.344 12.1631C146.659 11.6006 147.1 11.1486 147.655 10.8212Z"
                fill={color}
            />
            <path
                d="M158.385 10.4958H155.824C155.789 10.4958 155.761 10.5236 155.761 10.558V20.5258C155.761 20.5602 155.789 20.5881 155.824 20.5881H158.385C158.42 20.5881 158.448 20.5602 158.448 20.5258V10.558C158.448 10.5236 158.42 10.4958 158.385 10.4958Z"
                fill={color}
            />
            <path
                d="M166.996 10.2929C166.267 10.2929 165.598 10.4455 165.011 10.7489C164.483 11.0201 164.049 11.4058 163.718 11.8959V10.558C163.718 10.5239 163.689 10.4958 163.655 10.4958H161.154C161.12 10.4958 161.092 10.5239 161.092 10.558V20.5278C161.092 20.562 161.12 20.5901 161.154 20.5901H163.718C163.752 20.5901 163.78 20.562 163.78 20.5278V14.9212C163.78 14.1659 163.999 13.5532 164.429 13.0972C164.859 12.6432 165.447 12.4142 166.178 12.4142C166.77 12.4142 167.214 12.5849 167.493 12.9224C167.776 13.2639 167.919 13.8184 167.919 14.5757V20.5278C167.919 20.562 167.948 20.5901 167.982 20.5901H170.545C170.58 20.5901 170.608 20.562 170.608 20.5278V14.4531C170.608 11.693 169.394 10.2949 167 10.2949L166.996 10.2929Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M175.686 10.4475C176.227 10.3451 176.744 10.2929 177.225 10.2929L177.221 10.2909C178.694 10.2909 179.805 10.6364 180.524 11.3174C181.243 11.9984 181.609 13.0671 181.609 14.4913V20.5258C181.609 20.56 181.58 20.5881 181.546 20.5881H179.148C179.114 20.5881 179.086 20.56 179.086 20.5258V19.212C178.849 19.662 178.51 20.0276 178.074 20.3028C177.561 20.6262 176.967 20.791 176.237 20.791C175.508 20.791 174.848 20.6483 174.274 20.3671C173.698 20.0839 173.242 19.6962 172.917 19.214C172.589 18.7299 172.424 18.1775 172.424 17.5688C172.424 16.8235 172.622 16.2229 173.014 15.785C173.403 15.3491 174.042 15.0317 174.912 14.8388C175.775 14.648 176.944 14.5516 178.391 14.5516H179.047V14.186C179.047 13.5271 178.902 13.047 178.615 12.7617C178.328 12.4765 177.833 12.3318 177.142 12.3318C176.591 12.3318 176.017 12.4182 175.438 12.5849C174.856 12.7537 174.262 13.0148 173.668 13.3644C173.654 13.3744 173.636 13.3764 173.618 13.3704C173.599 13.3644 173.585 13.3523 173.579 13.3362L172.842 11.6026C172.832 11.5765 172.84 11.5444 172.864 11.5283C173.222 11.2832 173.652 11.0643 174.139 10.8794C174.626 10.6946 175.145 10.55 175.686 10.4475ZM179.049 16.1486H178.415C177.144 16.1486 176.252 16.247 175.763 16.4399C175.286 16.6267 175.054 16.9581 175.054 17.4523C175.054 17.8802 175.205 18.2357 175.504 18.5069C175.805 18.7801 176.233 18.9187 176.775 18.9187C177.423 18.9187 177.971 18.6918 178.401 18.2438C178.831 17.7958 179.049 17.2193 179.049 16.5323V16.1486Z"
                fill={color}
            />
            <path
                d="M190.521 18.3543C190.506 18.3462 190.49 18.3422 190.472 18.3462C190.254 18.4005 190.042 18.4507 189.838 18.4989C189.64 18.5451 189.444 18.5692 189.258 18.5692C188.638 18.5692 188.184 18.4125 187.911 18.1011C187.636 17.7878 187.497 17.3077 187.497 16.6729V12.5347H190.468C190.502 12.5347 190.531 12.5066 190.531 12.4724V10.556C190.531 10.5219 190.502 10.4937 190.468 10.4937H187.497V7.57894C187.497 7.54479 187.468 7.51667 187.434 7.51667H184.871C184.836 7.51667 184.808 7.54479 184.808 7.57894V10.4937H182.944C182.909 10.4937 182.881 10.5219 182.881 10.556V12.4724C182.881 12.5066 182.909 12.5347 182.944 12.5347H184.808V16.8155C184.808 19.4531 186.168 20.791 188.846 20.791C189.204 20.791 189.577 20.7488 189.955 20.6664C190.335 20.584 190.656 20.4716 190.904 20.3329C190.929 20.3189 190.941 20.2928 190.935 20.2667L190.545 18.3904C190.541 18.3743 190.531 18.3603 190.517 18.3502L190.521 18.3543Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M197.061 10.2909C198.493 10.2909 199.643 10.7509 200.479 11.6569H200.481C201.315 12.5608 201.737 13.8083 201.737 15.3672V16.1225C201.737 16.1566 201.709 16.1847 201.675 16.1847H194.569C194.775 17.8983 195.758 18.7319 197.574 18.7319C198.126 18.7319 198.685 18.6476 199.237 18.4808C199.786 18.3141 200.297 18.0449 200.754 17.6853C200.77 17.6733 200.79 17.6693 200.808 17.6733C200.828 17.6793 200.843 17.6914 200.851 17.7094L201.568 19.421C201.578 19.4451 201.57 19.4732 201.55 19.4913C201.067 19.889 200.453 20.2064 199.726 20.4394C198.998 20.6704 198.261 20.789 197.534 20.789C196.395 20.789 195.399 20.574 194.575 20.1502C193.749 19.7263 193.102 19.1136 192.654 18.3322C192.205 17.5527 191.979 16.5885 191.979 15.55C191.979 14.5114 192.197 13.5893 192.631 12.8099C193.066 12.0285 193.672 11.4078 194.437 10.9618C195.203 10.5158 196.086 10.2909 197.061 10.2909ZM195.383 12.822C194.944 13.2579 194.67 13.8525 194.569 14.5917L194.571 14.5938H199.477C199.414 13.8143 199.192 13.2077 198.811 12.7898C198.421 12.364 197.895 12.147 197.164 12.147C196.433 12.147 195.821 12.3861 195.383 12.822Z"
                fill={color}
            />
            <path
                d="M117.875 18.3181H117.669C116.598 18.3181 116.394 17.7155 116.394 16.5986V6.15472C116.394 6.12057 116.366 6.09244 116.332 6.09244H113.768C113.734 6.09244 113.706 6.12057 113.706 6.15472V16.918C113.706 18.1775 113.996 19.1478 114.572 19.8026C115.148 20.4595 116.051 20.793 117.253 20.793H118.36C118.378 20.793 118.396 20.785 118.408 20.7709C118.42 20.7568 118.424 20.7388 118.42 20.7207L117.931 18.3704C117.925 18.3422 117.901 18.3221 117.871 18.3221L117.875 18.3181Z"
                fill={color}
            />
            <path
                d="M123.64 18.3181H123.846L123.842 18.3221C123.872 18.3221 123.896 18.3422 123.902 18.3704L124.391 20.7207C124.395 20.7388 124.391 20.7568 124.379 20.7709C124.367 20.785 124.349 20.793 124.331 20.793H123.224C122.022 20.793 121.119 20.4595 120.543 19.8026C119.967 19.1478 119.677 18.1775 119.677 16.918V6.15472C119.677 6.12057 119.705 6.09244 119.739 6.09244H122.303C122.337 6.09244 122.365 6.12057 122.365 6.15472V16.5986C122.365 17.7155 122.569 18.3181 123.64 18.3181Z"
                fill={color}
            />
        </SvgIcon>
    );
};
