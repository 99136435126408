import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';
import { IDeveloperPageCardProps } from './DeveloperPageCard.types';

const styles = (theme: Theme) =>
    createStyles({
        link: {
            textDecoration: 'none',
            color: theme.palette.text.primary,
            '&:hover, &:focus': {
                textDecoration: 'underline',
                color: theme.palette.text.primary,
            },
            outline: 'none',
            '&:focus': {
                boxShadow: `0px 0px 0px 2px ${theme.palette.focus.main}`,
            },
            display: 'flex',
            alignItems: 'center',
            padding: '8px 0',
        },
        linkText: {
            marginRight: theme.spacing(0.5),
        },
        title: {
            fontWeight: theme.typography.fontWeightRegular,
        },
        image: (props: IDeveloperPageCardProps) => ({
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: props.image?.objectPosition,
            [theme.breakpoints.up('sm')]: {
                maxWidth: '454px',
            },
        }),
        card: {
            borderRadius: '4px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
        },
        imageContainer: (props: IDeveloperPageCardProps) => ({
            width: '100%',
            height: '60%',
            [theme.breakpoints.up('sm')]: {
                flex: '0 1 50%',
                height: '100%',
                textAlign: props.image?.position,
            },
        }),
        contentSection: (props: IDeveloperPageCardProps) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'auto 1fr auto',
            height: '100%',
            gap: `${theme.spacing(2)}px`,
            padding: `${theme.spacing(2)}px`,
            [theme.breakpoints.up('sm')]: {
                padding: `${theme.spacing(3)}px ${theme.spacing(2.5)}px`,
                flex: props.image ? '0 1 50%' : '1',
            },
            [theme.breakpoints.up('lg')]: {
                padding: `${theme.spacing(4.5)}px ${theme.spacing(
                    3.5,
                )}px ${theme.spacing(3)}px ${theme.spacing(3.5)}px`,
            },
        }),
        childrenSection: {
            display: 'grid',
            gap: `${theme.spacing(2)}px`,
        },
    });

const options = {
    name: 'DeveloperPageCard-',
};

export const useStyles = (props: IDeveloperPageCardProps) =>
    makeStyles(styles, options)(props);
