import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        badge: {
            '& span': {
                color: theme.palette.indicatorPrimary.main,
            },
        },
    });

const options = {
    name: 'ListNavBadge-',
};

export const useStyles = makeStyles(styles, options);
