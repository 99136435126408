import {
    createStyles,
    makeStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            title: {
                paddingBottom: theme.spacing(1),
            },
            input: {
                margin: '6px 0 6px',
                width: '24em',
                whiteSpace: 'nowrap',
                [theme.breakpoints.only('xs')]: {
                    width: '80%',
                    whiteSpace: 'wrap',
                },
            },
            infoSection: {
                display: 'flex',
                alignItems: 'center',
            },
            infoText: {
                marginInlineStart: theme.spacing(1),
                fontSize: '12px',
            },
        }),
    {
        name: 'InstitutionalEmail-',
    },
);
