import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            description: {
                paddingBottom: theme.spacing(3),
            },
            header: {
                paddingBottom: theme.spacing(1),
                marginTop: theme.spacing(2),
            },
            tableHeader: {
                fontSize: '0.75rem',
                backgroundColor: 'inherit',
                paddingBottom: '0',
                borderTop: '0',
                paddingTop: '0',
            },
            tableHeaderRow: {
                height: '25px',
                verticalAlign: 'top',
            },
            tableContainer: {
                overflowX: 'auto',
            },
        }),
    {
        name: 'SnowflakeAccountSettings-',
    },
);
