import React, { useState } from 'react';

import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Table } from '@bb-ui/react-library/dist/components/Table';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import { TableBody } from '@bb-ui/react-library/dist/components/TableBody';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { SearchBox } from '@bb-ui/react-library/dist/components/SearchBox';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { Checkbox } from '@bb-ui/react-library/dist/components/Checkbox';
import { FormControlLabel } from '@bb-ui/react-library/dist/components/FormControlLabel';
import { Box } from '@bb-ui/react-library/dist/components/Box';
import { Card } from '@bb-ui/react-library/dist/components/Card';
import { CardContent } from '@bb-ui/react-library/dist/components/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Banner } from '@bb-ui/react-library/dist/components/Banner';
import { Warning } from '@bb-ui/icons/dist/medium';

import { ButtonBase } from '@bb-ui/react-library/dist/components/ButtonBase';
import { Edit } from '@bb-ui/icons/dist/small/Edit';

import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Link,
    OffScreenAnnouncement,
    Skeleton,
    Tooltip,
} from '@bb-ui/react-library/dist/components';
import { Information } from '@bb-ui/icons/dist/small';
import { useStyles } from './LicenseManagement.styles';
import { IQSSeat } from '../QSSeats.types';
import { PagingControls } from '../../PagingControls/PagingControls';
import { ILocationState } from '../../../types/appUITypes';
import {
    getPageNumber,
    getPageNumberOptions,
    updateLocation,
} from '../../../utilities/pagination';
import { mapToMenuItems } from '../../../utilities/selectInput';
import { getQsLocale } from '../../../utilities/localization';
import { useI18nContext } from '../../I18nProvider/I18nProvider';
import { useAppConfigContext } from '../../../contexts/AppConfigProvider';
import { SkeletonTable } from '../../Skeleton/SkeletonTable';
import { SkeletonCard } from '../../Skeleton/SkeletonCard';
import { Message } from '../../Message/Message';
import { useHelpLinks } from '../../../hooks/useHelpLinks';

interface ILicenseManagementProps {
    tableData: IQSSeat[];
    quotaForReaders: number;
    quotaForAuthors: number;
    loading: boolean;
    error: boolean;
}

interface ITableRowData {
    username: string;
    role: string;
    status: string;
    lastAccess: string;
    actions?: React.ReactNode;
}

export const LicenseManagement: React.FC<ILicenseManagementProps> = (
    props: ILicenseManagementProps,
) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation<ILocationState>();
    const history = useHistory();
    const { userSelectedLang } = useI18nContext();
    const getHelpLink = useHelpLinks();
    const { pagination } = useAppConfigContext();

    const ITEMS_PER_PAGE = pagination?.itemsPerPage ?? 10;

    const getColumnLabel = (key: string) =>
        t(`settings.licenseManagement.tableHeaders.${key}`);

    const columnKeys = ['username', 'role', 'status', 'lastAccess', 'actions'];

    const tableColumns = columnKeys.map(key => ({
        id: key,
        label: getColumnLabel(key),
    }));

    const createTableRow = (rowData: ITableRowData) => (
        <TableRow role="row" data-testid="QSSeat-row" key={rowData.username}>
            {columnKeys.map(key => (
                <TableCell
                    role="gridcell"
                    key={key}
                    align={key === 'actions' ? 'right' : 'left'}
                >
                    {rowData[key as keyof ITableRowData]}
                </TableCell>
            ))}
        </TableRow>
    );

    const handleEditClick = (id: string) => {
        history.push(`/settings/user/${id}`);
    };

    const tableData = props.tableData.map(seat => {
        const row: {
            username: string;
            role: any;
            status: any;
            lastAccess: any;
            actions?: React.ReactNode;
        } = {
            username: seat.username,
            role: t(
                `settings.licenseManagement.filterByRole.${seat.role.toLowerCase()}`,
            ),
            status: t(
                `settings.licenseManagement.filterByStatus.${
                    seat.isActive ? 'active' : 'inactive'
                }`,
            ),
            lastAccess: seat.lastAccess
                ? new Date(+seat.lastAccess * 1000).toLocaleString(
                      getQsLocale(userSelectedLang).qsLngCode,
                  )
                : t('settings.licenseManagement.never'),
        };

        row.actions = (
            <div className={classes.actionsContainer}>
                <Link
                    data-testid={`edit-user-link-${seat.username}`}
                    onClick={() => {
                        handleEditClick(seat.username);
                    }}
                    className={classes.button}
                >
                    <ButtonBase
                        className={classes.editButton}
                        aria-label={t(
                            'settings.licenseManagement.editUserButtonAria',
                            { username: seat.username },
                        )}
                    >
                        <Edit className={classes.editIcon} />
                    </ButtonBase>
                </Link>
            </div>
        );

        return row;
    });

    const numOfQSSeatsCapacity: number =
        props.quotaForAuthors + props.quotaForReaders;
    const numOfActiveUsers: number = props.tableData.filter(
        user => user.isActive,
    ).length;
    const numOfInactiveUsers: number = props.tableData.filter(
        user => user.isActive === false,
    ).length;
    const numOfActiveAuthors: number = props.tableData.filter(
        user => user.isActive && user.role === 'Author',
    ).length;
    const isQSSeatsCapacityFull =
        numOfActiveUsers >= numOfQSSeatsCapacity && numOfQSSeatsCapacity > 0;

    const filteredData: ITableRowData[] = tableData;

    let filteredDataForPagination: ITableRowData[] = filteredData;

    const [searchBoxValue, setSearchBoxValue] = useState('');

    const [searchValue, setSearchValue] = useState('');

    const filterByRoleOptions = [
        {
            key: 'All roles',
            name: t('settings.licenseManagement.filterByRole.allRoles'),
        },
        {
            key: 'Author',
            name: t('settings.licenseManagement.filterByRole.author'),
        },
        {
            key: 'Reader',
            name: t('settings.licenseManagement.filterByRole.reader'),
        },
    ];

    const filterByStatusOptions = [
        {
            key: 'All',
            name: t('settings.licenseManagement.filterByStatus.all'),
        },
        {
            key: 'Active',
            name: t('settings.licenseManagement.filterByStatus.active'),
        },
        {
            key: 'Inactive',
            name: t('settings.licenseManagement.filterByStatus.inactive'),
        },
    ];

    const [filterByRoleSelectedOption, setFilterByRoleSelectedOption] =
        React.useState('All roles');

    const [filterByStatusSelectedOption, setFilterByStatusSelectedOption] =
        React.useState('All');

    const [isHideInactiveChecked, setIsHideInactiveChecked] = useState(false);

    const handleSearch = (value: string) => {
        setSearchValue(value);
        updateLocation({ page: 1 }, history, location);
    };

    const handleFilterByRole = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setFilterByRoleSelectedOption(event.target.value);
        updateLocation({ page: 1 }, history, location);
    };

    const handleFilterByStatus = (event: {
        target: { value: React.SetStateAction<string> };
    }) => {
        setFilterByStatusSelectedOption(event.target.value);
        updateLocation({ page: 1 }, history, location);
    };

    const handleHideInactive = (event: {
        target: { checked: boolean | ((prevState: boolean) => boolean) };
    }) => {
        setIsHideInactiveChecked(event.target.checked);
        updateLocation({ page: 1 }, history, location);
    };

    const getTranslatedRoleValue = (value: string) =>
        t(`settings.licenseManagement.filterByRole.${value.toLowerCase()}`);

    const getTranslatedStatusValue = (value: string) =>
        t(`settings.licenseManagement.filterByStatus.${value.toLowerCase()}`);

    const handleFiltering = () => {
        filteredDataForPagination = filteredData
            .filter(row =>
                row.username
                    .toString()
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()),
            )
            .filter(row =>
                row.role.includes(
                    filterByRoleSelectedOption === 'All roles'
                        ? ''
                        : getTranslatedRoleValue(filterByRoleSelectedOption),
                ),
            )
            .filter(row =>
                row.status.includes(
                    filterByStatusSelectedOption === 'All'
                        ? ''
                        : getTranslatedStatusValue(
                              filterByStatusSelectedOption,
                          ),
                ),
            )
            .filter(row =>
                row.status.includes(
                    isHideInactiveChecked
                        ? getTranslatedStatusValue('Active')
                        : '',
                ),
            );

        return filteredDataForPagination
            .slice(
                (getPageNumber(location) - 1) * ITEMS_PER_PAGE,
                getPageNumber(location) * ITEMS_PER_PAGE,
            )
            .map(createTableRow);
    };

    const updateCurrentPage = (newPage: number) => {
        updateLocation({ page: newPage }, history, location);
    };

    if (props.error) {
        return (
            <>
                <Typography
                    component="p"
                    className={classes.description}
                    data-testid="description"
                >
                    {t('settings.licenseManagement.description')}
                </Typography>
                <div
                    data-testid="license-management-error"
                    className={classes.errorMessage}
                >
                    <Message
                        variant="error"
                        message={t('general.contentError')}
                        secondaryMessage={t('general.contentErrorRefresh', {
                            action: (
                                <Link
                                    href={getHelpLink('contactSupport')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-testid="data-q-and-a-help-link"
                                >
                                    {t('general.contactSupportAction')}
                                </Link>
                            ),
                        })}
                    />
                </div>
            </>
        );
    }
    if (props.loading) {
        return (
            <div data-testid="license-management-loading">
                <Typography
                    component="p"
                    className={classes.description}
                    data-testid="description"
                >
                    {t('settings.licenseManagement.description')}
                </Typography>
                <OffScreenAnnouncement
                    type="polite"
                    message={t('settings.licenseManagement.loadingAriaLabel')}
                    changeTrigger={1}
                />
                <div className={classes.cardsContainer}>
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                </div>
                <div className={classes.skeletonFiltersContainer}>
                    <Skeleton
                        variant="text"
                        animation="wave"
                        className={classes.skeletonFilter}
                    />
                    <Skeleton
                        variant="text"
                        animation="wave"
                        className={classes.skeletonFilter}
                    />
                    <Skeleton
                        variant="text"
                        animation="wave"
                        className={classes.skeletonFilter}
                    />
                </div>
                <Skeleton
                    variant="text"
                    animation="wave"
                    width="25%"
                    height="40px"
                />
                <SkeletonTable />
            </div>
        );
    }
    return (
        <>
            {isQSSeatsCapacityFull && (
                <Banner
                    severity="warning"
                    icon={<Warning />}
                    data-testid="warning-banner"
                    className={classes.banner}
                >
                    <p role="alert">
                        {t('settings.licenseManagement.bannerOutOfSeats', {
                            link: (
                                <Link
                                    href="https://www.anthology.com/contact-us/sales"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-testid="link-to-anthology-sales"
                                    lang="en"
                                >
                                    Anthology Sales center
                                </Link>
                            ),
                        })}
                    </p>
                </Banner>
            )}
            <Typography
                component="p"
                className={classes.description}
                data-testid="description"
            >
                {t('settings.licenseManagement.description')}
            </Typography>
            <div className={classes.cardsContainer}>
                <Box maxWidth={{ sm: 1 / 4 }} className={classes.card}>
                    <Card data-testid="totalLicenses">
                        <CardHeader
                            title={t(
                                'settings.licenseManagement.cardsTitles.totalLicenses',
                            )}
                            titleTypographyProps={{
                                variant: 'h4',
                            }}
                            action={
                                <Tooltip
                                    title={t(
                                        'settings.licenseManagement.totalLicensesTooltip',
                                    )}
                                    placement="top"
                                    role="tooltip"
                                    id="totalLicenseTooltip"
                                >
                                    <Information />
                                </Tooltip>
                            }
                        />
                        <CardContent collapse="top">
                            <Typography variant="h3">
                                {t(
                                    'settings.userManagement.creditsUsage.credits',
                                    {
                                        remaining: numOfActiveUsers,
                                        total: numOfQSSeatsCapacity,
                                    },
                                )}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>

                <Box maxWidth={{ sm: 1 / 4 }} className={classes.card}>
                    <Card data-testid="authorUsers">
                        <CardHeader
                            title={t(
                                'settings.licenseManagement.cardsTitles.authorUsers',
                            )}
                            titleTypographyProps={{
                                variant: 'h4',
                            }}
                            action={
                                <Tooltip
                                    title={t(
                                        'settings.licenseManagement.authorUsersTooltip',
                                    )}
                                    placement="top"
                                    role="tooltip"
                                    id="authorUsersTooltip"
                                    data-testid="authorUsersTooltip"
                                >
                                    <Information />
                                </Tooltip>
                            }
                        />
                        <CardContent collapse="top">
                            <Typography variant="h3">
                                {t(
                                    'settings.userManagement.creditsUsage.credits',
                                    {
                                        remaining: numOfActiveAuthors,
                                        total: props.quotaForAuthors,
                                    },
                                )}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>

                <Box maxWidth={{ sm: 1 / 4 }} className={classes.card}>
                    <Card data-testid="activeUsers">
                        <CardHeader
                            title={t(
                                'settings.licenseManagement.cardsTitles.activeUsers',
                            )}
                            titleTypographyProps={{
                                variant: 'h4',
                            }}
                            action={
                                <Tooltip
                                    title={
                                        /* prettier-ignore */
                                        <Trans i18nKey="settings.licenseManagement.activeUsersTooltip">
                                            Users who accessed QuickSight this month via: Standard Reports, Custom Reports or <span lang="en">Data Q&A.</span>
                                        </Trans>
                                    }
                                    placement="top"
                                    role="tooltip"
                                    id="activeUsersTooltip"
                                    data-testid="activeUsersTooltip"
                                >
                                    <Information />
                                </Tooltip>
                            }
                        />
                        <CardContent collapse="top">
                            <Typography variant="h3">
                                {numOfActiveUsers}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>

                <Box maxWidth={{ sm: 1 / 4 }} className={classes.card}>
                    <Card data-testid="inactiveUsers">
                        <CardHeader
                            title={t(
                                'settings.licenseManagement.cardsTitles.inactiveUsers',
                            )}
                            titleTypographyProps={{
                                variant: 'h4',
                            }}
                            action={
                                <Tooltip
                                    title={t(
                                        'settings.licenseManagement.inactiveUsersTooltip',
                                    )}
                                    placement="top"
                                    role="tooltip"
                                    id="inactiveUsersTooltip"
                                    data-testid="inactiveUsersTooltip"
                                >
                                    <Information />
                                </Tooltip>
                            }
                        />
                        <CardContent collapse="top">
                            <Typography variant="h3">
                                {numOfInactiveUsers}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </div>
            <Typography variant="h3" gutterBottom>
                {`${t(
                    'settings.licenseManagement.allUsers',
                )} (${numOfQSSeatsCapacity})`}
            </Typography>
            <div className={classes.searchContainer}>
                <SearchBox
                    inputId="license-management-entry-search-input"
                    label={t('settings.licenseManagement.search')}
                    onChange={newValue => {
                        setSearchBoxValue(newValue);
                    }}
                    onSearch={handleSearch}
                    onClear={() => setSearchValue('')}
                    value={searchBoxValue}
                    className={classes.searchBox}
                />
                <TextField
                    select
                    id="license-management-entry-filter-by-role-input"
                    label={t('settings.licenseManagement.filterByRole.title')}
                    inputProps={{ 'data-testid': 'filter-by-role' }}
                    floatingLabel={true}
                    value={filterByRoleSelectedOption}
                    onChange={handleFilterByRole}
                    className={classes.filterByTextField}
                >
                    {mapToMenuItems(filterByRoleOptions)}
                </TextField>
                <TextField
                    select
                    id="license-management-entry-filter-by-status-input"
                    label={t('settings.licenseManagement.filterByStatus.title')}
                    inputProps={{ 'data-testid': 'filter-by-status' }}
                    floatingLabel={true}
                    value={filterByStatusSelectedOption}
                    onChange={handleFilterByStatus}
                    className={classes.filterByTextField}
                >
                    {mapToMenuItems(filterByStatusOptions)}
                </TextField>
                <FormControlLabel
                    label={t('settings.licenseManagement.HideInactiveUsers')}
                    htmlFor="hideInactive"
                    className={classes.checkbox}
                    data-testid="hideInactive"
                    control={
                        <Checkbox
                            value="checkbox"
                            id="hideInactive"
                            checked={isHideInactiveChecked}
                            onChange={handleHideInactive}
                        />
                    }
                />
            </div>
            <div className={classes.tableContainer}>
                <Table
                    role="grid"
                    aria-colcount={tableColumns.length}
                    aria-rowcount={filteredData.length}
                >
                    <TableHead>
                        <TableRow>
                            {tableColumns.map(({ id, label }) => (
                                <TableCell
                                    role="columnheader"
                                    key={id}
                                    style={{
                                        textAlign:
                                            label === 'Actions'
                                                ? 'center'
                                                : 'inherit',
                                    }}
                                >
                                    {label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.length > 0 ? (
                            handleFiltering()
                        ) : (
                            <TableRow key="noData">
                                <TableCell
                                    colSpan={tableColumns.length}
                                    className={classes.noDataCell}
                                >
                                    {t('errors.noData')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className={classes.pagination}>
                <PagingControls
                    pageNumberOptions={getPageNumberOptions(
                        ITEMS_PER_PAGE,
                        filteredDataForPagination.length,
                    )}
                    pageNumber={getPageNumber(location)}
                    updateCurrentPage={updateCurrentPage}
                />
            </div>
        </>
    );
};
