import React from 'react';
import { Typography, useTheme } from '@bb-ui/react-library';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { useStyles } from './CustomReportsUnauthorized.styles';
import { useApplicationTitle } from '../../hooks/useApplicationTitle';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { ActionAreaButtons } from '../ActionAreaButtons/ActionAreaButtons';

export const CustomReportsUnauthorized: React.FunctionComponent = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();
    const getHelpLink = useHelpLinks();
    const history = useHistory();

    // This does not get localized
    useApplicationTitle('Custom Reports');

    return (
        <div
            id="custom-reports-unauthorized"
            data-testid="custom-reports-unauthorized"
            className={classes.blockContainer}
        >
            <div
                className={classes.imageContainer}
                data-testid="custom-reports-illustration"
            >
                <img
                    src="/images/CustomReports/illustration.svg"
                    alt=""
                    className={classes.image}
                    loading="lazy"
                />
            </div>
            <div className={classes.textContainer}>
                <Typography
                    variant="h1"
                    data-testid="custom-reports-unauthorized-primary-text"
                    className={classes.text}
                >
                    {t('enhancedFeatures.unathorized.primaryText', {
                        featureName: t('authoring.title') as string,
                    })}
                </Typography>
                <Typography
                    variant="h2"
                    data-testid="custom-reports-unauthorized-secondary-text"
                    className={classes.text}
                >
                    {t('enhancedFeatures.unathorized.secondaryText')}
                </Typography>
            </div>
            <PrimaryButton
                onClick={() => {
                    history.push('/');
                }}
                data-testid="back-to-home-button"
                className={classes.button}
            >
                {t('enhancedFeatures.unathorized.backButton')}
            </PrimaryButton>
            <ActionAreaButtons helpLink={getHelpLink('customReports')} />
        </div>
    );
};
