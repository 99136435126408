import React from 'react';
import { useFeatureContext } from '../contexts/FeatureContext';
import { useUser } from '../hooks/useUser';

/**
 * This component displays a list of feature flags and their current status.
 * This will be beneficial to see that a flag is being successfully pulled in from the
 * foundations feature flag service and/or config with the correct status.
 */
export function SilentFeatureFlagIndicator(): JSX.Element | null {
    const featureFlags = useFeatureContext();
    const user = useUser();

    return user?.isUniversalUser() ? (
        <ul style={{ display: 'none' }} data-testid="feature-flags-list">
            {Object.entries(featureFlags).map(([key, value]) => (
                <li key={key}>{`  ${key} : ${value}  `}</li>
            ))}
        </ul>
    ) : null;
}
