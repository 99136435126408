import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        policies: {
            [theme.breakpoints.up('sm')]: {
                maxWidth: `${theme.breakpoints.width('sm')}rem`,
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '800px',
            },
        },
        policySection: {
            marginBottom: '10px',
            '& p': {
                margin: 0,
            },
        },
    });

const options = {
    name: 'PrivacyAndSecurity-',
};

export const useStyles = makeStyles(styles, options);
