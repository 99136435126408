export enum UasMigrationStatus {
    RUNNING = 'RUNNING',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
    FAILED_CAN_RETRY = 'FAILED_CAN_RETRY',
}

export interface UasMigrationStatusInput {
    tenantId: string;
    userIds: Record<string, UasMigrationUserMapping>;
}

export interface UasMigrationStatusOutput {
    tenantId: string;
    migrationStatus: UasMigrationStatus;
    error?: string;
    cause?: string;
    userMigrationResult?: Record<string, UasMigrationUserResult>;
}

export interface UasMigrationUserMapping {
    userName?: string;
    idpId?: string;
    remove?: boolean;
}

export interface UasMigrationUserResult {
    email: string;
    status: UasMigrationUserStatus;
    details?: Array<string>;
}

export enum UasMigrationUserStatus {
    MIGRATED = 'MIGRATED',
    REMOVED = 'REMOVED',
    FAILED = 'FAILED',
}

export interface UasMigrationGetStatusResponse {
    migrationId: string;
    migrationStatus: UasMigrationStatus;
    startDate: string;
    stopDate?: string;
    input?: UasMigrationStatusInput;
    error?: string;
    cause?: string;
    output?: UasMigrationStatusOutput;
}

export type UasMigrationStatusContextProps = {
    polling: boolean;
    data?: UasMigrationGetStatusResponse;
    error?: any;
    restartPolling: () => void;
    isSupported: boolean;
    firstExecutionAvailable: boolean;
};
