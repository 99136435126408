import {
    createStyles,
    makeStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        appBar: {
            borderBottom: `6px solid ${theme.palette.background.b5}`,
            overflowX: 'auto',
            // The app bar needs to layer on top of the secondary nav drawer
            // when the skip link is visible
            zIndex: theme.zIndex.drawer + 1,
        },
        toolbar: {
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        section: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(0, 2),
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(0, 4),
            },
        },
        expandableSection: {
            padding: 0,
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-end',
                flex: 1,
                minWidth: 0,
                '& > ul': {
                    minWidth: 0,
                    textAlign: 'end',
                },
                padding: theme.spacing(0, 2, 0, 0),
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(0, 4),
            },
        },
        titleLink: {
            padding: theme.spacing(1.5, 0),
            margin: theme.spacing(0.5, 0, 0, 1),
            '&:focus': {
                outline: `2px solid ${theme.palette.focus.light}`,
            },
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(0, 0, 0, 1),
            },
        },
        itemList: {
            listStyle: 'none',
            display: 'flex',
            margin: 0,
            padding: 0,
            '& a': {
                whiteSpace: 'nowrap',
                display: 'block',
                textDecoration: 'none',
                fontSize: theme.typography.fontSizeDefault,
                fontWeight: theme.typography.fontWeightSemiBold,
                padding: theme.spacing(2, 1),
                outline: 'none',
            },
            '& a:focus': {
                boxShadow: `${theme.palette.focus.light} 0 0 0 2px inset`,
            },
            '& button': {
                whiteSpace: 'nowrap',
                padding: theme.spacing(2, 1),
                margin: theme.spacing(0, 1, 0, 0),
                [theme.breakpoints.up('sm')]: {
                    margin: theme.spacing(0, 1),
                },
                [theme.breakpoints.up('md')]: {
                    margin: 0,
                },
            },
            '& a:hover, & button:hover': {
                textDecoration: 'underline',
            },
            [theme.breakpoints.up('md')]: {
                '& a, & button': {
                    padding: theme.spacing(1),
                },
            },
        },
        closeButton: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            zIndex: 1,
            [theme.breakpoints.up('sm')]: {
                right: theme.spacing(2.5),
            },
        },
        listArea: {
            width: '100%',
            justifyContent: 'flex-end',
        },
        languagePickerArea: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'start',
            [theme.breakpoints.up('md')]: {
                overflow: 'hidden',
                width: '100%',
                maxWidth: '450px',
                minWidth: '100px',
                marginRight: theme.spacing(2),
            },
        },
        whatsNewButtonIcon: {
            transform: 'rotate(-45deg)',
        },
        authButtonListItem: {
            [theme.breakpoints.up('md')]: {
                margin: theme.spacing(0, 2),
            },
        },
        buttonText: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'inherit',
                marginRight: '8px',
            },
        },
    });

const options = {
    name: 'PageHeader-',
};

export const useStyles = makeStyles(styles, options);
