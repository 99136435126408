import React from 'react';
import Badge from '@bb-ui/react-library/dist/components/Badge/Badge';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ListNavBadge.styles';

export const ListNavBadge: React.FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Badge
            position="inline"
            badgeContent={t('authoring.badge').toUpperCase()}
            offScreenLabel={t('authoring.badgeAlt')}
            data-testid="featureBadge"
            className={classes.badge}
            color="primary"
        />
    );
};
