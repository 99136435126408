import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            primaryLink: {
                borderRadius: '2px',
                backgroundColor: theme.palette.background.b9,
                color: theme.palette.common.white,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
                width: 'fit-content',
                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                outline: 'none',
                '&:focus': {
                    border: `1px solid ${theme.palette.focus.main}`,
                    boxShadow: `0px 0px 0px 1px ${theme.palette.focus.light}`,
                },
                '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                },
            },
        }),
    {
        name: 'PrimaryLink-',
    },
);
