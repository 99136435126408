import React from 'react';
import classNames from 'classnames';
import { useStyles } from './SkipLink.styles';

interface SkipContentProps {
    linkClass?: string;
    targetRef:
        | React.RefObject<HTMLElement>
        | (() => HTMLElement | null | undefined);
    screenReaderOnly?: boolean;
}

export const SkipLink = React.forwardRef<
    HTMLAnchorElement,
    React.PropsWithChildren<SkipContentProps>
>(({ targetRef, linkClass, children, screenReaderOnly }, ref) => {
    const classes = useStyles();
    const [isFocused, setIsFocused] = React.useState(false);

    const getTarget = () =>
        typeof targetRef === 'function' ? targetRef() : targetRef?.current;

    const onClick = (e: React.MouseEvent) => {
        const target = getTarget();
        e.preventDefault();
        e.stopPropagation();

        if (!target) {
            console.warn('Skip link used without reference!');
            return false;
        }

        // If the element is not focus-able, make it so, otherwise this can't work
        if (!target.hasAttribute('tabindex')) {
            target.setAttribute('tabindex', '-1');
        }

        target.blur();
        target.focus();
    };

    return (
        <div className={classes.container}>
            <a
                ref={ref}
                tabIndex={screenReaderOnly ? -1 : 0}
                href="#"
                className={
                    isFocused
                        ? classNames(classes.skipLinkVisible, linkClass)
                        : classes.skipLinkHidden
                }
                onFocus={() => {
                    setIsFocused(true);
                }}
                onBlur={() => {
                    setIsFocused(false);
                }}
                onClick={onClick}
            >
                {children}
            </a>
        </div>
    );
});

SkipLink.displayName = 'SkipLink';
