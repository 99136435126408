import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const globalNavDrawerWidth = 260;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeGlobalNavDrawerButton: {
            margin: theme.spacing(2),
            alignSelf: 'flex-end',
            '&:focus': {
                color: theme.palette.common.white,
            },
        },
        globalNavDrawerPaper: {
            width: '100%',
            // Needs to appear layered on top of secondary nav drawer when
            // they are transitioning simultaneously. This occurs when the
            // global nav transitions in while the secondary transitions out.
            zIndex: theme.zIndex.drawer + 1,
            [theme.breakpoints.up('sm')]: {
                width: globalNavDrawerWidth,
            },
            [theme.breakpoints.up('md')]: {
                transition: `${theme.transitions.create(['transform'], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.standard,
                })} !important`,
            },
        },
        globalNavFlexColumnWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
        },
        globalNavSection: {
            // Tried using theme.palette.border.main but it uses the light
            // theme color instead of the dark theme color, not sure why
            borderTop: `1px solid #8c8c8c`,
            marginTop: theme.spacing(0.5),
        },
        globalNavTopSection: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        logoImageLinkContainer: {
            alignSelf: 'center',
        },
        logoImageLink: {
            display: 'block',
            paddingBottom: theme.spacing(2),
            '&:focus': {
                outline: `2px solid ${theme.palette.focus.light}`,
            },
        },
        subMenu: {
            '& li a': {
                paddingLeft: theme.spacing(4.5), // need to indent the individual list items so that highlighting is the entire menu width
            },
        },
        helpLink: {
            paddingLeft: theme.spacing(2), // match NavItem padding
            '& a': {
                fontSize: theme.typography.fontSizeSmall,
                textDecoration: 'underline',
                color: theme.palette.common.white,
                paddingLeft: theme.spacing(4.5), // account for the missing icon to keep text in alignment with other list item
            },
        },
    }),
);
