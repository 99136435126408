import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        footer: {
            [theme.breakpoints.only('xs')]: {
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(3),
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(6.5),
            },
        },
        footerContainer: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row-reverse',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
        },
        footerLogo: {
            // setting width allows the Anthology logo to scale appropriately for mobile and desktop views
            width: '120px',
            height: 'auto',
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(1.5),
                marginBottom: theme.spacing(0.5),
                width: '150px',
            },
        },
        footerLinks: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            // setting width on the links container so that when the strings are longer for other languages
            // they do not overlap with the help button
            width: '90%',
            '& > a:last-child': {
                marginRight: theme.spacing(0),
            },
            [theme.breakpoints.up('sm')]: {
                width: 'auto',
                paddingTop: theme.spacing(0),
            },
        },
        footerLink: {
            color: theme.palette.text.secondary,
            fontSize: theme.typography.fontSizeSmall,
            marginRight: theme.spacing(1.5),
            '&:focus': {
                color: theme.palette.text.primary,
            },
            '&:hover': {
                color: theme.palette.text.primary,
            },
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(6),
            },
        },
        anthologyAllRightsReserved: {
            color: theme.palette.text.secondary,
            fontSize: theme.typography.fontSizeSmall,
        },
    });

const options = {
    name: 'Footer-',
};

export const useStyles = makeStyles(styles, options);
