import { Skeleton } from '@bb-ui/react-library/dist/components/Skeleton';
import React from 'react';
import { useStyles } from './SkeletonTable.styles';

interface ISkeletonTableProps {
    columnNum?: number;
    rowNum?: number;
}

// WARNING: Please include accessibility component such as Off-screen Announcement when using this component
// More info in docs - https://bb-ui.bb-fnds.com/docs/react-library/components/skeleton
export const SkeletonTable: React.FC<ISkeletonTableProps> = ({
    columnNum = 6, // Default values for 6 x 9 table, these can be overridden by passing props
    rowNum = 8,
}: ISkeletonTableProps = {}) => {
    const classes: ReturnType<typeof useStyles> = useStyles();

    return (
        <>
            {[...Array(rowNum)].map((_row, rowIndex) => (
                <div key={rowIndex} className={classes.loadingTableContentDivs}>
                    {[...Array(columnNum)].map((_column, columnIndex) => (
                        <Skeleton
                            key={columnIndex}
                            data-testid="skeleton-cell"
                            className={classes.loadingTableCell}
                            style={{
                                width: `${Math.floor(
                                    100 / columnNum - 1, // width is relative to the number of columns and always has at least small margin between cells
                                )}%`,
                            }}
                        />
                    ))}
                </div>
            ))}
        </>
    );
};
