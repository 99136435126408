import React, { memo } from 'react';

import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useTranslation, Trans } from 'react-i18next';
import { ButtonBase, Link } from '@bb-ui/react-library';
import { useAuth0Context } from '@bb-ui/auth/dist/auth0/Auth0Context';
import { ResponsiveBox } from '../ResponsiveBox/ResponsiveBox';
import { JoinTheCommunityBanner } from './JoinTheCommunityBanner';
import { ActionAreaButtons } from '../ActionAreaButtons/ActionAreaButtons';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { useApplicationTitle } from '../../hooks/useApplicationTitle';
import { CustomCardPrimary } from '../CustomCards/CustomCardPrimary';
import { illuminateColors } from '../../utilities/sharedStyles';
import { OutlineLink } from '../StyledLinks/OutlineLink';
import { PrimaryLink } from '../StyledLinks/PrimaryLink';
import { StyledItem } from '../CustomCards/StyledItem';
import { CustomCardSecondary } from '../CustomCards/CustomCardSecondary';
import { SnowflakeLauncher } from '../SnowflakeLauncher/SnowflakeLauncher';
import { useStyles } from './HomePage.styles';
import { useUser } from '../../hooks/useUser';
import { FeatureName } from '../../featureFlags/Feature.types';
import { useFeatureContext } from '../../contexts/FeatureContext';

export interface IHomePageProps {
    userHasSettingsAccess?: boolean;
    userHasDataQAndA?: boolean;
}

export const HomePage: React.FunctionComponent<IHomePageProps> = ({
    userHasSettingsAccess = false,
    userHasDataQAndA = false,
}) => {
    const classes: any = useStyles();
    const { t } = useTranslation();
    const getHelpLink = useHelpLinks();
    const { isAuthenticated } = useAuth0Context();
    const user = useUser();
    const featureFlags = useFeatureContext();

    useApplicationTitle('Home');

    const isEnhancedUser =
        user?.hasEnhancedCapabilities &&
        featureFlags['bbdata.authoring.preview' as FeatureName];

    const notAuthenticated = (
        <>
            <CustomCardPrimary
                id="what-is-illuminate"
                title={
                    <Typography variant="display2" component="h1">
                        <Trans i18nKey="homePage.cards.unauthenticated.title">
                            See campus-wide data clearly with
                            <span lang="en">Anthology Illuminate</span>
                        </Trans>
                    </Typography>
                }
                imgSrc="/images/HomePage/whatIsIlluminate.svg"
                className={classes.singleRow}
                loadingImageColor={illuminateColors.brandYellowLight}
                cardActions={
                    <div className={classes.links}>
                        <OutlineLink
                            to={getHelpLink('getReportingHelp')}
                            data-testid="home-page-get-access-button"
                            external
                        >
                            {t('homePage.cards.unauthenticated.getAccess')}
                        </OutlineLink>
                        <PrimaryLink
                            to={getHelpLink('aboutIlluminate')}
                            data-testid="more-about-illuminate-button"
                        >
                            { /* prettier-ignore */ }
                            <Trans i18nKey="homePage.cards.unauthenticated.findOutMore">
                                Find out more about <span lang="en">Illuminate</span>
                            </Trans>
                        </PrimaryLink>
                    </div>
                }
            >
                <Typography
                    variant="subtitle1"
                    component="span"
                    data-testid="home-page-unauthenticated-card-text"
                >
                    <Trans i18nKey="homePage.cards.unauthenticated.text">
                        <span lang="en">Illuminate</span> connects your campus
                        data and transforms it into key insights that help you
                        make the best decisions at the right time.
                    </Trans>
                </Typography>
            </CustomCardPrimary>

            <Typography
                component="h2"
                variant="h1"
                className={classes.subtitle}
            >
                {t('homePage.subtitles.standardFeatures')}
            </Typography>
            <CustomCardSecondary
                id="reporting"
                title={
                    <Trans i18nKey="homePage.cards.reportingUnauthenticated.title">
                        <span lang="en">Illuminate</span> Reporting
                    </Trans>
                }
                imgSrc="/images/HomePage/reportingIllustration.svg"
                className={classes.singleRow}
                cardActions={
                    <div className={classes.link}>
                        <OutlineLink
                            to="/reporting"
                            data-testid="go-to-reporting-card-button"
                        >
                            {t('homePage.cards.buttons.goToReporting')}
                        </OutlineLink>
                    </div>
                }
            >
                {t('homePage.cards.reportingUnauthenticated.text')}
                <div className={classes.items}>
                    <StyledItem
                        id="institution-wide-data"
                        title={t(
                            'homePage.cards.reportingUnauthenticated.item1.title',
                        )}
                    >
                        { /* prettier-ignore */ }
                        <Trans i18nKey="homePage.cards.reportingUnauthenticated.item1.text">
                            Take advantage of the convergence of rich data from <span lang="en">Anthology SaaS</span> solutions in a single place.
                        </Trans>
                    </StyledItem>
                    <StyledItem
                        id="role-based-access"
                        title={t(
                            'homePage.cards.reportingUnauthenticated.item2.title',
                        )}
                    >
                        {t(
                            'homePage.cards.reportingUnauthenticated.item2.text',
                        )}
                    </StyledItem>
                </div>
            </CustomCardSecondary>
            <CustomCardSecondary
                id="developer"
                title={
                    <Trans i18nKey="homePage.cards.developerUnauthenticated.title">
                        <span lang="en">Illuminate</span> Developer
                    </Trans>
                }
                imgSrc="/images/HomePage/developerIllustration.svg"
                className={classes.singleRow}
                cardActions={
                    <div className={classes.link}>
                        <OutlineLink
                            to="/developer"
                            data-testid="go-to-developer-card-button"
                        >
                            {t('homePage.cards.buttons.goToDeveloper')}
                        </OutlineLink>
                    </div>
                }
            >
                {t('homePage.cards.developerUnauthenticated.text')}
                <div className={classes.items}>
                    <StyledItem
                        id="unified-data"
                        title={t(
                            'homePage.cards.developerUnauthenticated.item1.title',
                        )}
                    >
                        { /* prettier-ignore */ }
                        <Trans i18nKey="homePage.cards.developerUnauthenticated.item1.text">
                        Provides access to your institution’s data across <span lang="en">Anthology SaaS</span> solutions.
                        </Trans>
                    </StyledItem>
                    <StyledItem
                        id="data-democratization"
                        title={t(
                            'homePage.cards.developerUnauthenticated.item2.title',
                        )}
                    >
                        { /* prettier-ignore */ }
                        <Trans i18nKey="homePage.cards.developerUnauthenticated.item2.text">
                            Each institution owns its data exploration with the use of <span lang="en">SQL</span> and <span lang="en">Snowflake</span> to consult and connect their BI tools to build their own reports.
                        </Trans>
                    </StyledItem>
                </div>
            </CustomCardSecondary>
        </>
    );

    const aunthenticatedWithoutEnhanced = (
        <>
            <CustomCardPrimary
                id="reporting"
                className={classes.primaryCard}
                title={t('homePage.cards.reportingNotEnhanced.title')}
                imgSrc="/images/HomePage/reportingIllustration.svg"
                imagePosition="left"
                svgContainsBitmap={false}
                cardActions={
                    <div className={classes.link}>
                        <OutlineLink
                            to="/reporting"
                            data-testid="go-to-reporting-card-button"
                        >
                            {t('homePage.cards.buttons.goToReporting')}
                        </OutlineLink>
                    </div>
                }
            >
                <Trans i18nKey="homePage.cards.reportingNotEnhanced.text">
                    <span lang="en">Illuminate</span> Reporting provides you a
                    clear understanding of the experience at your institution.
                </Trans>
            </CustomCardPrimary>
            <CustomCardPrimary
                id="developer"
                className={classes.primaryCard}
                title={t('homePage.cards.developerNotEnhanced.title')}
                imgSrc="/images/HomePage/developerIllustration.svg"
                imagePosition="left"
                svgContainsBitmap={false}
                cardActions={
                    <div className={classes.link}>
                        <OutlineLink
                            to="/developer"
                            data-testid="go-to-developer-card-button"
                        >
                            {t('homePage.cards.buttons.goToDeveloper')}
                        </OutlineLink>
                    </div>
                }
            >
                { /* prettier-ignore */}
                <Trans i18nKey="homePage.cards.developerNotEnhanced.text">
                    <span lang="en">Illuminate</span> Developer gives you access
                    to your institution’s activity, usage, and assessment data
                    from across multiple <span lang="en">Anthology SaaS</span> solutions.
                </Trans>
            </CustomCardPrimary>
        </>
    );

    const enhanced = (
        <>
            <CustomCardSecondary
                id="reporting"
                title={t('reporting.title')}
                imgSrc="/images/HomePage/reportingIllustration.svg"
                className={classes.singleRow}
                cardActions={
                    <div className={classes.link}>
                        <OutlineLink
                            to="/reporting"
                            data-testid="go-to-reporting-card-button"
                        >
                            {t('homePage.cards.buttons.goToReporting')}
                        </OutlineLink>
                    </div>
                }
            >
                {t('homePage.cards.reportingEnhanced.text')}
                <ul>
                    {userHasDataQAndA && (
                        <li>
                            <Link
                                href="/reporting/data-q-and-a"
                                data-testid="home-page-data-q-and-a-link"
                                color="secondary"
                                variant="h3"
                            >
                                <span lang="en">Data Q&A</span>
                            </Link>
                        </li>
                    )}
                    {isEnhancedUser && (
                        <li>
                            <Link
                                href="/reporting/custom-reports"
                                data-testid="home-page-custom-reports-link"
                                color="secondary"
                                variant="h3"
                            >
                                {t('authoring.title')}
                            </Link>
                        </li>
                    )}
                </ul>
            </CustomCardSecondary>
            <CustomCardSecondary
                id="developer"
                title={t('developer.title')}
                imgSrc="/images/HomePage/developerIllustration.svg"
                className={classes.singleRow}
                cardActions={
                    <div className={classes.link}>
                        <OutlineLink
                            to="/developer"
                            data-testid="go-to-developer-card-button"
                        >
                            {t('homePage.cards.buttons.goToDeveloper')}
                        </OutlineLink>
                    </div>
                }
            >
                <Trans i18nKey="homePage.cards.developerEnhanced.text">
                    <span lang="en">Illuminate Enhanced</span> capabilities
                    empower BI analysts and data developers to create customized
                    datasets and reports to meet their specific institutional
                    needs.
                </Trans>
                <ul>
                    <li>
                        <SnowflakeLauncher
                            ButtonComponent={ButtonBase}
                            className={classes.launchSnowflake}
                        />
                    </li>
                    <li>
                        <Link
                            href="/dictionary"
                            color="secondary"
                            variant="h3"
                            data-testid="home-page-data-dictionary-link"
                        >
                            {t('dataDictionary.title')}
                        </Link>
                    </li>
                    {userHasSettingsAccess && (
                        <li>
                            <Link
                                href="/settings"
                                color="secondary"
                                variant="h3"
                                data-testid="home-page-settings-link"
                            >
                                {t('settings.title')}
                            </Link>
                        </li>
                    )}
                </ul>
            </CustomCardSecondary>
        </>
    );

    const notEnhanced = (
        <>
            <Typography
                component="h2"
                variant="h1"
                className={classes.subtitle}
                data-testid="home-page-enhanced-subtitle"
            >
                <Trans i18nKey="homePage.subtitles.enhanced">
                    Your data in a whole new light with
                    <span lang="en" style={{ fontWeight: 'bold' }}>
                        Illuminate Enhanced
                    </span>
                </Trans>
            </Typography>
            <CustomCardPrimary
                id="enhanced-teaser"
                title={
                    <Typography variant="h2" className={classes.title}>
                        <Trans i18nKey="homePage.cards.notEnhanced.title">
                            <span lang="en">Illuminate Enhanced</span> gives you
                            the power for data exploration and report creation
                            with:
                        </Trans>
                    </Typography>
                }
                imgSrc="/images/HomePage/illuminateEnhanced.svg"
                className={classes.singleRow}
                loadingImageColor={illuminateColors.brandYellowLight}
                imagePosition="left"
                cardActions={
                    <div className={classes.link}>
                        <OutlineLink
                            to={getHelpLink('exploreEnhanced')}
                            data-testid="home-page-explore-enhanced-button"
                            external
                        >
                            { /* prettier-ignore */}
                            <Trans i18nKey="buttons.exploreEnhanced">
                                Explore <span lang="en">Illuminate Enhanced</span>
                            </Trans>
                        </OutlineLink>
                    </div>
                }
            >
                <div className={classes.listItems}>
                    <StyledItem
                        id="custom-reports"
                        title={t('authoring.title')}
                    >
                        {t('homePage.cards.notEnhanced.customReportsItem.text')}
                    </StyledItem>
                    <StyledItem id="data-q-and-a" title="Data Q&A">
                        {t('homePage.cards.notEnhanced.dataQandAItem.text')}
                    </StyledItem>
                    <StyledItem
                        id="extra-seats"
                        title={t('homePage.cards.notEnhanced.seatsItem.title')}
                    >
                        {t('homePage.cards.notEnhanced.seatsItem.text')}
                    </StyledItem>
                </div>
            </CustomCardPrimary>
        </>
    );

    return (
        <ResponsiveBox className={classes.homePageContainer}>
            <div
                className={classes.gridContainer}
                data-testid="home-page-container"
            >
                {isAuthenticated ? (
                    <>
                        <Typography
                            variant="h1"
                            className={classes.subtitle}
                            data-testid="home-page-welcome-title"
                        >
                            { /* prettier-ignore */}
                            <Trans i18nKey="homePage.subtitles.welcome" >
                                Welcome to <span style={{ fontWeight: 'bold' }}
                                            lang="en"
                                            >
                                                Anthology Illuminate
                                </span>
                            </Trans>
                        </Typography>
                        {isEnhancedUser ? (
                            enhanced
                        ) : (
                            <>
                                {aunthenticatedWithoutEnhanced}
                                {notEnhanced}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {notAuthenticated}
                        {notEnhanced}
                    </>
                )}

                <div className={classes.singleRow}>
                    <JoinTheCommunityBanner />
                </div>
            </div>
            <ActionAreaButtons helpLink={getHelpLink('main')} />
        </ResponsiveBox>
    );
};

export default memo(HomePage);
