// Polyfill needed since we are using typescript and targeting es5 for browsers without native support
// Keep it as a first line please, or polyfill doesn't work
import 'core-js';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
    isIE,
    isOpera,
    isSafari,
    isWindows,
    isYandex,
} from 'react-device-detect';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';

import App from './App';
import ScrollToTop from '../src/components/ScrollToTop/ScrollToTop';
import { BrowserIncompatibilityErrorPage } from '../src/components/ErrorPages/BrowserIncompatibilityErrorPage/BrowserIncompatibilityErrorPage';
import { AppThemeProvider } from '../src/components/AppThemeProvider/AppThemeProvider';

// This fixes an issue in Chrome where the css transitions fire when the page first loads
// See: https://css-tricks.com/transitions-only-after-page-load/
window.addEventListener('load', () => {
    document.body.classList.remove('preload');
});

if (isIE || isOpera || isYandex || (isWindows && isSafari)) {
    // These are the browsers we don't support (Safari doesn't work in combination with Windows)
    ReactDOM.render(
        <Router>
            <AppThemeProvider>
                <BrowserIncompatibilityErrorPage />
            </AppThemeProvider>
        </Router>,
        document.getElementById('app'),
    );
} else {
    ReactDOM.render(
        <Router>
            {/* See https://v5.reactrouter.com/web/guides/scroll-restoration */}
            <ScrollToTop />
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </Router>,
        document.getElementById('app'),
    );
}
