import React from 'react';
import classnames from 'classnames';

import { Card } from '@bb-ui/react-library/dist/components/Card';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import ImageHandler from '../ImageHandler/ImageHandler';
import { ICustomCardProps } from './CustomCards.types';
import { useStyles } from './CustomCardSecondary.styles';

export const CustomCardSecondary: React.FunctionComponent<ICustomCardProps> = ({
    id,
    title,
    imgSrc,
    loadingImageColor,
    className,
    cardActions,
    children,
}) => {
    const classes = useStyles();

    return (
        <Card
            className={classnames(classes.card, className)}
            data-testid={`home-page-${id}-card`}
        >
            <ImageHandler
                imgSrc={imgSrc}
                loadingImageColor={loadingImageColor}
                className={classes.image}
            />
            <div className={classes.contentSection}>
                <Typography
                    variant="h2"
                    className={classes.title}
                    data-testid={`home-page-${id}-card-title`}
                >
                    {title}
                </Typography>
                <div data-testid={`home-page-${id}-card-text`}>{children}</div>
                {cardActions}
            </div>
        </Card>
    );
};
