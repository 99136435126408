import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            card: {
                [theme.breakpoints.only('xs')]: {
                    width: '100%',
                    marginBottom: '2%',
                },
            },
            row1: {
                height: theme.spacing(3),
            },
            row2: {
                height: theme.spacing(5),
                width: '50%',
            },
        }),
    {
        name: 'SkeletonCard-',
    },
);
