import React from 'react';

interface AppLoadingProps {
    loading: boolean;
    setLoading: (loading: boolean) => void;
}
export const AppLoadingContext = React.createContext<AppLoadingProps>({
    loading: true,
    setLoading: () => {},
});

export const useAppLoadingContext = () => React.useContext(AppLoadingContext);
