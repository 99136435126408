import {
    makeStyles,
    createStyles,
} from '@bb-ui/react-library/dist/components/styles';

const styles = () =>
    createStyles({
        pagingControlsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        pagingControlsCurrentPageNumber: {
            display: 'flex',
            alignItems: 'center',
        },
        pagingControlsTextLeft: {
            marginRight: '8px',
        },
        pagingControlsTextRight: {
            marginLeft: '8px',
        },
    });

const options = {
    name: 'PagingControls-',
};

export const useStyles = makeStyles(styles, options);
