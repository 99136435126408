import * as React from 'react';
import { ListItem } from '@bb-ui/react-library/dist/components/ListItem';
import { ListItemText } from '@bb-ui/react-library/dist/components/ListItemText';
import { ListItemIcon } from '@bb-ui/react-library/dist/components/ListItemIcon';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';

import { useStyles as useListNavItemStyles } from './ListNavItem.styles';
import { ListNavVariant } from './ListNav.types';

export interface IListItem {
    name: string;
    key: string;
}
export interface IListNavButtonProps {
    label: string;
    icon?: JSX.Element;
    onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    disableListItem?: boolean;
    listNavVariant: ListNavVariant; // eslint-disable-line react/no-unused-prop-types
    testId?: string;
}

// This comopnent is designed to be used in a `List` component for items that cause
// an action to happen but are not links. For a link that navigates to another route,
// use `ListItemRouteLink`
export const ListNavButton: React.FunctionComponent<
    IListNavButtonProps
> = props => {
    const listNavItemClasses = useListNavItemStyles(props);
    const { label, icon, onClick, disableListItem, testId } = props;

    return (
        <ListItem
            classes={listNavItemClasses}
            button
            disabled={disableListItem}
            onClick={onClick}
            data-testid={testId}
        >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText
                disableTypography
                primary={
                    <Typography variant="subtitle2" component="span">
                        {label}
                    </Typography>
                }
            />
        </ListItem>
    );
};
