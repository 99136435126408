import * as React from 'react';

import { Switch, Route } from 'react-router-dom';
import {
    Auth0Provider,
    useAuth0Context,
} from '@bb-ui/auth/dist/auth0/Auth0Context';
import {
    UASProvider,
    UASProviderProps,
} from '@bb-ui/auth/dist/auth0/UASProvider';

import { useLocalStorage } from 'react-use';
import { useTranslation } from 'react-i18next';

import { MainPage } from '../components/MainPage/MainPage';
import { useAppConfigContext } from '../contexts/AppConfigProvider';
import { IBeamer } from '../types/beamer.types';
import { AppNavigationProvider } from '../contexts/AppNavigationContext';
import { Auth0Callback } from '../components/Auth0Callback/Auth0Callback';
import { SignInCallback } from '../components/SignInCallback/SignInCallback';
import { localStorageUASTenantKey } from '../utilities/constants';
import { useUser } from '../hooks/useUser';

import { FeatureFlagServiceContext } from '../contexts/FeatureFlagServiceContext';
import { TenantSettingsProvider } from '../contexts/TenantSettingsContext';

interface AppWithAuthenticationInnerProps {
    setUasTenant: (tenantId: string) => void;
    uasTenant: string;
}

const AppWithAuthenticationInner: React.FunctionComponent<
    AppWithAuthenticationInnerProps
> = props => {
    const { i18n } = useTranslation();
    const { loading: authLoading } = useAuth0Context();
    const user = useUser();

    const { uas, regions, setRegion } = useAppConfigContext();

    const beamer: IBeamer = (window as any).Beamer;

    React.useEffect(() => {
        if (!authLoading && user) {
            beamer.update({
                user_firstname: user.firstName || user.userId,
                user_lastname: user.lastName,
                user_email: user.personIdentifier,
                // sets the badge to be inline with the What's New text
                right: -5,
                top: 7,
                language: i18n.language.substring(0, 2),
            });
            beamer.init();
        }
    }, [user, authLoading, i18n, beamer]);

    React.useEffect(() => {
        if (user?.isUniversalUser()) {
            const regionName = [...user.region.split('-'), uas!.stage]
                .map(
                    (part: string, index: number) =>
                        index === 0
                            ? part.toLowerCase()
                            : part[0].toUpperCase() +
                              part?.substring(1).toLowerCase(), // eslint-disable-line no-unsafe-optional-chaining
                )
                .join('');

            if (regions.includes(regionName)) {
                setRegion(regionName);
            } else {
                throw Error(
                    `Tenant's region (${regionName}) is not supported!`,
                );
            }
        }
    }, [user, setRegion, regions, uas]);

    if (authLoading) {
        return null;
    }

    return (
        <Switch>
            <Route
                path="/callback"
                render={({ history }) => (
                    <Auth0Callback onRedirect={path => history.replace(path)} />
                )}
            />
            <Route path="/sign-in-callback">
                <SignInCallback
                    uasTenant={props.uasTenant}
                    setUasTenant={props.setUasTenant}
                />
            </Route>
            <Route path="/">
                <FeatureFlagServiceContext>
                    <TenantSettingsProvider>
                        <AppNavigationProvider>
                            <MainPage />
                        </AppNavigationProvider>
                    </TenantSettingsProvider>
                </FeatureFlagServiceContext>
            </Route>
        </Switch>
    );
};

export const AppWithAuthentication: React.FunctionComponent = () => {
    const { auth0: auth0Config, uas } = useAppConfigContext();
    const [uasTenant, setUasTenant] = useLocalStorage<string>(
        localStorageUASTenantKey,
        '',
        {
            raw: true,
        },
    );

    if (!auth0Config) {
        throw new Error('No auth config found.');
    }

    if (uasTenant && !uas?.config) {
        throw new Error('No uas config found.');
    }

    if (uasTenant && uas?.config) {
        const uasConfigForTenant: UASProviderProps = {
            ...uas.config,
            audienceNamespace: `${uas.bbNamespace}/tenants`,
            redirect_uri: `${window.location.origin}/callback`,
            tenantUriComponent: uasTenant,
        };

        return (
            <UASProvider {...uasConfigForTenant}>
                <AppWithAuthenticationInner
                    setUasTenant={setUasTenant}
                    uasTenant={uasTenant}
                />
            </UASProvider>
        );
    }

    if (auth0Config && auth0Config.redirect_uri?.startsWith('/')) {
        auth0Config.redirect_uri =
            window.location.origin + auth0Config.redirect_uri;
    }

    return (
        <Auth0Provider {...auth0Config}>
            <AppWithAuthenticationInner
                setUasTenant={setUasTenant}
                uasTenant={uasTenant}
            />
        </Auth0Provider>
    );
};
