import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { ChevronLeft } from '@bb-ui/icons/dist/small/ChevronLeft';
import { ChevronRight } from '@bb-ui/icons/dist/small/ChevronRight';

import { Tooltip } from '@bb-ui/react-library/dist/components/Tooltip';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { MenuItem } from '@bb-ui/react-library/dist/components/MenuItem';

import { useStyles } from './PagingControls.styles';
import { ScreenReaderOnly } from '../ScreenReaderOnly/ScreenReaderOnly';

export interface IPagingControlsProps {
    pageNumber: number;
    pageNumberOptions: number[];
    updateCurrentPage: (pageNumber: number) => void;
}

export const PagingControls: React.FunctionComponent<IPagingControlsProps> = ({
    pageNumber = 1,
    pageNumberOptions,
    updateCurrentPage,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const onPageNumberChanged = (index: number | undefined) => {
        if (index !== undefined) {
            updateCurrentPage(index);
        } else {
            throw Error('index for page number to change to is undefined');
        }
    };

    // The page number is one greater than the key. For example, page 1 is at key 0.
    const goToPrevPage = () => {
        updateCurrentPage(pageNumber - 1);
    };
    const goToNextPage = () => {
        updateCurrentPage(pageNumber + 1);
    };
    return (
        <div className={classes.pagingControlsContainer}>
            <div className={classes.pagingControlsCurrentPageNumber}>
                {/* Without the div element below the Tooltip component, the following warning is in the console:

                        Warning: Material-UI: you are providing a disabled `button` child to the Tooltip component.
                        A disabled element does not fire events.
                        Tooltip needs to listen to the child element's events to display the title.

                        Place a `div` container on top of the element.

                     */}
                <ScreenReaderOnly type="span" aria-live="polite">
                    {t('dataDictionary.currentPage', {
                        currentPage: pageNumber,
                        pagesTotal:
                            pageNumberOptions[pageNumberOptions.length - 1],
                    })}
                </ScreenReaderOnly>
                <Tooltip
                    title={t<string>('dataDictionary.previousPageOfEntries')}
                >
                    <div>
                        <IconButton
                            onClick={goToPrevPage}
                            disabled={pageNumber === 1}
                            aria-label={t(
                                'dataDictionary.previousPageOfEntries',
                            )}
                            data-testid="previous-page-of-entries-button"
                        >
                            <ChevronLeft />
                        </IconButton>
                    </div>
                </Tooltip>
                <Typography
                    component="label"
                    className={classes.pagingControlsTextLeft}
                >
                    {t('dataDictionary.page')}
                </Typography>
                <div>
                    <TextField
                        fullWidth
                        select
                        // Not adding InputLabelProps and InputProps because without a label, the id doesn't get set on the label
                        id="paging-controls-select"
                        data-testid="paging-controls-select"
                        value={pageNumber}
                        onChange={event =>
                            onPageNumberChanged(Number(event.target.value))
                        }
                    >
                        {pageNumberOptions.map(option => (
                            <MenuItem key={option} value={option} button>
                                {t('general.number', { value: option })}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>

                <Typography
                    className={classes.pagingControlsTextRight}
                    component="div"
                    data-testid="paging-page-count-text"
                >{`${t('dataDictionary.of')} ${t('general.number', {
                    value: pageNumberOptions.length,
                })}`}</Typography>
                <Tooltip title={t<string>('dataDictionary.nextPageOfEntries')}>
                    <div>
                        <IconButton
                            onClick={goToNextPage}
                            disabled={pageNumber === pageNumberOptions.length}
                            aria-label={t('dataDictionary.nextPageOfEntries')}
                            data-testid="next-page-of-entries-button"
                        >
                            <ChevronRight />
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};
