import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';
import { illuminateColors, zIndex } from '../../utilities/sharedStyles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            actionAreaButtonsContainer: {
                position: 'fixed',
                bottom: theme.spacing(3.5),
                right: theme.spacing(3),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                [theme.breakpoints.up('sm')]: {
                    right: theme.spacing(4),
                },
                zIndex: zIndex.helpButton,
            },
            floatingButton: {
                width: '40px',
                height: '40px',
                borderRadius: '20px',
                border: `2px solid ${theme.palette.common.white}`,
                backgroundColor: theme.palette.background.b9,
                color: theme.palette.common.white,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
                outline: 'none',
                marginBottom: theme.spacing(1),
                '&:focus': {
                    border: `1px solid ${theme.palette.focus.main}`,
                    boxShadow: `0px 0px 0px 1px ${theme.palette.focus.light}`,
                },
                '&:hover': {
                    color: illuminateColors.brandYellow,
                },
            },
        }),
    {
        name: 'action-area-buttons-',
    },
);
