import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        homePageContainer: {
            paddingTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(3),
            },
        },
        gridContainer: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: `${theme.spacing(3)}px`,
            [theme.breakpoints.up('md')]: {
                gridTemplateColumns: '1fr 1fr',
                rowGap: `${theme.spacing(5)}px`,
                columnGap: `${theme.spacing(4)}px`,
            },
        },
        singleRow: {
            gridColumn: '1 / -1',
        },
        links: {
            display: 'flex',
            gap: theme.spacing(2),
        },
        link: {
            display: 'flex',
        },
        items: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
            paddingTop: theme.spacing(3),
            '& > *': {
                flex: 1,
            },
        },
        subtitle: {
            gridColumn: '1 / -1',
            marginBottom: -theme.spacing(2),
        },
        launchSnowflake: {
            fontWeight: theme.typography.fontWeightSemiBold,
            fontSize: theme.typography.fontSizeMedium,
            textDecoration: 'underline',
            fontFamily: theme.typography.fontFamily,
        },
        listItems: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
        },
        primaryCard: {
            [theme.breakpoints.up('lg')]: {
                height: '360px',
            },
        },
    });

const options = {
    name: 'HomePage-',
};

export const useStyles = makeStyles(styles, options);
