import { History, Location } from 'history';
import {
    ILocationState,
    ITablePageSettings,
    IDataDictionaryTableSortSettings,
} from '../types/appUITypes';

export const getPageNumberOptions = (
    itemsPerPage: number,
    numOfRecords: number,
) => {
    const numberOfPages = Math.ceil(numOfRecords / itemsPerPage) || 1;

    // The following code is a JS trick used to create an array with the numbers from 1 to numberOfPages
    return Array.from(Array(numberOfPages).keys()).map((x: number) => x + 1);
};

/*
 *  Replace the current location in the history with a new location using the DataDictionaryTableSortSettings and DataDictionaryTablePageSettings
 *
 *  Example: updateLocation({columnToSortBy: columnName, sortDirection: 'desc'})
 *
 *  Make sure to pass in all the values to change at one time, rather than calling this multiple times in succession.
 */
export const updateLocation = (
    sortOptions: IDataDictionaryTableSortSettings | ITablePageSettings,
    history: History<unknown>,
    location: Location<ILocationState>,
) =>
    history.replace({
        ...location,
        state: { ...location.state, ...sortOptions },
    });

export const getPageNumber = (location: Location<ILocationState>) => {
    if (location.state) {
        const { page } = location.state;
        return page || 1;
    }
    return 1;
};
