import { useEffect, useState } from 'react';

import { useAuth0Context } from '@bb-ui/auth/dist/auth0/Auth0Context';

export const LOGIN_DOES_NOT_EXIST_ERROR = new Error(
    '`login` function does not exist',
);

export interface ILogin {
    isReady: boolean;
    login: () => void;
}
export function useLogin() {
    const { login } = useAuth0Context();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (login) {
            setReady(true);
        }
    }, [login]);

    return {
        isReady: ready,
        login: () => {
            if (!login) {
                throw LOGIN_DOES_NOT_EXIST_ERROR;
            } else {
                login({
                    appState: { returnPath: '/' },
                });
            }
        },
    };
}
