import * as React from 'react';

import { Home, Lock, Report, Visualization } from '@bb-ui/icons/dist/medium';
import {
    BackArrow,
    Course,
    FolderClosed,
    Grade,
    GraduateCap,
    HandRaise,
    JoinSession,
    LeaveSession,
    Lightbulb,
    Settings,
} from '@bb-ui/icons/dist/small';
import { BbThemeProvider } from '@bb-ui/react-library/dist/components/BbThemeProvider';
import { Drawer } from '@bb-ui/react-library/dist/components/Drawer';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { List } from '@bb-ui/react-library/dist/components/List';
import { ListItem } from '@bb-ui/react-library/dist/components/ListItem';
import {
    useMediaQuery,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouteLink } from 'react-router-dom';
import { Key } from 'ts-key-enum';

import { AnthologyIlluminateLogoTwoLines } from '../../../app/assets/images/SVGs/AnthologyIlluminateLogoTwoLines';
import { useAppNavigationContext } from '../../contexts/AppNavigationContext';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { ListItemRouteLink } from '../ListNav/ListItemRouteLink';
import { ListNavButton } from '../ListNav/ListNavButton';
import { useStyles } from './SideBar.styles';
import { BadgeType } from '../ListNav/ListNav.types';
import { useUser } from '../../hooks/useUser';

export interface ISideBarProps {
    isAuthenticated?: boolean;
    userHasReportingAccess?: boolean;
    userHasQSSeat?: boolean;
    userHasSettingsAccess?: boolean;
    userHasDataQAndA?: boolean;
    logout: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    signin: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

export const SideBar: React.FC<ISideBarProps> = ({
    isAuthenticated,
    userHasReportingAccess,
    userHasQSSeat,
    userHasSettingsAccess,
    userHasDataQAndA,
    logout,
    signin,
}) => {
    const homePageLink = '/';
    const { t } = useTranslation();
    const classes = useStyles();
    const getHelpLink = useHelpLinks();
    const user = useUser();

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));

    const { openNavDrawer, closeGlobalNavDrawer, openSecondaryNavDrawer } =
        useAppNavigationContext();

    const openGlobalNavDrawerButtonRef = React.useRef<HTMLButtonElement>(null);
    const closeGlobalNavDrawerButtonRef = React.useRef<HTMLButtonElement>(null);

    const globalNavOpen = openNavDrawer === 'global';
    const closeGlobalNavDrawerInSmDown = () => {
        if (smDown) {
            closeGlobalNavDrawer();
        }
    };

    const onCloseGlobalNavDrawerButtonKeyDown: (
        event: React.KeyboardEvent<HTMLButtonElement>,
    ) => void = e => {
        if (e.key === ' ' || e.key === Key.Enter) {
            setTimeout(() => {
                if (openGlobalNavDrawerButtonRef.current) {
                    openGlobalNavDrawerButtonRef.current.focus();
                }
            }, 200);
        }
    };

    const showBadgeBasedOnReleaseDate = (
        expectedReleaseDate: string | number | Date,
    ) => {
        const currentDate = new Date();
        const releaseDate = new Date(expectedReleaseDate);
        const twoMonthsAhead = new Date();
        twoMonthsAhead.setMonth(currentDate.getMonth() + 2);

        // Compare only the year, month, and day parts
        const isAfterReleaseDate = currentDate >= releaseDate;
        const isWithinTwoMonths = releaseDate <= twoMonthsAhead;

        if (isAfterReleaseDate && isWithinTwoMonths) {
            return BadgeType.NEW;
        }
        return undefined;
    };

    return (
        <BbThemeProvider theme="dark">
            <Drawer
                variant={mdUp ? 'persistent' : 'temporary'}
                open={globalNavOpen}
                onClose={closeGlobalNavDrawer}
                data-testid="main-menu-drawer"
                PaperProps={{
                    className: classes.globalNavDrawerPaper,
                }}
            >
                <nav
                    aria-label={t('general.mainMenu')}
                    className={classes.globalNavFlexColumnWrapper}
                >
                    {/* Top section */}
                    <div className={classes.globalNavTopSection}>
                        <IconButton
                            className={classes.closeGlobalNavDrawerButton}
                            onClick={closeGlobalNavDrawer}
                            // TODO this doesn't really function anymore nor makes sense to me.
                            // Waiting for UXD to confirm maybe other keyboard action.
                            onKeyDown={onCloseGlobalNavDrawerButtonKeyDown}
                            aria-label={t('general.closeMainMenu')}
                            aria-expanded={globalNavOpen}
                            ref={closeGlobalNavDrawerButtonRef}
                            data-testid="sidebar-close-main-menu"
                            color="inherit"
                        >
                            <BackArrow />
                        </IconButton>
                        <div className={classes.logoImageLinkContainer}>
                            <RouteLink
                                to={homePageLink}
                                className={classes.logoImageLink}
                                onClick={closeGlobalNavDrawerInSmDown}
                                aria-label={t('general.homePageLinkAriaLabel')}
                                data-testid="sidebar-logo-link"
                            >
                                <AnthologyIlluminateLogoTwoLines />
                            </RouteLink>
                        </div>
                        <div className={classes.globalNavSection}>
                            <List>
                                <ListItemRouteLink
                                    key={t('general.home').toString()}
                                    path={homePageLink}
                                    exact
                                    label={t('general.home').toString()}
                                    icon={<Home />}
                                    onClick={closeGlobalNavDrawerInSmDown}
                                    listNavVariant="global"
                                    testId="sidebar-home-button"
                                />

                                <ListItemRouteLink
                                    key="reporting"
                                    path="/reporting"
                                    exact
                                    label={t('reporting.title')}
                                    icon={<Report />}
                                    onClick={closeGlobalNavDrawerInSmDown}
                                    listNavVariant="global"
                                />

                                {isAuthenticated && (
                                    <>
                                        <List className={classes.subMenu}>
                                            {userHasDataQAndA && (
                                                <ListItemRouteLink
                                                    key="data-q-and-a"
                                                    path="/reporting/data-q-and-a"
                                                    label="Data Q&A"
                                                    icon={<HandRaise />}
                                                    badgeType={showBadgeBasedOnReleaseDate(
                                                        new Date(2024, 6, 15),
                                                    )}
                                                    onClick={
                                                        closeGlobalNavDrawerInSmDown
                                                    }
                                                    listNavVariant="global"
                                                    keepEnglishLabel
                                                />
                                            )}
                                            {user?.hasEnhancedCapabilities() && (
                                                <ListItemRouteLink
                                                    key="custom-reports"
                                                    path="/reporting/custom-reports"
                                                    label={t('authoring.title')}
                                                    icon={<FolderClosed />}
                                                    onClick={
                                                        closeGlobalNavDrawerInSmDown
                                                    }
                                                    listNavVariant="global"
                                                />
                                            )}

                                            {userHasReportingAccess &&
                                                userHasQSSeat && (
                                                    <List
                                                        className={
                                                            classes.subMenu
                                                        }
                                                    >
                                                        <ListItemRouteLink
                                                            key="learning"
                                                            path="/reporting/learning"
                                                            label={t(
                                                                'reporting.cards.learning.label',
                                                            )}
                                                            icon={
                                                                <GraduateCap />
                                                            }
                                                            onClick={
                                                                closeGlobalNavDrawerInSmDown
                                                            }
                                                            listNavVariant="global"
                                                        />
                                                        <ListItemRouteLink
                                                            key="teaching"
                                                            path="/reporting/teaching"
                                                            label={t(
                                                                'reporting.cards.teaching.label',
                                                            )}
                                                            icon={<Lightbulb />}
                                                            onClick={
                                                                closeGlobalNavDrawerInSmDown
                                                            }
                                                            listNavVariant="global"
                                                        />
                                                        <ListItemRouteLink
                                                            key="leading"
                                                            path="/reporting/leading"
                                                            label={t(
                                                                'reporting.cards.leading.label',
                                                            )}
                                                            icon={<Grade />}
                                                            onClick={
                                                                closeGlobalNavDrawerInSmDown
                                                            }
                                                            listNavVariant="global"
                                                        />
                                                    </List>
                                                )}
                                        </List>
                                    </>
                                )}
                                <ListItemRouteLink
                                    key="developer"
                                    path="/developer"
                                    label={t('developer.title')}
                                    icon={<Visualization />}
                                    onClick={closeGlobalNavDrawerInSmDown}
                                    listNavVariant="global"
                                />
                                <List className={classes.subMenu}>
                                    <ListItemRouteLink
                                        key={t(
                                            'dataDictionary.title',
                                        ).toString()}
                                        path="/dictionary"
                                        label={t('dataDictionary.title')}
                                        icon={<Course />}
                                        onClick={openSecondaryNavDrawer}
                                        listNavVariant="global"
                                    />

                                    {isAuthenticated &&
                                        userHasSettingsAccess && (
                                            <ListItemRouteLink
                                                key={t(
                                                    'settings.label',
                                                ).toString()}
                                                path="/settings"
                                                label={t('settings.label')}
                                                icon={<Settings />}
                                                onClick={
                                                    closeGlobalNavDrawerInSmDown
                                                }
                                                listNavVariant="global"
                                            />
                                        )}
                                </List>

                                <ListItemRouteLink
                                    key={t(
                                        'privacyAndSecurity.title',
                                    ).toString()}
                                    path="/privacy"
                                    label={t('privacyAndSecurity.title')}
                                    icon={<Lock />}
                                    onClick={closeGlobalNavDrawerInSmDown}
                                    listNavVariant="global"
                                />

                                <ListItem className={classes.helpLink}>
                                    <a
                                        href={getHelpLink('main')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-testid="sidebar-help-link"
                                    >
                                        {t('needHelp.title')}
                                    </a>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                    {/* Bottom section */}
                    <div className={classes.globalNavSection}>
                        <List>
                            {isAuthenticated ? (
                                <ListNavButton
                                    label={t('auth.logout')}
                                    icon={<LeaveSession />}
                                    onClick={logout}
                                    listNavVariant="global"
                                    testId="sidebar-sign-out-link-in-drawer-nav"
                                />
                            ) : (
                                <ListNavButton
                                    label={t('auth.login')}
                                    icon={<JoinSession />}
                                    onClick={signin}
                                    listNavVariant="global"
                                    testId="sidebar-sign-in-button-in-drawer-nav"
                                />
                            )}
                        </List>
                    </div>
                </nav>
            </Drawer>
        </BbThemeProvider>
    );
};
