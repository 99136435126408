export interface IQuickSightConsoleProps {
    embedData: { url: string };
    isError: boolean;
}

export enum EmbeddedConsoleState {
    loaded,
    notLoaded,
    failedToLoad,
}
