import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useMountedState } from 'react-use';
import { useAppThemeContext } from '../../contexts/AppThemeContext';

interface I18nProps {
    userSelectedLang?: string;
    switchLanguage: (loading: string) => void;
}
export const I18nContext = React.createContext<I18nProps>({
    switchLanguage: () => {},
});

export const useI18nContext = () => React.useContext(I18nContext);

export const I18nProvider: React.FunctionComponent = ({ children }) => {
    const { i18n } = useTranslation();
    const isMounted = useMountedState();

    const [userSelectedLang, setUserSelectedLang] = React.useState<string>();
    const [loadedUSBundle, setLoadedUSBundle] = React.useState<string>();
    const [error, setError] = React.useState<Error>();

    const { setIsRtl } = useAppThemeContext();

    const switchLanguage = (language: string) => {
        window.localStorage.setItem('selectedLocale', language);
        setUserSelectedLang(language);
    };

    React.useEffect(() => {
        const hasResourceBundle = (language: string): boolean => {
            const defaultNs = i18n.options.defaultNS ?? 'translations';
            if (i18n.hasResourceBundle(language, defaultNs)) return true;

            if (
                language.includes('-') &&
                i18n.hasResourceBundle(language.split('-')[0], defaultNs)
            )
                return true;

            if (!i18n.options.fallbackLng) return false;

            const fallBacks = i18n.options.fallbackLng as string[];
            return fallBacks.some(lng =>
                i18n.hasResourceBundle(lng, defaultNs),
            );
        };

        const throwWhenTranslationsNotLoaded = (language: string) => {
            if (!hasResourceBundle(language)) {
                throw new Error(
                    `Unable to load application translation file (${language}).`,
                );
            }
        };

        const isUSBundle = (language?: string) =>
            language?.startsWith('en-US-');

        const isUSBundleLoaded = () =>
            i18n.hasResourceBundle('en-US', 'translation');

        // i18n re-init with user seleced language
        if (userSelectedLang) {
            if (userSelectedLang !== i18n.language) {
                i18n.init()
                    .then(() => {
                        throwWhenTranslationsNotLoaded(i18n.language);

                        // i18n does not allow to load a different JSON for the same locale (en-US)
                        // therefore we use a workaround (page reload) for now
                        // we may consider to use namespaces for k-12 and pro in one en-US bundle in the future
                        if (isUSBundle(userSelectedLang)) {
                            if (isUSBundleLoaded()) {
                                if (loadedUSBundle !== userSelectedLang) {
                                    window.location.reload();
                                }
                            } else {
                                setLoadedUSBundle(userSelectedLang);
                            }
                        }
                    })
                    .catch(error => {
                        if (isMounted()) {
                            setError(error);
                        }
                    });
            }
        } else {
            // first i18n init
            throwWhenTranslationsNotLoaded(i18n.language);
            const selectedLanguage =
                window.localStorage.getItem('selectedLocale') ?? i18n.language;
            setUserSelectedLang(selectedLanguage);
            if (isUSBundle(selectedLanguage)) {
                setLoadedUSBundle(selectedLanguage);
            }
        }
    }, [i18n, userSelectedLang, isMounted, loadedUSBundle]);

    if (error) {
        throw error;
    }

    React.useEffect(() => {
        i18n.on('languageChanged', () => {
            setIsRtl(i18n.dir() === 'rtl');
        });
        setIsRtl(i18n.dir() === 'rtl');
    }, [i18n, setIsRtl]);

    return (
        <I18nContext.Provider value={{ userSelectedLang, switchLanguage }}>
            <Helmet htmlAttributes={{ lang: i18n.language }} />
            {children}
        </I18nContext.Provider>
    );
};
