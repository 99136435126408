import * as React from 'react';

import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Link } from 'react-router-dom';
import { ThemeStyle } from '@bb-ui/react-library/dist/components/Typography/Typography';
import { useStyles } from './OutlineLink.styles';

export interface IOutlineLinkProps {
    to: string;
    // External links are links going to external pages. If the link is external, it will open in a new tab
    external?: boolean;
    theme?: 'light' | 'dark';
    typographyVariant?: ThemeStyle;
}

// This component is used for Links which should look like a <OutlineButton />
export const OutlineLink: React.FunctionComponent<IOutlineLinkProps> = ({
    to,
    external = false,
    theme = 'light',
    typographyVariant = 'h4',
    children: label,
    ...props
}) => {
    const classes = useStyles();

    const outlineLinkClass =
        theme === 'light'
            ? classes.outlineLinkLightTheme
            : classes.outlineLinkDarkTheme;

    if (external) {
        return (
            <a
                href={to}
                className={`${outlineLinkClass} ${classes.baseOutlineLink}`}
                target="_blank"
                rel="noopener noreferrer"
                {...props}
            >
                <Typography
                    component="span"
                    variant={typographyVariant}
                    className={classes.label}
                >
                    {label}
                </Typography>
            </a>
        );
    }
    return (
        <Link
            className={`${outlineLinkClass} ${classes.baseOutlineLink}`}
            to={to}
            {...props}
        >
            <Typography component="span" variant="h4" className={classes.label}>
                {label}
            </Typography>
        </Link>
    );
};
