import * as React from 'react';
import classnames from 'classnames';
import { Paper } from '@bb-ui/react-library/dist/components/Paper';
import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export interface IFrameProps {
    className?: string;
    collapseTop?: boolean; // eslint-disable-line react/no-unused-prop-types
    square?: boolean; // eslint-disable-line react/no-unused-prop-types
}

const styles = (theme: Theme) =>
    createStyles({
        frame: (props: IFrameProps) => ({
            borderRadius: props.square ? 0 : theme.spacing(0.5),
            border: `1px solid ${theme.palette.background.b4}`,
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
            padding: theme.spacing(2),
            paddingTop: props.collapseTop ? 0 : theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(4),
                paddingTop: props.collapseTop ? 0 : theme.spacing(4),
            },
        }),
    });

const options = {
    name: 'Frame-',
};
const useStyles = makeStyles(styles, options);

export const Frame: React.FunctionComponent<IFrameProps> = props => {
    const { className, children } = props;
    const classes = useStyles(props);

    return (
        <Paper className={classnames(classes.frame, className)}>
            {children}
        </Paper>
    );
};
