import React, { FC } from 'react';
import { Typography } from '@bb-ui/react-library';
import { CheckCircle } from '@bb-ui/icons/dist/medium';
import { useStyles } from './StyledItem.styles';

interface IStyledItemProps {
    title: string;
    id: string;
}

export const StyledItem: FC<IStyledItemProps> = ({ title, id, children }) => {
    const classes = useStyles();
    return (
        <div className={classes.content} data-testid={`${id}-list-item`}>
            <CheckCircle />
            <div className={classes.section}>
                <Typography component="h3" variant="h4">
                    {title}
                </Typography>
                <Typography variant="body1">{children}</Typography>
            </div>
        </div>
    );
};
