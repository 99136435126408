import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { BbThemeProvider } from '@bb-ui/react-library/dist/components/BbThemeProvider';
import { Discussion } from '@bb-ui/icons/dist/large/Discussion';

import { Box } from '@bb-ui/react-library/dist/components/Box';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useStyles } from './JoinTheCommunityBanner.styles';
import { OutlineLink } from '../StyledLinks/OutlineLink';

export const JoinTheCommunityBanner: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <BbThemeProvider theme="dark">
            <Box
                width="100%"
                className={classes.joinTheCommunityContainer}
                data-testid="join-the-community-banner"
            >
                <div className={classes.contentSection}>
                    <div
                        className={classes.titleSection}
                        data-testid="join-the-community-banner-title"
                    >
                        <Discussion
                            aria-hidden="true"
                            className={classes.discussionIcon}
                        />
                        <Typography
                            className={classes.titleText}
                            component="h2"
                        >
                            <Trans i18nKey="homePage.communityIlluminate.title">
                                Join the <span lang="en">Illuminate</span>{' '}
                                community
                            </Trans>
                        </Typography>
                    </div>
                    <Typography className={classes.normalText}>
                        {t('homePage.communityIlluminate.text')}
                    </Typography>
                    <div className={classes.linkContainer}>
                        <OutlineLink
                            to="https://community.anthology.com/groups/home/105"
                            data-testid="home-page-community-discussion-board-link"
                            theme="dark"
                            external
                        >
                            <Trans i18nKey="homePage.communityIlluminate.discussionBoardLinkText">
                                Join <span lang="en">Illuminate</span>{' '}
                                Discussion Board
                            </Trans>
                        </OutlineLink>
                        <OutlineLink
                            to="https://community.anthology.com/groups/home/127"
                            data-testid="home-page-community-user-group-link"
                            theme="dark"
                            external
                        >
                            {t(
                                'homePage.communityIlluminate.userGroupLinkText',
                            )}
                        </OutlineLink>
                    </div>
                </div>
            </Box>
        </BbThemeProvider>
    );
};

export default JoinTheCommunityBanner;
