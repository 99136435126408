import * as React from 'react';
import { SvgIcon } from '@bb-ui/react-library/dist/components/SvgIcon';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = () =>
    createStyles({
        logo: {
            width: '285px',
            height: '34px',
        },
    });

const options = {
    name: 'AnthologyIlluminateLogo-',
};

export const useStyles = makeStyles(styles, options);

export const AnthologyIlluminateLogo: React.FunctionComponent = () => {
    const classes = useStyles();
    const theme = useTheme();
    const color = theme.palette.text.primary;

    return (
        <SvgIcon
            viewBox="0 0 285 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="presentation"
            className={classes.logo}
        >
            <g>
                <path
                    d="M10.0973 -0.000244141H6.47363V28.4581H10.0973V-0.000244141Z"
                    fill="#F7934E"
                />
                <path
                    d="M3.62365 8.08765H0V28.4609H3.62365V8.08765Z"
                    fill="#FDBD3A"
                />
                <path
                    d="M223.227 7.60376H219.185C219.137 7.60376 219.097 7.6436 219.097 7.69198V11.2151C219.097 11.2635 219.137 11.3033 219.185 11.3033H223.227C223.276 11.3033 223.315 11.2635 223.315 11.2151V7.69198C223.315 7.6436 223.276 7.60376 223.227 7.60376Z"
                    fill={color}
                />
                <path
                    d="M152.438 7.92253H156.13C156.179 7.92253 156.218 7.96202 156.218 8.01075V28.3698C156.218 28.4185 156.179 28.458 156.13 28.458H152.438C152.389 28.458 152.35 28.4185 152.35 28.3698V8.01075C152.35 7.96202 152.389 7.92253 152.438 7.92253Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M31.5861 23.2302L33.8388 28.458H36.2308L27.2826 8.09323H25.2319L16.2837 28.458H18.7099L20.9626 23.2302H31.5861ZM30.7499 21.2666H21.8017L26.2758 10.8395L30.7499 21.2666Z"
                    fill={color}
                />
                <path
                    d="M50.1793 28.458V19.6217C50.1793 15.9051 48.4955 14.0468 45.1278 14.0468C44.0669 14.0468 43.1255 14.2716 42.2978 14.7241C41.4701 15.1766 40.8244 15.8368 40.3636 16.7019V14.3911H38.054V28.458H40.3921V20.3986C40.3921 19.0497 40.7761 17.9825 41.5469 17.1914C42.3177 16.4031 43.3473 16.0075 44.6358 16.0075C45.7536 16.0075 46.5671 16.3063 47.0762 16.904C47.5853 17.5016 47.8413 18.4549 47.8413 19.764V28.458H50.1793Z"
                    fill={color}
                />
                <path
                    d="M61.3234 26.6965L61.2067 28.5462C60.763 28.6231 60.2823 28.6629 59.7618 28.6629C58.0666 28.6629 56.7924 28.219 55.9362 27.3339C55.0801 26.4489 54.6506 25.2166 54.6506 23.6372V16.2437H51.9087V14.3939H54.6506V10.1195H56.9886V14.3939H60.9735V16.2437H56.9886V23.4664C56.9886 25.6805 58.0097 26.7875 60.0491 26.7875C60.4729 26.7875 60.8967 26.7591 61.3205 26.7021L61.3234 26.6965Z"
                    fill={color}
                />
                <path
                    d="M76.2475 28.458V19.6217C76.2475 15.905 74.5636 14.0467 71.196 14.0467C70.135 14.0467 69.1993 14.2658 68.3801 14.7098C67.5609 15.1537 66.921 15.7969 66.4602 16.645V8.09608H64.1222V28.458H66.4602V20.3986C66.4602 19.0497 66.8442 17.9825 67.615 17.1914C68.3858 16.4031 69.4154 16.0075 70.7039 16.0075C71.8217 16.0075 72.6352 16.3063 73.1443 16.9039C73.6535 17.5015 73.9094 18.4549 73.9094 19.764V28.458H76.2475Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M81.6317 27.7665C80.622 27.1689 79.8427 26.318 79.2937 25.2109C78.7448 24.1039 78.4717 22.8176 78.4717 21.3548C78.4717 19.8921 78.7448 18.6058 79.2937 17.4987C79.8427 16.3917 80.622 15.5408 81.6317 14.9432C82.6415 14.3456 83.8105 14.0468 85.1388 14.0468C86.4671 14.0468 87.6418 14.3456 88.66 14.9432C89.6811 15.5408 90.4633 16.3917 91.0123 17.4987C91.5612 18.6058 91.8343 19.8921 91.8343 21.3548C91.8343 22.8176 91.5612 24.1039 91.0123 25.2109C90.4633 26.318 89.6783 27.1717 88.66 27.7665C87.6389 28.3641 86.4671 28.6629 85.1388 28.6629C83.8105 28.6629 82.6415 28.3641 81.6317 27.7665ZM88.3301 25.3532C89.0696 24.4397 89.4422 23.105 89.4422 21.352C89.4422 19.599 89.0668 18.3155 88.3159 17.3792C87.565 16.4458 86.5069 15.9791 85.1416 15.9791C83.7764 15.9791 82.7154 16.4458 81.9645 17.3792C81.2136 18.3127 80.8382 19.636 80.8382 21.352C80.8382 23.068 81.2079 24.4141 81.9503 25.339C82.6898 26.2639 83.7536 26.7249 85.1416 26.7249C86.5296 26.7249 87.5906 26.2667 88.3329 25.3532H88.3301Z"
                    fill={color}
                />
                <path
                    d="M94.2633 8.09608V28.4608H96.6014V8.09608H94.2633Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M102.216 27.7665C101.206 27.1689 100.427 26.318 99.878 25.2109C99.329 24.1039 99.056 22.8176 99.056 21.3548C99.056 19.8921 99.329 18.6058 99.878 17.4987C100.427 16.3917 101.206 15.5408 102.216 14.9432C103.226 14.3456 104.395 14.0468 105.723 14.0468C107.051 14.0468 108.226 14.3456 109.244 14.9432C110.265 15.5408 111.048 16.3917 111.597 17.4987C112.146 18.6058 112.419 19.8921 112.419 21.3548C112.419 22.8176 112.146 24.1039 111.597 25.2109C111.048 26.318 110.263 27.1717 109.244 27.7665C108.223 28.3641 107.051 28.6629 105.723 28.6629C104.395 28.6629 103.226 28.3641 102.216 27.7665ZM108.914 25.3532C109.654 24.4397 110.026 23.105 110.026 21.352C110.026 19.599 109.651 18.3155 108.9 17.3792C108.149 16.4458 107.091 15.9791 105.726 15.9791C104.361 15.9791 103.3 16.4458 102.549 17.3792C101.798 18.3127 101.422 19.636 101.422 21.352C101.422 23.068 101.792 24.4141 102.535 25.339C103.274 26.2639 104.338 26.7249 105.726 26.7249C107.114 26.7249 108.175 26.2667 108.917 25.3532H108.914Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M127.866 27.5047V14.3939H125.528V16.8214C125.104 15.9364 124.478 15.2505 123.651 14.7696C122.823 14.2887 121.85 14.0468 120.735 14.0468C119.504 14.0468 118.412 14.3342 117.459 14.9119C116.506 15.4896 115.763 16.3092 115.234 17.3678C114.705 18.4265 114.441 19.6502 114.441 21.0361C114.441 22.422 114.705 23.6457 115.234 24.7044C115.763 25.763 116.506 26.5826 117.459 27.1603C118.412 27.738 119.504 28.0255 120.735 28.0255C121.87 28.0255 122.854 27.7836 123.693 27.3026C124.532 26.8217 125.153 26.1273 125.556 25.2223V27.7067C125.556 29.0927 125.212 30.1456 124.518 30.8685C123.824 31.5913 122.806 31.9527 121.458 31.9527C120.44 31.9527 119.515 31.819 118.687 31.5486C117.86 31.2783 116.972 30.8372 116.031 30.2196L115.252 31.9527C116.924 33.2248 118.995 33.8594 121.458 33.8594C123.574 33.8594 125.172 33.3244 126.25 32.2572C127.326 31.1872 127.866 29.6049 127.866 27.5047ZM125.528 21.0361C125.528 22.6156 125.144 23.8506 124.373 24.7471C123.602 25.6435 122.564 26.0903 121.196 26.0903C119.828 26.0903 118.756 25.6435 117.976 24.7471C117.197 23.8535 116.807 22.5956 116.807 21.0361C116.807 19.4766 117.197 18.25 117.976 17.3536C118.756 16.4572 119.828 16.0104 121.196 16.0104C122.564 16.0104 123.628 16.4572 124.387 17.3536C125.147 18.25 125.528 19.4567 125.528 21.0361Z"
                    fill={color}
                />
                <path
                    d="M141.752 14.4195H144.09L135.776 33.6573H133.381L135.748 28.2844L129.8 14.4195H132.283L136.988 25.9167L141.752 14.4195Z"
                    fill={color}
                />
                <path
                    d="M190.06 14.1606H186.45C186.402 14.1606 186.362 14.2004 186.362 14.2488V22.1915C186.362 23.2615 186.058 24.1295 185.46 24.7755C184.863 25.4187 184.09 25.7431 183.157 25.7431C182.303 25.7431 181.675 25.5097 181.288 25.0515C180.898 24.5877 180.702 23.8591 180.702 22.883V14.2488C180.702 14.2004 180.662 14.1606 180.614 14.1606H177.004C176.956 14.1606 176.916 14.2004 176.916 14.2488V22.8261C176.916 26.7534 178.694 28.7454 182.198 28.7454C184.058 28.7454 185.483 28.0112 186.442 26.5655V28.3698C186.442 28.4182 186.482 28.458 186.53 28.458H190.051C190.1 28.458 190.139 28.4182 190.139 28.3698V14.2459C190.139 14.1976 190.1 14.1577 190.051 14.1577L190.06 14.1606Z"
                    fill={color}
                />
                <path
                    d="M207.913 14.6216C208.75 14.1236 209.717 13.8731 210.786 13.8731L210.795 13.8703C214.012 13.8703 215.644 15.851 215.644 19.7611V28.3669C215.644 28.4153 215.604 28.4552 215.556 28.4552H211.947C211.898 28.4552 211.859 28.4153 211.859 28.3669V19.9063C211.859 18.8533 211.685 18.0736 211.341 17.5898C211.005 17.1145 210.422 16.8755 209.609 16.8755C208.696 16.8755 207.97 17.1999 207.453 17.8402C206.929 18.4862 206.665 19.394 206.665 20.5438V28.3698C206.665 28.4182 206.625 28.458 206.577 28.458H202.967C202.919 28.458 202.879 28.4182 202.879 28.3698V19.9091C202.879 18.8562 202.706 18.0764 202.361 17.5926C202.026 17.1174 201.451 16.8783 200.658 16.8783C199.745 16.8783 199.019 17.2027 198.502 17.8431C197.978 18.4891 197.714 19.3969 197.714 20.5466V28.3726C197.714 28.421 197.674 28.4609 197.626 28.4609H194.016C193.968 28.4609 193.928 28.421 193.928 28.3726V14.2488C193.928 14.2004 193.968 14.1606 194.016 14.1606H197.537C197.586 14.1606 197.626 14.2004 197.626 14.2488V16.0986C198.038 15.4469 198.587 14.9232 199.255 14.5362C200.018 14.0951 200.905 13.8731 201.898 13.8731C203.974 13.8731 205.376 14.7639 206.068 16.5226C206.511 15.7258 207.131 15.0855 207.913 14.6216Z"
                    fill={color}
                />
                <path
                    d="M223.022 14.1606H219.416C219.367 14.1606 219.328 14.2001 219.328 14.2488V28.3698C219.328 28.4185 219.367 28.458 219.416 28.458H223.022C223.071 28.458 223.111 28.4185 223.111 28.3698V14.2488C223.111 14.2001 223.071 14.1606 223.022 14.1606Z"
                    fill={color}
                />
                <path
                    d="M235.148 13.8731C234.121 13.8731 233.179 14.0894 232.352 14.5191C231.609 14.9033 230.998 15.4497 230.531 16.1441V14.2488C230.531 14.2004 230.492 14.1606 230.443 14.1606H226.922C226.874 14.1606 226.834 14.2004 226.834 14.2488V28.3726C226.834 28.421 226.874 28.4609 226.922 28.4609H230.531C230.58 28.4609 230.62 28.421 230.62 28.3726V20.4299C230.62 19.3599 230.927 18.4919 231.533 17.8459C232.138 17.2028 232.966 16.8783 233.996 16.8783C234.829 16.8783 235.455 17.1202 235.847 17.5983C236.246 18.0821 236.448 18.8676 236.448 19.9404V28.3726C236.448 28.421 236.487 28.4609 236.536 28.4609H240.145C240.194 28.4609 240.233 28.421 240.233 28.3726V19.7668C240.233 15.8567 238.524 13.876 235.153 13.876L235.148 13.8731Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M247.384 14.0923C248.146 13.9471 248.874 13.8731 249.551 13.8731L249.546 13.8703C251.619 13.8703 253.184 14.3598 254.196 15.3245C255.209 16.2892 255.724 17.8032 255.724 19.8209V28.3698C255.724 28.4182 255.684 28.458 255.635 28.458H252.259C252.211 28.458 252.171 28.4182 252.171 28.3698V26.5086C251.838 27.1461 251.36 27.664 250.746 28.0539C250.024 28.5121 249.187 28.7454 248.16 28.7454C247.134 28.7454 246.204 28.5434 245.396 28.145C244.585 27.7437 243.942 27.1945 243.484 26.5115C243.024 25.8256 242.79 25.043 242.79 24.1807C242.79 23.1249 243.069 22.274 243.621 21.6536C244.17 21.0361 245.069 20.5864 246.295 20.3132C247.509 20.0429 249.156 19.9063 251.193 19.9063H252.117V19.3883C252.117 18.4549 251.912 17.7748 251.508 17.3707C251.104 16.9665 250.407 16.7616 249.435 16.7616C248.658 16.7616 247.85 16.884 247.034 17.1202C246.215 17.3593 245.379 17.7292 244.543 18.2244C244.523 18.2386 244.497 18.2415 244.471 18.2329C244.446 18.2244 244.426 18.2073 244.417 18.1846L243.379 15.7286C243.365 15.6916 243.376 15.6461 243.41 15.6233C243.914 15.2761 244.52 14.9659 245.205 14.7041C245.891 14.4423 246.622 14.2374 247.384 14.0923ZM252.12 22.1687H251.227C249.438 22.1687 248.18 22.3082 247.492 22.5814C246.821 22.846 246.494 23.3156 246.494 24.0157C246.494 24.6218 246.707 25.1255 247.128 25.5097C247.552 25.8968 248.155 26.0931 248.917 26.0931C249.83 26.0931 250.601 25.7715 251.207 25.1369C251.813 24.5023 252.12 23.6855 252.12 22.7123V22.1687Z"
                    fill={color}
                />
                <path
                    d="M268.273 25.2934C268.253 25.282 268.23 25.2764 268.204 25.282C267.897 25.3589 267.599 25.43 267.311 25.4983C267.032 25.5638 266.757 25.5979 266.495 25.5979C265.622 25.5979 264.982 25.376 264.598 24.9349C264.211 24.4909 264.015 23.8108 264.015 22.9115V17.0491H268.199C268.247 17.0491 268.287 17.0092 268.287 16.9608V14.2459C268.287 14.1975 268.247 14.1577 268.199 14.1577H264.015V10.0284C264.015 9.98002 263.975 9.94017 263.926 9.94017H260.317C260.269 9.94017 260.229 9.98002 260.229 10.0284V14.1577H257.604C257.555 14.1577 257.515 14.1975 257.515 14.2459V16.9608C257.515 17.0092 257.555 17.0491 257.604 17.0491H260.229V23.1135C260.229 26.8501 262.143 28.7454 265.915 28.7454C266.418 28.7454 266.944 28.6857 267.476 28.569C268.011 28.4523 268.463 28.2929 268.813 28.0966C268.847 28.0766 268.864 28.0397 268.856 28.0027L268.307 25.3447C268.301 25.3219 268.287 25.302 268.267 25.2877L268.273 25.2934Z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M277.482 13.8703C279.499 13.8703 281.118 14.522 282.295 15.8054H282.298C283.473 17.0861 284.067 18.8533 284.067 21.0617V22.1317C284.067 22.1801 284.027 22.2199 283.979 22.2199H273.973C274.263 24.6474 275.648 25.8285 278.205 25.8285C278.981 25.8285 279.769 25.7089 280.546 25.4727C281.319 25.2365 282.039 24.8552 282.682 24.3458C282.705 24.3287 282.733 24.323 282.759 24.3287C282.787 24.3372 282.807 24.3543 282.818 24.3799L283.828 26.8046C283.842 26.8387 283.831 26.8786 283.803 26.9042C283.123 27.4677 282.258 27.9173 281.234 28.2474C280.21 28.5747 279.172 28.7426 278.148 28.7426C276.544 28.7426 275.142 28.4381 273.981 27.8376C272.818 27.2371 271.908 26.3692 271.276 25.2621C270.645 24.158 270.326 22.792 270.326 21.3207C270.326 19.8494 270.633 18.5431 271.245 17.439C271.856 16.3319 272.71 15.4526 273.788 14.8208C274.866 14.189 276.109 13.8703 277.482 13.8703ZM275.119 17.456C274.502 18.0736 274.115 18.9159 273.973 19.9632L273.975 19.966H280.884C280.796 18.8619 280.483 18.0024 279.946 17.4105C279.397 16.8072 278.657 16.4998 277.628 16.4998C276.598 16.4998 275.736 16.8385 275.119 17.456Z"
                    fill={color}
                />
                <path
                    d="M165.98 25.2422H165.69C164.182 25.2422 163.895 24.3885 163.895 22.8062V8.01075C163.895 7.96237 163.855 7.92253 163.807 7.92253H160.197C160.149 7.92253 160.109 7.96237 160.109 8.01075V23.2587C160.109 25.043 160.519 26.4176 161.329 27.3453C162.14 28.2759 163.411 28.7483 165.104 28.7483H166.662C166.688 28.7483 166.714 28.7369 166.731 28.717C166.748 28.6971 166.753 28.6715 166.748 28.6459L166.059 25.3162C166.051 25.2764 166.017 25.2479 165.974 25.2479L165.98 25.2422Z"
                    fill={color}
                />
                <path
                    d="M174.097 25.2422H174.388L174.382 25.2479C174.425 25.2479 174.459 25.2764 174.467 25.3162L175.156 28.6459C175.161 28.6715 175.156 28.6971 175.138 28.717C175.121 28.7369 175.096 28.7483 175.07 28.7483H173.512C171.819 28.7483 170.548 28.2759 169.737 27.3453C168.927 26.4176 168.517 25.043 168.517 23.2587V8.01075C168.517 7.96237 168.557 7.92253 168.605 7.92253H172.215C172.263 7.92253 172.303 7.96237 172.303 8.01075V22.8062C172.303 24.3885 172.59 25.2422 174.097 25.2422Z"
                    fill={color}
                />
            </g>
            <rect width="285" height="33.8654" />
        </SvgIcon>
    );
};
