import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        launchSnowflakeButton: {
            fontWeight: theme.typography.fontWeightSemiBold,
            display: 'inline-block',
            width: '250px',
            height: '45px',
            [theme.breakpoints.up('md')]: {
                width: '100%',
            },
        },
        launchButtonErrorText: {
            color: theme.palette.error.main,
        },
    });

const options = {
    name: 'SnowflakeLauncher-',
};

export const useStyles = makeStyles(styles, options);
