import * as React from 'react';

import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { ThemeStyle } from '@bb-ui/react-library/dist/components/Typography/Typography';
import { useStyles } from './PrimaryLink.styles';

export interface IPrimaryLinkProps {
    to: string;
    typographyVariant?: ThemeStyle;
}

// This component is used for Links which should look like a <PrimaryButton />
export const PrimaryLink: React.FunctionComponent<IPrimaryLinkProps> = ({
    to,
    typographyVariant = 'h4',
    children: label,
    ...props
}) => {
    const classes = useStyles();

    return (
        <a
            className={classes.primaryLink}
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            {...props}
        >
            <Typography component="span" variant={typographyVariant}>
                {label}
            </Typography>
        </a>
    );
};
