import { bbTheme as theme } from '@bb-ui/react-library/dist/components/styles/theme';
import {
    makeStyles,
    createStyles,
} from '@bb-ui/react-library/dist/components/styles';

// Hopefully something like this can be added to the component library but for we can
// keep it here for reuse.
export const veryLightFocusColor = '#84bbfb';

export const illuminateColors = {
    brandYellow: '#FDBD3A',
    brandYellowLight: '#F7E7C5',
};

const styles = () =>
    createStyles({
        // "Screen Reader Only" - For content that is visually hidden but will be used by screen readers
        // From https://cloudfour.com/thinks/see-no-evil-hidden-content-and-accessibility/ which is an improved version
        // of the original version found here: https://accessibility.18f.gov/hidden-content/
        srOnly: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            clipPath: 'polygon(0px 0px, 0px 0px, 0px 0px)',
            height: '1px',
            margin: '-1px',
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            width: '1px',
            whiteSpace: 'nowrap',
        },
        backArrowButton: {
            color: theme.palette.common.black,
        },
    });

const options = {
    name: 'sharedStyles-',
};

export const useSharedStyles = makeStyles(styles, options);

// setting up a zIndex object to set a standard for the application
// this is similar to how MUI sets up theres:
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/zIndex.js
export const zIndex = {
    entrySearch: 1,
    interactiveErd: 100,
    navButton: 150,
    helpButton: 150,
    // modal: 1300 --> adding for reference and is added by bb-ui
    skipLink: 2000,
    loadingIndicator: 99999,
};
