import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Attention } from '@bb-ui/icons/dist/medium/Attention';

import { TFunction } from 'i18next';
import { IMessageProps } from './Message.types';
import { useStyles } from './Message.styles';

const getDefaults = (
    variant: 'info' | 'error',
    t: TFunction,
    attentionIconClassname: string, // className for Attention Icon to be able to set the correct color
): {
    primaryMessage?: string;
    secondaryMessage?: string;
    icon?: JSX.Element;
    primaryColor: 'error' | 'primary' | 'secondary';
    secondaryColor: 'error' | 'primary' | 'secondary';
} => {
    switch (variant) {
        case 'error':
            return {
                primaryMessage: t(
                    'general.defaultPageError',
                    "The page didn't load.",
                ),
                secondaryMessage: t(
                    'general.pleaseRefreshThePage',
                    'Please refresh the page or contact your system administrator for help.',
                ),
                icon: <Attention className={attentionIconClassname} />,
                primaryColor: 'error',
                secondaryColor: 'error',
            };
        case 'info':
            return {
                primaryColor: 'primary',
                secondaryColor: 'secondary',
            };
    }
};

export const Message: React.FC<IMessageProps> = props => {
    const classes = useStyles(props);
    const { t } = useTranslation();
    const {
        message: primaryMessageOverride,
        secondaryMessage: secondaryMessageOverride,
        variant,
    } = props;

    const defaults = getDefaults(variant, t, classes.attentionIcon);

    const primaryMessage = primaryMessageOverride || defaults.primaryMessage;
    const secondaryMessage =
        secondaryMessageOverride ?? defaults.secondaryMessage;

    if (!primaryMessage) {
        throw Error('Primary message must be defined in Message component!');
    }

    return (
        <div className={classes.container} data-testid="error-message">
            {defaults.icon}
            <Typography
                color={defaults.primaryColor}
                align="center"
                variant="subtitle1"
                className={classes.primaryMessage}
            >
                {primaryMessage}
            </Typography>
            {secondaryMessage && (
                <Typography
                    color={defaults.secondaryColor}
                    align="center"
                    className={classes.secondaryMessage}
                >
                    {secondaryMessage}
                </Typography>
            )}
        </div>
    );
};
