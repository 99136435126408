import React from 'react';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { useTranslation, Trans } from 'react-i18next';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';

import { ResponsiveBox } from '../ResponsiveBox/ResponsiveBox';
import { AnthologyLogo } from '../../../app/assets/images/SVGs/AnthologyLogo';
import { useStyles } from './Footer.styles';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { usePageExpand } from '../../contexts/PageExpandContext';

export const Footer: React.FunctionComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const getHelpLink = useHelpLinks();
    const currentYear = Math.max(new Date().getFullYear(), 2023);
    const { expanded } = usePageExpand();

    if (expanded) {
        return null;
    }

    return (
        <footer className={classes.footer} data-testid="footer">
            <ResponsiveBox>
                <AnthologyLogo
                    className={classes.footerLogo}
                    data-testid="anthology-logo-footer"
                />
                <div className={classes.footerContainer}>
                    <div className={classes.footerLinks}>
                        <Link
                            href={getHelpLink('termsOfUse')}
                            className={classes.footerLink}
                            data-testid="terms-of-use-link"
                        >
                            {t('footer.termsOfUse')}
                        </Link>
                        <Link
                            href={getHelpLink('anthologyPrivacyStatement')}
                            className={classes.footerLink}
                            data-testid="privacy-policy-link"
                        >
                            {t('footer.privacyPolicy')}
                        </Link>
                        <Link
                            href={getHelpLink('anthologyAboutUs')}
                            className={classes.footerLink}
                            data-testid="about-anthology-link"
                        >
                            <Trans i18nKey="footer.aboutAnthology">
                                About <span lang="en">Anthology</span>
                            </Trans>
                        </Link>
                    </div>
                    <Typography
                        className={classes.anthologyAllRightsReserved}
                        data-testid="copyrightText"
                    >
                        ©{currentYear}{' '}
                        <Trans i18nKey="footer.copyright">
                            <span lang="en">Anthology</span> Inc. and its
                            affiliates. All rights reserved.
                        </Trans>
                    </Typography>
                </div>
            </ResponsiveBox>
        </footer>
    );
};
