import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            outline: 'none',
        },
        secondaryContainer: {
            width: '100%',
            padding: theme.spacing(3, 0),
        },
        accommodateSideNavButton: {
            padding: theme.spacing(3, 4),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(3, 0),
            },
        },
        details: {
            listStyleType: 'none',
            display: 'flex',
            margin: '2px 0 0 0',
            padding: '0',
            fontSize: theme.typography.fontSizeSmall,
        },
        detail: {
            marginRight: '20px',
        },
        detailValues: {
            color: theme.palette.text.hint,
        },
        child: {
            marginTop: 'auto',
            marginBottom: 'auto',
        },
    });

const options = {
    name: 'MainHeader-',
};

export const useStyles = makeStyles(styles, options);
