import React from 'react';

interface AppThemeProps {
    isRtl: boolean;
    setIsRtl: (isRtl: boolean) => void;
}
export const AppThemeContext = React.createContext<AppThemeProps>({
    isRtl: false,
    setIsRtl: () => {},
});

export const useAppThemeContext = () => React.useContext(AppThemeContext);
