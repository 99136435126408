/**
 * Commonly used help links
 * Exporting for use in tests
 */
export const helpLinks: Record<string, string> = {
    main: 'https://help.blackboard.com/Anthology_Illuminate',
    getReportingHelp:
        'https://help.blackboard.com/Anthology_Illuminate/Get_Anthology_Illuminate',
    reporting: 'https://help.blackboard.com/Anthology_Illuminate/Reporting',
    learningReportingArea:
        'https://help.blackboard.com/Anthology_Illuminate/Reporting/Learning',
    teachingReportingArea:
        'https://help.blackboard.com/Anthology_Illuminate/Reporting/Teaching',
    leadingReportingArea:
        'https://help.blackboard.com/Anthology_Illuminate/Reporting/Leading',
    developer: 'https://help.blackboard.com/Anthology_Illuminate/Developer',
    directDataAccess:
        'https://help.blackboard.com/Learn/Administrator/SaaS/Integrations/Direct_Data_Access',
    dataDictionary:
        'https://help.blackboard.com/Anthology_Illuminate/Developer/Data_Dictionary',
    settings:
        'https://help.blackboard.com/Anthology_Illuminate/Developer/Settings',
    roles: 'https://help.blackboard.com/Anthology_Illuminate/Role_Based_Access',
    privacyAndSecurity:
        'https://help.blackboard.com/Anthology_Illuminate/Privacy_and_Security',
    privacyCenter: 'https://www.anthology.com/trust-center',
    termsOfUse: 'https://www.anthology.com/trust-center/terms-of-use',
    anthologyPrivacyStatement:
        'https://www.anthology.com/trust-center/privacy-statement',
    anthologyAboutUs: 'https://www.anthology.com/about-us',
    releaseNotes:
        'https://help.blackboard.com/Anthology_Illuminate/Release_Notes',
    dataQAndA:
        'https://help.blackboard.com/Anthology_Illuminate/Reporting/Data_QandA',
    customReports:
        'https://help.blackboard.com/Anthology_Illuminate/Reporting/Custom_Reports',
    contactSupport:
        'https://blackboard.my.salesforce-sites.com/btbb_publichome',
    aboutIlluminate:
        'https://www.anthology.com/products/institutional-intelligence/anthology-illuminate',
    exploreEnhanced: 'https://learn.anthology.com/anthology-illuminate',
    uasMigration:
        'https://help.blackboard.com/Anthology_Illuminate/Institutional_Authentication/UAS',
    aiTransparencyNote:
        'https://help.blackboard.com/Anthology_Illuminate/Reporting/AI_Transparency_Note',
    generativeAiTerms:
        'https://www.anthology.com/agreements/generative-ai-terms',
};

export function useHelpLinks(): (key: string) => string {
    return (key: string) => {
        let helpLink = helpLinks[key];

        // If we can't find a matching link, fall back on main help link
        if (!helpLink) {
            console.warn(`A help link for key "${key}" was not found.`);
            helpLink = helpLinks.main;
        }

        return helpLink;
    };
}
