import React from 'react';
import MenuItem from '@bb-ui/react-library/dist/components/MenuItem/MenuItem';

export const mapToMenuItems = (options: any[]) =>
    // TODO handle case where options is empty (pagination and table has zero rows)
    options.map(option => (
        <MenuItem button key={option.key} value={option.key}>
            {option.name}
        </MenuItem>
    ));
