import React from 'react';
import { Typography, useTheme } from '@bb-ui/react-library';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { useStyles } from './DataQAndAUnauthorized.styles';
import { useApplicationTitle } from '../../hooks/useApplicationTitle';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { ActionAreaButtons } from '../ActionAreaButtons/ActionAreaButtons';

export const DataQAndAUnauthorized: React.FunctionComponent = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();
    const getHelpLink = useHelpLinks();
    const history = useHistory();

    // This does not get localized
    useApplicationTitle('Data Q&A');

    return (
        <div
            id="data-q&a-unauthorized"
            data-testid="data-q&a-unauthorized"
            className={classes.blockContainer}
        >
            <div
                className={classes.imageContainer}
                data-testid="data-q&a-illustration"
            >
                <img
                    src="/images/DataQAndA/illustration.svg"
                    alt=""
                    className={classes.image}
                />
            </div>
            <div className={classes.textContainer}>
                <Typography
                    variant="h1"
                    data-testid="data-q&a-unauthorized-primary-text"
                    className={classes.text}
                >
                    {t('enhancedFeatures.unathorized.primaryText', {
                        featureName: 'Data Q&A',
                    })}
                </Typography>
                <Typography
                    variant="h2"
                    data-testid="data-q&a-unauthorized-secondary-text"
                    className={classes.text}
                >
                    {t('enhancedFeatures.unathorized.secondaryText')}
                </Typography>
            </div>
            <PrimaryButton
                onClick={() => {
                    history.push('/');
                }}
                data-testid="back-to-home-button"
                className={classes.button}
            >
                {t('enhancedFeatures.unathorized.backButton')}
            </PrimaryButton>
            <ActionAreaButtons helpLink={getHelpLink('dataQAndA')} />
        </div>
    );
};
