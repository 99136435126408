import {
    makeStyles,
    createStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            content: {
                display: 'grid',
                gridTemplateColumns: '1fr',
                rowGap: theme.spacing(2),
            },
            singleIdpGroup: {
                display: 'flex',
                flexDirection: 'column',
                marginInlineStart: theme.spacing(0.5),
            },
            singleIdpSelect: {
                marginInlineStart: theme.spacing(2.5),
                maxWidth: '380px',
            },
            userSectionTitle: {
                margin: theme.spacing(1, 0, 1, 0),
            },
            tableContainer: {
                overflowX: 'auto',
            },
            tableHeader: {
                fontSize: '0.75rem',
                backgroundColor: 'inherit',
                paddingBottom: '0',
                borderTop: '0',
                paddingTop: '0',
            },
            tableHeaderRow: {
                height: '50px',
            },
            checkboxCell: {
                paddingInlineStart: theme.spacing(0),
                paddingInlineEnd: theme.spacing(0),
                width: '30px',
            },
            usernameField: {
                width: '220px',
            },
            userIdpSelect: {
                width: '165px',
            },
            idpOption: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            userAlert: {
                fontWeight: 'bold',
            },
            usernameInfoIcon: {
                position: 'relative',
                top: 3,
                left: 3,
            },
            monospace: {
                fontFamily: 'monospace',
            },
        }),
    {
        name: 'UasMigrationDialog-',
    },
);
