import React from 'react';

import {
    ConsoleContentOptions,
    ConsoleExperience,
    EmbeddingContext,
    EmbeddingEvents,
    ExperienceFrameMetadata,
    FrameOptions,
} from 'amazon-quicksight-embedding-sdk';

import { useTranslation } from 'react-i18next';
import Box from '@bb-ui/react-library/dist/components/Box/Box';
import { Message } from '../components/Message/Message';

import { useStyles } from './QuickSightConsole.styles';
import {
    EmbeddedConsoleState,
    IQuickSightConsoleProps,
} from './QuickSightConsole.types';
import { getEmbeddingContext } from './QuickSightEmbeddingContext';
import { getQsLocale } from '../utilities/localization';
import { useI18nContext } from '../components/I18nProvider/I18nProvider';

export const QuickSightConsole: React.FunctionComponent<
    IQuickSightConsoleProps
> = ({ embedData, isError }) => {
    const [consoleState, setConsoleState] =
        React.useState<EmbeddedConsoleState>(EmbeddedConsoleState.notLoaded);

    const classes = useStyles();

    const { t } = useTranslation();
    const consoleRef = React.useRef<ConsoleExperience>();

    const { userSelectedLang } = useI18nContext();
    const qsLocaleSettings = getQsLocale(userSelectedLang);

    const embed = React.useCallback(async () => {
        const embeddingContext: EmbeddingContext = await getEmbeddingContext();
        const { embedConsole } = embeddingContext;
        const frameOptions: FrameOptions = {
            url: embedData.url,
            container: '#author-console-container',
            resizeHeightOnSizeChangedEvent: true,
            onChange: (
                changeEvent: EmbeddingEvents,
                metadata: ExperienceFrameMetadata | undefined,
            ) => {
                switch (changeEvent.eventName) {
                    case 'FRAME_LOADED': {
                        setConsoleState(EmbeddedConsoleState.loaded);
                        break;
                    }
                }
            },
        };

        const contentOptions: ConsoleContentOptions = {
            locale: qsLocaleSettings.qsLngCode,
        };

        await embedConsole(frameOptions, contentOptions);
    }, [embedData.url, qsLocaleSettings.qsLngCode]);

    React.useEffect(() => {
        if (!consoleRef.current) {
            embed().catch(() => {
                setConsoleState(EmbeddedConsoleState.failedToLoad);
            });
        }
    }, [embed, consoleRef]);

    return consoleState === EmbeddedConsoleState.failedToLoad || isError ? (
        <Box height="100%" width="100%" display="flex">
            <Message
                message={t('reports.error.dataDidNotLoad')}
                variant="error"
            />
        </Box>
    ) : (
        <div
            className={classes.authorConsoleContainer}
            data-testid="author-console-container"
            id="author-console-container"
            title={t('authoring.title')}
        />
    );
};
