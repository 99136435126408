import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@bb-ui/react-library';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ImageHandler.styles';

interface IImageHandlerProps {
    imgSrc: string;
    alt?: string;
    // loadingImageColor is used when the image is loading. By default it is transparent
    // but it is recommended to override it by a less-saturated dominant color of the image to be loaded
    loadingImageColor?: string;
    className?: string;
}
// This component handles everything related to images, including loading and error handling
const ImageHandler: React.FunctionComponent<IImageHandlerProps> = ({
    imgSrc,
    alt = '', // default value is for those images which only purpose is being an illustration
    loadingImageColor,
    className,
}) => {
    const classes = useStyles();
    const image = useRef<HTMLImageElement>();
    const [hasError, setHasError] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (image.current && image.current.complete) setImageLoaded(true);
    }, []);

    return (
        <>
            {!imageLoaded && (
                <div
                    className={classes.placeholder}
                    style={{
                        backgroundColor: loadingImageColor || 'transparent',
                    }}
                    data-testid="image-loading-placeholder"
                >
                    {hasError && (
                        <Typography>
                            {t('general.imageError', {
                                imageAlternativeText: alt,
                            })}
                        </Typography>
                    )}
                </div>
            )}
            <img
                src={imgSrc}
                alt={alt}
                data-testid="image-container"
                className={className || classes.image}
                loading="lazy"
                onLoad={() => setImageLoaded(true)}
                onError={e => {
                    console.error(e);
                    setHasError(true);
                }}
                style={{ visibility: imageLoaded ? 'visible' : 'hidden' }}
            />
        </>
    );
};

export default ImageHandler;
