import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';

import { formats } from './formats';
import { lngWhiteList } from '../../src/utilities/localization';

// loads user locale stored in local storage
// value is saved after each language picker selection
// extract -k-12 / -pro suffix because it breaks ICU formatting
// extracetd lngSuffix is used in backend JSON file loading
let lngSuffix = '';
const localStorageLngDetector = new LanguageDetector();
localStorageLngDetector.addDetector({
    name: 'localeStorageLngDetector',
    lookup() {
        let found = window.localStorage.getItem('selectedLocale') ?? undefined;
        if (found) {
            const chunks = found.split('-');
            if (chunks.length === 3) {
                found = `${chunks[0]}-${chunks[1]}`;
                lngSuffix = `-${chunks[2]}`;
            }
        }
        return found;
    },
});

const detectorOptions = {
    // first it attempts to load from the browser's local storage
    // then it attempts getting locale that is set in the user's browser
    order: ['localeStorageLngDetector', 'navigator'],
};

const getAppBuildEnvironment = () =>
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

i18next
    .use(HttpApi)
    .use(localStorageLngDetector)
    .use(initReactI18next)
    .use(new ICU({ formats }))
    .init({
        backend: {
            loadPath: (lng: string[]) =>
                `/config/locales/{{lng}}${
                    lng[0] === 'en-US' ? lngSuffix : ''
                }.json`,
            parse: (data: string) => {
                const json = JSON.parse(data);
                if (typeof json === 'string') {
                    throw new Error(
                        'Unable to parse application translation file to JSON.',
                    );
                }
                return json;
            },
        },
        detection: detectorOptions,
        supportedLngs: lngWhiteList,
        nonExplicitSupportedLngs: true,
        load: 'all',
        fallbackLng: 'en', // FallbackLngObjList is not supported in I18nProvider right now
        debug: getAppBuildEnvironment(),
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            useSuspense: true,
        },
    });

export default i18next;
