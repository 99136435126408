// This wraps the @bb-ui/auth module's Auth0CallbackHandler with additional
// logic to catch an error signalled by Auth0 that isn't recognized by Auth0's
// library itself. (It'll appear as an ?error query string in the URL.) The
// module may build in this error handling in a later version.

import * as React from 'react';
import { Auth0CallbackHandler } from '@bb-ui/auth/dist/auth0/Auth0CallbackHandler';
import { useTranslation } from 'react-i18next';
import {
    createStyles,
    makeStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';

import { Message } from '../Message/Message';
import { useAppLoadingContext } from '../../contexts/AppLoadingContext';

export interface Auth0CallbackProps {
    onRedirect: (path: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorContainer: {
            background: theme.palette.background.b3,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        },
    }),
);

function useQuery() {
    return new URLSearchParams(window.location.search);
}

export const Auth0Callback: React.FunctionComponent<Auth0CallbackProps> = ({
    onRedirect,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { setLoading } = useAppLoadingContext();
    const query = useQuery();
    const error = query.get('error');

    if (error) {
        setLoading(false);
        console.error(error);
        return (
            <div className={classes.errorContainer}>
                <Message
                    variant="error"
                    message={t('general.applicationLoadError')}
                    secondaryMessage={t('general.pleaseRefreshThePage')}
                />
            </div>
        );
    }
    return <Auth0CallbackHandler onRedirect={onRedirect} />;
};
